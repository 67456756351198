import { AcademyListWrapper } from '../models/academy/academy-list-wrapper';
import { AttendanceListWrapper } from '../models/attendance/attendance-list-wrapper';
import { BulkStudentsPostData } from '../models/student/bulk-student-post-data';
import { studentAcademyHistory } from '../models/student/student-activity';
import { studentAcademyHistoryListDataWrapper } from '../models/student/student-activity-data-wrapper';
import { StudentClassSessions } from '../models/student/student-class-sessions';
import { StudentData } from '../models/student/student-data';
import { StudentDetailsWrapper } from '../models/student/student-details-wrapper';
import { StudentListWrapper } from '../models/student/student-list-wrapper';
import { StudentPostData } from '../models/student/student-post-data';
import { StudentPostDataWrapper } from '../models/student/student-post-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import AttendanceRequest from '../utils/types/attendance-request';
import AttendanceRequestWrapper from '../utils/types/attendance-request-wrapper';

export class StudentService {
    private static _instance: StudentService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new StudentService());
    }

    public getStudentList = async (
        supplierId: number,
        query: string,
        searchTerm: string,
        currentPage: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate[0]=academies&populate[1]=studentRelation&populate[2]=studentAcademyHistory.academy&populate[3]=users&populate[4]=classes&populate[5]=sessions.sessionTimings&populate[6]=sessions.class&populate[7]=photo&pagination[page]=1&pagination[pageSize]=1500&custom[pageSize]=10&custom[page]=${currentPage}&filters[users][id]=${supplierId}&filters[$or][0][firstName][$contains]=${searchTerm}&filters[$or][1][lastName][$contains]=${searchTerm}&filters[$or][2][parentContactNo][$contains]=${searchTerm}&${query}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    /**
     * Retrieves a minimal list of students based on the supplier ID and search term.
     *
     * This function fetches a list of students with minimal information, including their photos.
     * The results are filtered by the supplier ID and the search term is applied to the first name,
     * last name, and middle name of the students.
     *
     * @param supplierId - The ID of the supplier to filter the students by.
     * @param searchTerm - The search term to filter students by their names (first, last, or middle).
     * @returns A promise that resolves to an ApiResponse containing a StudentListWrapper.
     *          The StudentListWrapper includes the list of students matching the search criteria.
     *
     * @remarks
     * Filters:
     * - `filters[users][id]=${supplierId}`: Filters students by the specified supplier ID.
     * - `filters[$or][0][firstName][$contains]=${searchTerm}`: Searches for the term in first names.
     * - `filters[$or][1][lastName][$contains]=${searchTerm}`: Searches for the term in last names.
     * - `filters[$or][2][middleName][$contains]=${searchTerm}`: Searches for the term in middle names.
     * The $or operator ensures that the searchTerm matches any of these name fields.
     *
     * Populate:
     * - `populate=photo`: Includes the photo relation in the response, populating student photo data.
     *
     * This combination of filters and populate allows for efficient searching and retrieval of
     * minimal student data, including their photos, while limiting the results to a specific supplier.
     */
    public getMinmalStudentList = async (
        supplierId: number,
        searchTerm: string
    ): Promise<ApiResponse<StudentListWrapper>> => {
        // console.log("getMinmalStudentList")
        return invokeApi<null, StudentListWrapper>({
            //  populate=photo&filters[users][0][id]=3&filters[$or][0][firstName][$contains]=a&filters[$or][1][lastName][$contains]=a&filters[$or][2][middleName][$contains]=a
            route: `students?populate=photo&filters[users][id]=${supplierId}&filters[$or][0][firstName][$contains]=${searchTerm}&filters[$or][1][lastName][$contains]=${searchTerm} &filters[$or][2][middleName][$contains]=${searchTerm}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentApartmentsList = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        let currentPage = 1;
        let allStudentsApartments: StudentData[] = [];
        let totalStudentsCount = 0;

        do {
            const response = await invokeApi<null, StudentListWrapper>({
                route: `students?filters[users][id]=${supplierId}&fields[0]=apartmentName&pagination[page]=${currentPage}`,
                method: 'GET',
                privateRoute: true,
            });

            if (!response.success) {
                // Handle error
                return response; // Return error response
            }

            if (!response.data) {
                // Handle case where response.data is undefined
                return {
                    isLoading: false,
                    success: false,
                    error: {
                        status: 500,
                        message: 'Data is undefined',
                    },
                };
            }

            allStudentsApartments = allStudentsApartments.concat(response?.data?.data ?? []);

            const { pagination } = response.data.meta;
            const { page, pageCount, total } = pagination;

            totalStudentsCount = total;

            if (page >= pageCount) {
                // Reached the last page, break the loop
                break;
            }

            currentPage++;
        } while (currentPage <= Math.ceil(totalStudentsCount / 500));

        const paginationMeta = {
            pagination: {
                page: 1,
                pageSize: allStudentsApartments.length,
                pageCount: allStudentsApartments.length,
                total: allStudentsApartments.length,
            },
        };

        return {
            isLoading: false,
            success: true,
            data: {
                data: allStudentsApartments,
                meta: paginationMeta,
            },
        };
    };

    public getStudentSchoolsList = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        let currentPage = 1;
        let allStudentsSchools: StudentData[] = [];
        let totalStudentsCount = 0;

        do {
            const response = await invokeApi<null, StudentListWrapper>({
                route: `students?filters[users][id]=${supplierId}&fields[0]=school&pagination[page]=${currentPage}`,
                method: 'GET',
                privateRoute: true,
            });

            if (!response.success) {
                // Handle error
                return response; // Return error response
            }

            if (!response.data) {
                // Handle case where response.data is undefined
                return {
                    isLoading: false,
                    success: false,
                    error: {
                        status: 500,
                        message: 'Data is undefined',
                    },
                };
            }

            allStudentsSchools = allStudentsSchools.concat(response?.data?.data ?? []);

            const { pagination } = response.data.meta;
            const { page, pageCount, total } = pagination;

            totalStudentsCount = total;

            if (page >= pageCount) {
                // Reached the last page, break the loop
                break;
            }

            currentPage++;
        } while (currentPage <= Math.ceil(totalStudentsCount / 500));

        const paginationMeta = {
            pagination: {
                page: 1,
                pageSize: allStudentsSchools.length,
                pageCount: allStudentsSchools.length,
                total: allStudentsSchools.length,
            },
        };

        return {
            isLoading: false,
            success: true,
            data: {
                data: allStudentsSchools,
                meta: paginationMeta,
            },
        };
    };

    public getActiveStudentList = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        let currentPage = 1;
        let allStudents: StudentData[] = [];
        let totalStudentsCount = 0;

        do {
            const response = await invokeApi<null, StudentListWrapper>({
                route: `students?fields[0]=id&populate[academies][fields][0]=id&filters[users][id]=${supplierId}&pagination[page]=${currentPage}`,
                method: 'GET',
                privateRoute: true,
            });

            if (!response.success) {
                // Handle error
                return response; // Return error response
            }

            if (!response.data) {
                // Handle case where response.data is undefined
                return {
                    isLoading: false,
                    success: false,
                    error: {
                        status: 500,
                        message: 'Data is undefined',
                    },
                };
            }

            allStudents = allStudents.concat(response?.data?.data ?? []);

            const { pagination } = response.data.meta;
            const { page, pageCount, total } = pagination;

            totalStudentsCount = total;

            if (page >= pageCount) {
                // Reached the last page, break the loop
                break;
            }

            currentPage++;
        } while (currentPage <= Math.ceil(totalStudentsCount / 500));

        const paginationMeta = {
            pagination: {
                page: 1,
                pageSize: allStudents.length,
                pageCount: allStudents.length,
                total: allStudents.length,
            },
        };

        return {
            isLoading: false,
            success: true,
            data: {
                data: allStudents,
                meta: paginationMeta,
            },
        };
    };

    public getStudentDetails = async (
        studentId: number
    ): Promise<ApiResponse<StudentDetailsWrapper>> => {
        return invokeApi<null, StudentDetailsWrapper>({
            route: `students/${studentId}?[populate][0]=studentAcademyHistory.academy&populate[1]=classes.class_discipline&populate[2]=users&populate[3]=academies&populate[4]=studentRelation&populate[5]=photo&populate[6]=sessions.sessionTimings&populate[7]=sessions.class`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentRelationInfo = async (
        studentId: number
    ): Promise<ApiResponse<StudentDetailsWrapper>> => {
        return invokeApi<null, StudentDetailsWrapper>({
            route: `students/${studentId}?populate=studentRelation`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentAcademyHistory = async (
        studentId: number
    ): Promise<ApiResponse<StudentDetailsWrapper>> => {
        return invokeApi<null, StudentDetailsWrapper>({
            route: `students/${studentId}?populate[studentAcademyHistory][populate][0]=academy,users`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewStudent = async (data: StudentPostDataWrapper) => {
        return invokeApi<StudentPostDataWrapper, StudentDetailsWrapper>({
            route: `students`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public postBulkStudents = async (data: BulkStudentsPostData[]) => {
        return invokeApi<BulkStudentsPostData[], string>({
            route: `bulk-students`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public updateStudentSessionTimings = async (data: {id: number, sessions: StudentClassSessions[]}[]) => {
        return invokeApi<{id: number, sessions: StudentClassSessions[]}[], string>({
            route: `student-sessions-update`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public dropStudent = async (id: number, data: StudentPostData) => {
        return invokeApi<StudentPostDataWrapper, ApiResponse<StudentPostDataWrapper>>({
            route: `students/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: { data },
        });
    };

    public updateStudent = async (id: number, data: StudentPostDataWrapper) => {
        return invokeApi<StudentPostDataWrapper, StudentData>({
            route: `students/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public postLeaveRecord = async (data: AttendanceRequest) => {
        return invokeApi<AttendanceRequestWrapper, AttendanceListWrapper>({
            route: `schedules`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public getFiltredAcademyStudentDetails = async (
        studentId: number
    ): Promise<ApiResponse<StudentDetailsWrapper>> => {
        return invokeApi<null, StudentDetailsWrapper>({
            route: `students?populate=*&filters[academies][id]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentListDetailes = async (
        supplierId: number
    ): Promise<ApiResponse<StudentListWrapper>> => {
        return invokeApi<null, StudentListWrapper>({
            route: `students?populate=*&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getOwnersAcademies = async (
        supplierId: number
    ): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getStudentAttendance = async (
        studentId: number
    ): Promise<ApiResponse<AttendanceListWrapper>> => {
        return invokeApi<null, AttendanceListWrapper>({
            route: `schedules?populate=class&filters[student][id][$eq]=${studentId}`,
            method: 'GET',
            privateRoute: true,
        });
    };
}
