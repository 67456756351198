import React, { useEffect, useMemo, useState } from 'react';
import NavMenu from '../../components/widgets/nav-menu';
import style from './style.module.css';
// import filterIcon from '../../../assets/icons/filter.png';
import { ageOptions, DROPDOWN_LISTS, ENROLLMENT_STATUS_TYPES, statusOptions } from './data';
// import {DATA_FILTER_LIST} from './data';
// import downarrowIcon from '../../../assets/icons/union.png';
import StudentsListItem from '../../components/pieces/students-list-item';
import StudentsViewModel from '../../view-models/students-view-model';
import DropdownList from '../../components/widgets/dropdown-list';
import arrowDropdownIcon from '../../../assets/icons/arrow-dropdown.png';
import downloadIcon from '../../../assets/icons/download-icon.png';
import filterIcon from '../../../assets/icons/filter.png';
import closeIcon from '../../../assets/icons/red-close.png';
import arrowLeftIcon from '../../../assets/icons/chevron-left.png';
import arrowRightIcon from '../../../assets/icons/chevron-right.png';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import FeecollectionForm from '../../components/widgets/fee-collection-form';
import RecordLeaveForm from '../../components/widgets/record-leave-form';
import NewStudentForm from '../../components/widgets/new-student-form';
import DropStudentForm from '../../components/widgets/drop-student-form';
import FilterCheckBoxItem from '../../components/pieces/filter-check-box-item';
import searchIcon from '../../../assets/icons/search.png';
import { StudentData } from '../../../models/student/student-data';
import { AiOutlinePlus } from 'react-icons/ai';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import { Roles } from '../../../utils/auth/roles';
import BulkStudentUploadForm from '../../components/widgets/bulk-student-upload-form';
import AddStudent from '../../actions/add-student';
import EnrolmentForm from '../../components/widgets/enrolment-form-box';
import NewEnrolmentFormDetails from '../../components/widgets/send-enrolment-form';
import EnrolmentListItem from '../../components/pieces/enrolment-list-item/index';
import CollectFees from '../../actions/collect-fees';
import { BOARD_OPTIONS, GRADE_OPTIONS } from '../../components/widgets/new-student-form/data';
import crossIcon from '../../../assets/icons/cross.png';
import tickIcon from '../../../assets/icons/tick.png';

const Students = () => {
    const {
        state,
        selectedStudentIndex,
        dispatch,
        isStudentsFetchSuccess,
        students,
        indexOfFirstItem,
        indexOfLastItem,
        itemsPerPage,
        currentPage,
        total,
        handleActionsButton,
        goToStudentDetails,
        goToFeeHistory,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        openRecordLeaveFormModal,
        recordLeaveModalStudentData,
        formValues,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        isRecordPostSuccess,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        onCollectFeeClicked,
        handleAddNewStudentBtnClick,
        isNewStudentModalOpen,
        handleNewStudentModalClose,
        mapAcademiesToOptions,
        mapClassesToOptions,
        openStudentDropoutConfirmation,
        openStudentEditForm,
        isEditStudentModalOpen,
        handleEditStudentModalClose,
        handleStudentDropout,
        isDropStudentFormOpen,
        handleDropStudentModalClose,
        studentActiveAcademies,
        handleStudentDropAction,
        checkedAcademiesToDropStudent,
        handleDropStudentAcademyCheck,
        // onAcademiesSelected,
        selectedStudentData,
        handleProfileImgChange,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        searchTerm,
        handlesearchInputChange,
        studentSuggestionListData,
        selectedAgesForFilter,
        handleAgeChange,
        filteredStudents,
        handleFilterModalClose,
        isfilterModalOpen,
        handleFilterBtnClick,
        isProfileImagePosting,
        profileImageInputKey,
        allClasses,
        handleFeeFormInputChange,
        // studentStatus,
        handleClassInputChange,
        handleStudentFeeSearch,
        studentsClassFeeData,
        feePaymentFormData,
        handleFeeCollectSubmit,
        confirmedStudentClassId,
        handleStudentFeeReset,
        selectedStudentClassId,
        selectedDobFilterType,
        selectedDobFilterDate,
        setSelectedDobFilterDate,
        setSelectedDobFilterType,
        visibleFilter,
        toggleVisibleFilter,
        handleApartmentSearchInputChange,
        apartmentData,
        apartmentSerachTerm,
        handleApartmentNameClick,
        selectedApartmentForFilter,
        handleRemoveSelectedApartment,
        selectedAcademiesForFilter,
        handleAcademyFilterChange,
        isLeaveRecordPosting,
        isStudentDropping,
        isFeeCollectionUpdating,
        isFeeCollectionAdding,
        userState,
        pagesToShow,
        startPage,
        endPage,
        studentFilterDropDownRef,
        enrollmentFilterDropDownRef,
        moreActionsDropDownRef,
        inactiveFeeData,
        expandInactive,
        handleExpandInactive,
        handleStatusChange,
        statusForFilter,
        handleDownloadSampleCsv,
        handleBulkUploadStudentsModalOpen,
        handleBulkUploadStudentsModalClose,
        isBulkUploadFormOpen,
        isClassFetching,
        handleCsvFileInputChange,
        selectedCsvFileData,
        csvFileInputRef,
        handleBulkStudentAddSubmit,
        isBulkStudentsAdding,
        handleExportInvaidStudentsDataToCsv,
        handleIsEnrolmentOrNewStudentModalClose,
        isEnrolmentOrNewStudentModalOpen,
        handleAddEnrollmentFormOpen,
        handleAddNewStudentFormOpen,
        handleEnrolmentFormDetailsModalClose,
        handleEnrolmentFormDetailsSubmit,
        isNewEnrolmentFormDetailsOpen,
        handleEnrolmentDataInputChange,
        enrolmentData,
        handleEnrolmentFormAddClick,
        mapClassestoOptionsForEnrolmentForm,
        isNewEnrolmentLoading,
        currentEnrolmentData,
        mapAllClassesToOptionsIrrespectiveOfAcademy,
        selfEnrolment,
        student,
        setOpenTab,
        totalEnrolment,
        enrolmentFormData,
        studentsEnrolled,
        isEnrolmentFormFetchSuccess,
        totalPagesForEnrolment,
        endPageForEnrolment,
        handleEnrolmentCardEditClick,
        handleEnrolmentCardDeleteClick,
        isEmail,
        handleEmailSwitch,
        allSelfEnrolmentStudentChecked,
        handleAllEnrolmentStudentSelect,
        handleEnrolmentStudentItemCheck,
        checkedEnrolmentListItems,
        isAllChecked,
        handleEnrollButtonClick,
        allParentEmails,
        editingStudentData,
        selectedSchoolForFilter,
        selectedGradeForFilter,
        selectedBoardForFilter,
        handleBoardFilterChange,
        handleGradeFilterChange,
        handleSchoolSearchInputChange,
        schoolSerachTerm,
        schoolData,
        handleRemoveSelectedSchool,
        handleSchoolNameClick,
        handleRejectButtonClick,
        isEnrolmentUpdating,
        selectedEnrollmentStatusForFilter,
        handleEnrollmentStatusFilterChange,
        academiesWithoutContactInfo,
        selectedClassesForFilter,
        handleClassFilterChange,
        sortDropdownRef,
        handleSortDropBtnClick,
        isSortOptionsVisible,
        selectedSortOption,
        handleSortOptionChange,
    } = StudentsViewModel();

    const [dropSortBy, setDropSortBy] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<StudentData[] | []>([]);

    const checkBoxHandler = (option: string) => {
        const tabToSelect = state.openTab === 1 ? student : selfEnrolment;
        if (selectedOption === option) {
            setSelectedOption('');
            setSortBy([]);
        } else {
            setSelectedOption(option);
            if (students) {
                let sortedData = [...students];
                switch (option) {
                    case 'Student name A-Z':
                        sortedData.sort((a, b) =>
                            a?.attributes.firstName.localeCompare(b?.attributes?.firstName)
                        );
                        break;

                    case 'Student name Z-A':
                        sortedData.sort((a, b) =>
                            b?.attributes.firstName.localeCompare(a?.attributes?.firstName)
                        );
                        break;

                    default:
                        sortedData = [];
                        break;
                }
                setSortBy(sortedData);
            }
        }

        setDropSortBy(false);
    };

    const renderFilters = useMemo(() => {
        return (
            <div>
                {state.openTab === 1 && (
                    <div ref={studentFilterDropDownRef}>
                        <div>
                            <div className={style.filterLabelContentWrapper}>
                                <div
                                    className={`${style.filterName} ${selectedAcademiesForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                    onClick={() => toggleVisibleFilter(1)}
                                >
                                    Academy Name
                                </div>
                                {visibleFilter === 1 && (
                                    <div className={style.filterItemsContainer}>
                                        {state.academies.map((academy, index) => (
                                            <FilterCheckBoxItem
                                                key={index}
                                                isChecked={selectedAcademiesForFilter.includes(
                                                    academy?.id
                                                )}
                                                filterName={academy?.attributes?.name}
                                                onFilterSelected={() =>
                                                    handleAcademyFilterChange(academy.id)
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Filter for class */}

                            <div className={style.filterLabelContentWrapper}>
                                <div
                                    className={`${style.filterName} ${selectedClassesForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                    onClick={() => toggleVisibleFilter(9)}
                                >
                                    Class
                                </div>
                                {visibleFilter === 9 && (
                                    <div className={style.filterItemsContainer}>
                                        {allClasses
                                            ?.filter(c => {
                                                if (selectedAcademiesForFilter.length === 0) {
                                                    return true;
                                                } else {
                                                    return selectedAcademiesForFilter.includes(
                                                        c?.attributes?.academies?.data?.id
                                                    );
                                                }
                                            })
                                            ?.map((cls, index) => (
                                                <FilterCheckBoxItem
                                                    key={index}
                                                    isChecked={selectedClassesForFilter.includes(
                                                        cls?.id
                                                    )}
                                                    filterName={cls?.attributes?.class_name}
                                                    onFilterSelected={() =>
                                                        handleClassFilterChange(cls.id)
                                                    }
                                                />
                                            ))}
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className={style.filterLabelContentWrapper}>
                                    <div
                                        className={`${style.filterName} ${selectedAgesForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                        onClick={() => toggleVisibleFilter(2)}
                                    >
                                        Age
                                    </div>
                                    {visibleFilter === 2 && (
                                        <div className={style.filterItemsContainer}>
                                            {ageOptions.map(option => (
                                                <div
                                                    key={option.value}
                                                    className={style.ageFilterListItem}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option.value}
                                                        value={option.value}
                                                        checked={selectedAgesForFilter.includes(
                                                            option.value
                                                        )}
                                                        onChange={handleAgeChange}
                                                    />
                                                    <label htmlFor={option.value}>
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className={style.filterLabelContentWrapper}>
                                    <div
                                        className={`${style.filterName} ${selectedDobFilterDate ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                        onClick={() => toggleVisibleFilter(3)}
                                    >
                                        DOB
                                    </div>
                                    {visibleFilter === 3 && (
                                        <div className={style.filterItemsContainer}>
                                            <div>
                                                <select
                                                    className={style.filterInput}
                                                    value={selectedDobFilterType}
                                                    onChange={e =>
                                                        setSelectedDobFilterType(e.target.value)
                                                    }
                                                >
                                                    <option value="after">After</option>
                                                    <option value="before">Before</option>
                                                </select>
                                            </div>
                                            <div>
                                                <input
                                                    type="date"
                                                    className={style.filterInput}
                                                    value={selectedDobFilterDate}
                                                    onChange={e =>
                                                        setSelectedDobFilterDate(e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        setSelectedDobFilterDate('');
                                                        setSelectedDobFilterType('after');
                                                    }}
                                                    className={style.DobFilterClearBtn}
                                                >
                                                    cancel
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className={style.filterLabelContentWrapper}>
                                    <div
                                        className={`${style.filterName} ${selectedApartmentForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                        onClick={() => toggleVisibleFilter(4)}
                                    >
                                        Apartment
                                    </div>
                                    {visibleFilter === 4 && (
                                        <div className={style.filterItemsContainer}>
                                            {selectedApartmentForFilter.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={style.selectedApartments}
                                                >
                                                    {item}
                                                    <button>
                                                        <img
                                                            className={
                                                                style.removeSelectedApartmentIcon
                                                            }
                                                            src={closeIcon}
                                                            alt="red cross"
                                                            onClick={() =>
                                                                handleRemoveSelectedApartment(item)
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className={style.menuSearchInputContainer}>
                                                <input
                                                    type="text"
                                                    className={style.menuSearchInput}
                                                    placeholder="Search apartments"
                                                    // value={apartmentSerachTerm}
                                                    onChange={handleApartmentSearchInputChange}
                                                />
                                                {apartmentSerachTerm && (
                                                    <div className={style.apartmentSuggestionBox}>
                                                        <ul
                                                            className={
                                                                style.apartmentSuggestionBoxListWrapper
                                                            }
                                                        >
                                                            {apartmentData
                                                                .filter(item =>
                                                                    item
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            apartmentSerachTerm.toLowerCase()
                                                                        )
                                                                )
                                                                .filter(
                                                                    item =>
                                                                        !selectedApartmentForFilter.includes(
                                                                            item
                                                                        )
                                                                )
                                                                .map((item, index) => (
                                                                    <li
                                                                        className={
                                                                            style.apartmentSuggestionBoxListItem
                                                                        }
                                                                        key={index}
                                                                        onClick={() =>
                                                                            handleApartmentNameClick(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>{item}</span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* DIV FOR STATUS */}

                            <div>
                                <div className={style.filterLabelContentWrapper}>
                                    <div
                                        className={`${style.filterName} ${statusForFilter >= 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                        onClick={() => toggleVisibleFilter(5)}
                                    >
                                        Status
                                    </div>
                                    {visibleFilter === 5 && (
                                        <div className={style.filterItemsContainer}>
                                            {statusOptions.map(option => (
                                                <div
                                                    key={option.value}
                                                    className={style.statusFilterListItem}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option.value.toString()}
                                                        value={option.value}
                                                        checked={statusForFilter === option.value}
                                                        onChange={handleStatusChange}
                                                    />
                                                    <label htmlFor={option.value.toString()}>
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className={style.filterLabelContentWrapper}>
                                    <div
                                        className={`${style.filterName} ${selectedSchoolForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                        onClick={() => toggleVisibleFilter(6)}
                                    >
                                        School
                                    </div>
                                    {visibleFilter === 6 && (
                                        <div className={style.filterItemsContainer}>
                                            {selectedSchoolForFilter.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={style.selectedApartments}
                                                >
                                                    {item}
                                                    <button>
                                                        <img
                                                            className={
                                                                style.removeSelectedApartmentIcon
                                                            }
                                                            src={closeIcon}
                                                            alt="red cross"
                                                            onClick={() =>
                                                                handleRemoveSelectedSchool(item)
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className={style.menuSearchInputContainer}>
                                                <input
                                                    type="text"
                                                    className={style.menuSearchInput}
                                                    placeholder="Search school"
                                                    onChange={handleSchoolSearchInputChange}
                                                />
                                                {schoolSerachTerm && (
                                                    <div className={style.apartmentSuggestionBox}>
                                                        <ul
                                                            className={
                                                                style.apartmentSuggestionBoxListWrapper
                                                            }
                                                        >
                                                            {schoolData
                                                                .filter(item =>
                                                                    item
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            schoolSerachTerm.toLowerCase()
                                                                        )
                                                                )
                                                                .filter(
                                                                    item =>
                                                                        !selectedSchoolForFilter.includes(
                                                                            item
                                                                        )
                                                                )
                                                                .map((item, index) => (
                                                                    <li
                                                                        className={
                                                                            style.apartmentSuggestionBoxListItem
                                                                        }
                                                                        key={index}
                                                                        onClick={() =>
                                                                            handleSchoolNameClick(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>{item}</span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={style.filterLabelContentWrapper}>
                                <div
                                    className={`${style.filterName} ${selectedBoardForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                    onClick={() => toggleVisibleFilter(7)}
                                >
                                    Board
                                </div>
                                {visibleFilter === 7 && (
                                    <div className={style.filterItemsContainer}>
                                        {BOARD_OPTIONS.map((item, index) => (
                                            <FilterCheckBoxItem
                                                key={index}
                                                isChecked={selectedBoardForFilter.includes(
                                                    item.value
                                                )}
                                                filterName={item.label}
                                                onFilterSelected={() =>
                                                    handleBoardFilterChange(item.value)
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className={style.filterLabelContentWrapper}>
                                <div
                                    className={`${style.filterName} ${selectedGradeForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                    onClick={() => toggleVisibleFilter(8)}
                                >
                                    Grade
                                </div>
                                {visibleFilter === 8 && (
                                    <div className={style.filterItemsContainer}>
                                        {GRADE_OPTIONS.map((item, index) => (
                                            <FilterCheckBoxItem
                                                key={index}
                                                isChecked={selectedGradeForFilter.includes(
                                                    item.value
                                                )}
                                                filterName={item.label}
                                                onFilterSelected={() =>
                                                    handleGradeFilterChange(item.value)
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {state.openTab === 2 && (
                    <div ref={enrollmentFilterDropDownRef}>
                        <div className={style.filterLabelContentWrapper}>
                            <div
                                className={`${style.filterName} ${selectedEnrollmentStatusForFilter.length > 0 ? '!border-l-4 !border-l-slate-500 bg-slate-200' : ''}`}
                                onClick={() => toggleVisibleFilter(1)}
                            >
                                Status
                            </div>
                            {visibleFilter === 1 && (
                                <div className={style.filterItemsContainer}>
                                    {ENROLLMENT_STATUS_TYPES.map((item, index) => (
                                        <FilterCheckBoxItem
                                            key={index}
                                            isChecked={selectedEnrollmentStatusForFilter.includes(
                                                item?.value
                                            )}
                                            filterName={item.label}
                                            onFilterSelected={() =>
                                                handleEnrollmentStatusFilterChange(item.value)
                                            }
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }, [
        state.academies,
        schoolData,
        selectedBoardForFilter,
        schoolSerachTerm,
        selectedSchoolForFilter,
        selectedGradeForFilter,
        selectedAgesForFilter,
        selectedDobFilterDate,
        selectedAcademiesForFilter,
        selectedDobFilterType,
        visibleFilter,
        selectedApartmentForFilter,
        apartmentSerachTerm,
        statusForFilter,
        state.openTab,
        allSelfEnrolmentStudentChecked,
        selectedEnrollmentStatusForFilter,
        selectedClassesForFilter,
    ]);

    const renderMainContent = useMemo(() => {
        return (
            <div className="flex-1">
                <div
                    className={state.openTab === 1 ? style.contentContainerMain : 'hidden'}
                    id="link1"
                >
                    {/* Content for Students List */}
                    <div>
                        <div className={`${style.studentsHeader} relative`}>
                            <div className="flex justify-between items-center w-full gap-2">
                                <div className={style.studentsLabel}>Total students: {total}</div>

                                <div className={style.headerSearchContainerMain}>
                                    <div className={`${style.headerSearchContainer} relative`}>
                                        <img
                                            className={style.menuSearchIcon}
                                            src={searchIcon}
                                            alt="search"
                                        />

                                        <input
                                            className={style.searchBar}
                                            placeholder="Search student by name/phone"
                                            value={searchTerm}
                                            onFocus={() => setIsSearchInputOnFocus(true)} // Set isSearchInputOnFocus to true when the input is focused
                                            onBlur={() =>
                                                setTimeout(
                                                    () => setIsSearchInputOnFocus(false),
                                                    200
                                                )
                                            }
                                            onChange={handlesearchInputChange}
                                        />
                                    </div>
                                </div>

                                {/*------------------- Sort functionality for students list  ----------------- */}

                                <div
                                    className="relative cursor-pointer"
                                    ref={sortDropdownRef}
                                >
                                    <div 
                                        className={`${style.sortDropdownBtn}`}
                                        onClick={handleSortDropBtnClick}
                                    >
                                        Sort by
                                        <img
                                            className={style.dropDownIcon}
                                            src={arrowDropdownIcon}
                                            alt="downarrow"
                                        />
                                    </div>

                                    {isSortOptionsVisible && (
                                        <div className="absolute flex flex-col p-2 gap-2 bg-white z-50 right-0 w-[200%] shadow-md shadow-gray-300">
                                            <div className="flex gap-2 items-center">
                                                <input
                                                    type="checkbox"
                                                    name="sort"
                                                    checked={selectedSortOption === 1}
                                                    onChange={() => {
                                                        handleSortOptionChange(1);
                                                    }}
                                                />
                                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                                    Student name (A - Z)
                                                </div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <input
                                                    type="checkbox"
                                                    name="sort"
                                                    checked={selectedSortOption === 2}
                                                    onChange={() => {
                                                        handleSortOptionChange(2);
                                                    }}
                                                />
                                                <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                                    Student name (Z - A)
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* <div className="flex flex-row">
                                    {dropSortBy && (
                                        <div className="flex flex-row gap-2 absolute  mt-7">
                                            <input
                                                type="checkbox"
                                                name="sorting"
                                                checked={selectedOption === 'Student name A-Z'}
                                                onChange={() => checkBoxHandler('Student name A-Z')}
                                            />
                                            <div className="text-[13px] leading-5 tracking-[0px] text-black text-xs font-bold">
                                                Student name A-Z
                                            </div>
                                        </div>
                                    )}
                                </div> */}

                                <div className={style.studentFilterIconContainer}>
                                    <img
                                        src={filterIcon}
                                        className={`${style.studentFilterIcon} cursor-pointer mr-2`}
                                        onClick={handleFilterBtnClick}
                                    />
                                </div>
                            </div>
                        </div>
                        {isStudentsFetchSuccess ? (
                            ((sortBy.length > 0 && sortBy) || students || []).map((item, index) => (
                                <div
                                    // ref={dropdownRef}
                                    className={style.studentsListItemContainer}
                                    key={index}
                                >
                                    <StudentsListItem
                                        // dropdownRef={dropdownRef}
                                        studentAttributes={item?.attributes}
                                        key={index}
                                        handleActionsButton={() => handleActionsButton(index)}
                                        goToStudentDetails={() => goToStudentDetails(item.id)}
                                        searchTerm={searchTerm}
                                    />
                                    {selectedStudentIndex === index && (
                                        <div
                                            ref={moreActionsDropDownRef}
                                            className={style.studentsOptionsDropdownContainer}
                                        >
                                            <DropdownList
                                                options={DROPDOWN_LISTS.filter(item => {
                                                    if (
                                                        userState?.userDetails?.attributes
                                                            ?.studentsAccess === 'restrictedView'
                                                    ) {
                                                        return (
                                                            item.action !== 'GO_TO_COLLECT_FEE' &&
                                                            item.action !==
                                                                'OPEN_RECORD_LEAVE_FORM' &&
                                                            item.action !==
                                                                'OPEN_STUDENT_DROPOUT_PROMPT' &&
                                                            item.action !== 'OPEN_STUDENT_EDIT_FORM'
                                                        );
                                                    } else {
                                                        return true;
                                                    }
                                                })}
                                                goToStudentDetails={() =>
                                                    goToStudentDetails(item.id)
                                                }
                                                goToFeeHistory={() => goToFeeHistory(item.id)}
                                                gotoFeeCollection={() => onCollectFeeClicked(item)}
                                                openRecordLeaveFormModal={() =>
                                                    openRecordLeaveFormModal(item)
                                                }
                                                openStudentDropoutConfirmation={() =>
                                                    openStudentDropoutConfirmation(item)
                                                }
                                                openStudentEditForm={() =>
                                                    openStudentEditForm(item)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        )}

                        <div className={style.studentsFooterContainer}>
                            <div className={style.studentsRecordsLabel}>
                                {indexOfFirstItem + 1}-{indexOfLastItem} of {total ?? 0} records
                            </div>
                            <div className={style.studentsRecordsDropdownContainerWrapper}>
                                <div>Show</div>
                                <div className={style.studentsRecordsDropdownContainer}>
                                    10{' '}
                                    <img
                                        className={style.studentsArrowDropdownIcon}
                                        src={arrowDropdownIcon}
                                        alt="down"
                                    />
                                </div>
                            </div>
                            <div className={style.studentsPaginationContainer}>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowLeftIcon}
                                    alt="arrowLeftIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: currentPage === 1 ? 1 : currentPage - 1,
                                        })
                                    }
                                />
                                <div className="overflow-x-scroll flex items-center sm:gap-[15px] gap-[8px] text-xs sm:text-sm">
                                    {Array.from({
                                        length: endPage - startPage + 1,
                                    }).map((_, index) => (
                                        <div
                                            className={`cursor-pointer ${
                                                startPage + index === currentPage
                                                    ? 'text-black cursor-default '
                                                    : 'text-blue-500'
                                            }`}
                                            key={startPage + index}
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload: startPage + index,
                                                })
                                            }
                                        >
                                            {startPage + index}
                                        </div>
                                    ))}
                                </div>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowRightIcon}
                                    alt="arrowRightIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload:
                                                currentPage + itemsPerPage + 1 > total
                                                    ? currentPage
                                                    : currentPage + 1,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={state.openTab === 2 ? style.contentContainerMain : 'hidden'}
                    id="link2"
                >
                    <div className="flex-1">
                        <div className={`${style.studentsHeader}`}>
                            <div className="flex sm:flex-row flex-col justify-between items-center w-full sm:gap-1 gap-2">
                                <div className="justify-between gap-2 flex flex-grow flex-col sm:flex-row w-full">
                                    <div className="items-center sm:flex justify-start sm:gap-3 hidden ">
                                        <input
                                            type="checkbox"
                                            onChange={handleAllEnrolmentStudentSelect}
                                            checked={isAllChecked}
                                            className={style.checkBoxInput}
                                        />
                                        <span className="text-xs">Select All</span>
                                    </div>
                                    <div className="flex gap-6 justify-between w-full sm:w-auto">
                                        <div className={`${style.studentsLabel} `}>
                                            Enrolments Sent: {totalEnrolment}
                                        </div>
                                        <div className={`${style.studentsLabel} `}>
                                            Enrolled: {studentsEnrolled}
                                        </div>
                                    </div>
                                    <div className="flex gap-6 justify-between w-full sm:w-auto">
                                        <button
                                            id="reminderBtn"
                                            onClick={handleEnrollButtonClick}
                                            className={`outline-slate-300 text-slate-400 flex items-center gap-2  sm:px-4 py-1 outline rounded font-semibold text-sm px-2 transition-all active:text-white active:bg-green-300`}
                                        >
                                            Approve
                                            <div className="rounded-full bg-slate-300 sm:h-6 sm:w-6 h-5 w-5  flex items-center justify-center">
                                                <img
                                                    src={tickIcon}
                                                    className="h-4 w-4"
                                                />
                                            </div>
                                        </button>
                                        <button
                                            id="reminderBtn"
                                            onClick={handleRejectButtonClick}
                                            className={`outline-slate-300 flex items-center gap-2 text-slate-400  sm:px-4 py-1 outline rounded font-semibold text-sm px-2 transition-all active:text-white active:bg-red-300`}
                                        >
                                            Reject
                                            <div className="rounded-full bg-slate-300 sm:h-6 sm:w-6 h-5 w-5 flex items-center justify-center">
                                                <img
                                                    src={crossIcon}
                                                    className="h-4 w-4"
                                                />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-between gap-2 sm:w-auto w-full">
                                    <div className="items-center sm:hidden justify-start gap-2 flex">
                                        <input
                                            type="checkbox"
                                            onChange={handleAllEnrolmentStudentSelect}
                                            checked={isAllChecked}
                                            className={style.checkBoxInput}
                                        />
                                        <span className="text-xs">Select All</span>
                                    </div>
                                    <div className={style.headerSearchContainerMain}>
                                        <div className={`${style.headerSearchContainer} relative`}>
                                            <img
                                                className={style.menuSearchIcon}
                                                src={searchIcon}
                                                alt="search"
                                            />

                                            <input
                                                className={style.searchBar}
                                                placeholder="Search email or number"
                                                value={searchTerm}
                                                onFocus={() => setIsSearchInputOnFocus(true)} // Set isSearchInputOnFocus to true when the input is focused
                                                onBlur={() =>
                                                    setTimeout(
                                                        () => setIsSearchInputOnFocus(false),
                                                        200
                                                    )
                                                }
                                                onChange={handlesearchInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isEnrolmentFormFetchSuccess ? (
                            (enrolmentFormData || [])
                                .slice(indexOfFirstItem, indexOfLastItem)
                                .map((item, index) => (
                                    <div
                                        className={style.studentsListItemContainer}
                                        key={index}
                                    >
                                        <EnrolmentListItem
                                            enrolmentAttributes={item?.attributes}
                                            allStudentsChecked={allSelfEnrolmentStudentChecked}
                                            handleEnrolmentStudentItemCheck={() =>
                                                handleEnrolmentStudentItemCheck(item)
                                            }
                                            isChecked={checkedEnrolmentListItems
                                                .map(item => item.id)
                                                .includes(item.id)}
                                        />
                                    </div>
                                ))
                        ) : (
                            <div className={style.loaderContainer}>
                                <Loader />
                            </div>
                        )}
                        <div className={style.studentsFooterContainer}>
                            <div className={style.studentsRecordsLabel}>
                                {indexOfFirstItem + 1}-{indexOfLastItem} of {totalEnrolment ?? 0}{' '}
                                records
                            </div>
                            <div className={style.studentsRecordsDropdownContainerWrapper}>
                                <div>Show</div>
                                <div className={style.studentsRecordsDropdownContainer}>
                                    10{' '}
                                    <img
                                        className={style.studentsArrowDropdownIcon}
                                        src={arrowDropdownIcon}
                                        alt="down"
                                    />
                                </div>
                            </div>
                            <div className={style.studentsPaginationContainer}>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowLeftIcon}
                                    alt="arrowLeftIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: currentPage === 1 ? 1 : currentPage - 1,
                                        })
                                    }
                                />
                                <div className="overflow-x-scroll flex items-center sm:gap-[15px] gap-[8px] text-xs sm:text-sm">
                                    {Array.from({
                                        length: endPageForEnrolment - startPage + 1,
                                    }).map((_, index) => (
                                        <div
                                            className={`cursor-pointer ${
                                                startPage + index === currentPage
                                                    ? 'text-black cursor-default '
                                                    : 'text-blue-500'
                                            }`}
                                            key={startPage + index}
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload: startPage + index,
                                                })
                                            }
                                        >
                                            {startPage + index}
                                        </div>
                                    ))}
                                </div>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowRightIcon}
                                    alt="arrowRightIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload:
                                                currentPage + itemsPerPage + 1 >
                                                totalPagesForEnrolment
                                                    ? currentPage
                                                    : currentPage + 1,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [
        state.academies,
        state.openTab,
        state.currentPage,
        state.students,
        state.selfEnrolment,
        studentFilterDropDownRef,
        enrollmentFilterDropDownRef,
        selectedStudentIndex,
        filteredStudents,
        totalEnrolment,
        studentsEnrolled,
        searchTerm,
        allSelfEnrolmentStudentChecked,
        checkedEnrolmentListItems,
        isAllChecked,
        enrolmentFormData,
        selectedClassesForFilter,
        isSortOptionsVisible,
        selectedSortOption,
        sortDropdownRef,
    ]);

    return (
        <NavMenu>
            {(isLeaveRecordPosting ||
                isStudentDropping ||
                isFeeCollectionUpdating ||
                isFeeCollectionAdding ||
                isBulkStudentsAdding ||
                isNewEnrolmentLoading ||
                isEnrolmentUpdating ||
                isClassFetching) && <FullScreenLoader />}
            {userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' ||
            userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                <div className={style.studentsContainer}>
                    <div className="flex justify-between flex-col sm:flex-row bg-white px-2 py-1 rounded">
                        {/* Tabs */}
                        <ul
                            className={`${style.tabListContainer}`}
                            role="tablist"
                        >
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${
                                        state.openTab == 1
                                            ? 'bg-white shadow-2xl border-2 border-gray-500'
                                            : 'bg-transparent border-2 border-gray-300 scale-95'
                                    }`
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setOpenTab',
                                        payload: 1,
                                    });
                                    setOpenTab(1);
                                }}
                            >
                                <a
                                    id="studentsTab"
                                    className={style.tabListItemLink}
                                    data-toggle="tab"
                                >
                                    Students
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${
                                        state.openTab == 2
                                            ? 'bg-white shadow-2xl border-2 border-gray-500 '
                                            : 'bg-transparent border-2 border-gray-300 scale-95'
                                    }`
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'setOpenTab',
                                        payload: 2,
                                    });
                                    setOpenTab(2);
                                }}
                            >
                                <a
                                    id="selfEnrolmentTab"
                                    className={style.tabListItemLink}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Self-Enrolment
                                </a>
                            </li>
                        </ul>
                        <div>
                            {state.openTab === 1 && (
                                <div className="">
                                    <div className={style.studentsAddButtonContainer}>
                                        {userState?.userDetails?.attributes?.studentsAccess !==
                                            'restrictedView' && (
                                            <>
                                                <div className="hidden items-center gap-1 sm:flex">
                                                    <img
                                                        src={downloadIcon}
                                                        className="h-8 w-8 p-1 border rounded-full transition-all hover:scale-110 active:scale-95 cursor-pointer"
                                                        onClick={handleDownloadSampleCsv}
                                                    />
                                                    <button
                                                        className="border-2 border-slate-400 text-slate-500 rounded px-3 py-1 transition-all active:scale-95"
                                                        onClick={handleBulkUploadStudentsModalOpen}
                                                    >
                                                        Bulk Upload Students
                                                    </button>
                                                </div>
                                                <button
                                                    className={style.studentsAddButton}
                                                    onClick={handleAddNewStudentBtnClick}
                                                >
                                                    <AiOutlinePlus /> Add new student
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* MAIN CONTENT */}
                    <div className={style.mainContentConatiner}>
                        <div className={style.studentsFilterContainer}>
                            <div className={style.studentsFilterTitle}>
                                <div className={style.filterTitle}>
                                    Filters
                                    <img
                                        className={style.filterIcon}
                                        src={filterIcon}
                                        alt="filter"
                                    />
                                </div>
                                {state.academies.length > 0 && renderFilters}
                            </div>
                        </div>
                        {renderMainContent}
                    </div>
                </div>
            ) : (
                <AccessDeniedPage />
            )}

            <CollectFees
                isOpen={isFeeCollectionModalOpen}
                handleClose={handleFeeCollectionModalClose}
                activeStudentData={selectedStudentData}
                isStudentChangable={false}
            />

            <PopupModal
                isOpen={isRecordLeaveModalOpen}
                handleModalClose={handleRecordLeaveModalClose}
            >
                <RecordLeaveForm
                    handleInputChange={handleRecordLeaveFormInputChange}
                    handleFormSubmit={handleRecordLeavesFormSubmit}
                    formValues={formValues}
                    studentData={recordLeaveModalStudentData}
                    isRecordPostSuccess={isRecordPostSuccess}
                />
            </PopupModal>

            <AddStudent
                isOpen={isNewStudentModalOpen}
                handleClose={handleNewStudentModalClose}
                isEdit={false}
            />

            <AddStudent
                isOpen={isEditStudentModalOpen}
                handleClose={handleEditStudentModalClose}
                isEdit={true}
                studentData={editingStudentData}
            />

            <PopupModal
                handleModalClose={handleDropStudentModalClose}
                isOpen={isDropStudentFormOpen}
                background="white"
                title="Drop Student"
            >
                <DropStudentForm
                    studentActiveAcademies={studentActiveAcademies}
                    handleStudentDropAction={handleStudentDropAction}
                    handleDropStudentAcademyCheck={handleDropStudentAcademyCheck}
                    checkedAcademiesToDropStudent={checkedAcademiesToDropStudent}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleFilterModalClose}
                isOpen={isfilterModalOpen}
                background="white"
            >
                <div className="w-[86vw] h-[80vh] p-2">
                    <div className="p-4 border rounded">
                        <div className={style.filterTitle}>
                            Filters
                            <img
                                className={style.filterIcon}
                                src={filterIcon}
                                alt="filter"
                            />
                        </div>
                        {renderFilters}
                    </div>
                </div>
            </PopupModal>

            <PopupModal
                handleModalClose={handleBulkUploadStudentsModalClose}
                isOpen={isBulkUploadFormOpen}
                background="white"
                title="Bulk Student Upload"
            >
                <BulkStudentUploadForm
                    selectedCsvFileData={selectedCsvFileData}
                    handleCsvFileInputChange={handleCsvFileInputChange}
                    allClassesName={
                        allClasses?.map(classItem => classItem?.attributes?.class_name) || []
                    }
                    csvFileInputRef={csvFileInputRef}
                    handleBulkStudentAddSubmit={handleBulkStudentAddSubmit}
                    handleExportInvaidStudentsDataToCsv={handleExportInvaidStudentsDataToCsv}
                />
            </PopupModal>
            <PopupModal
                handleModalClose={handleIsEnrolmentOrNewStudentModalClose}
                isOpen={isEnrolmentOrNewStudentModalOpen}
                background="white"
                title="Add Student"
            >
                <EnrolmentForm
                    handleAddEnrollmentFormOpen={handleAddEnrollmentFormOpen}
                    handleAddNewStudentFormOpen={handleAddNewStudentFormOpen}
                />
            </PopupModal>
            <PopupModal
                handleModalClose={handleEnrolmentFormDetailsModalClose}
                isOpen={isNewEnrolmentFormDetailsOpen}
                background="white"
                title="Send Enrolment Form"
            >
                <NewEnrolmentFormDetails
                    handleNextButtonClick={handleEnrolmentFormDetailsSubmit}
                    listOfAllAcademies={mapAcademiesToOptions}
                    listOfAllClasses={mapClassestoOptionsForEnrolmentForm}
                    handleInputChange={handleEnrolmentDataInputChange}
                    enrolmentCardDetails={enrolmentData}
                    handleAddClick={handleEnrolmentFormAddClick}
                    currentEnrolmentData={currentEnrolmentData}
                    allClasses={mapAllClassesToOptionsIrrespectiveOfAcademy}
                    onEditClick={handleEnrolmentCardEditClick}
                    onDeleteClick={handleEnrolmentCardDeleteClick}
                    isEmail={isEmail}
                    academiesWithoutContactInfo={academiesWithoutContactInfo || []}
                    handleEmailSwitch={handleEmailSwitch}
                />
            </PopupModal>
        </NavMenu>
    );
};

export default Students;
