import axios from 'axios';
import Select, { SingleValue, MultiValue } from 'react-select';
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import ProgressTrackerViewModel from '../../../view-models/progress-tracker-view-model';
import { getJWTToken } from '../../../../utils/helpers/helpers';

interface AddEditCertificationFormProps {
    isOpen: boolean;
    onClose: () => void;
    initialData?: CertificationFormData | undefined; // Optional data for editing
    academySelected: number | null;
    selectDiscipline: string | number | null;
    fetchcertifications: () => Promise<void>;
    mode: 'edit' | 'add';
    id: number | undefined;
}

interface CertificationFormData {
    Name: string;
    level: number;
    students: string[];
    academy: string;
    discipline: string;
    users: string;
    Remarks: string;
    assignment_technique_requests: number[];
}

interface OptionType {
    value: number;
    label: string;
}

const AddEditCertificationForm: React.FC<AddEditCertificationFormProps> = ({
    isOpen,
    onClose,
    initialData,
    academySelected,
    selectDiscipline,
    fetchcertifications,
    mode,
    id,
}) => {
    const {
        mapAcademyToOptions,
        mapTechniqueToOptions,
        handleAcademyInputChange,
        handleTechniqueInputChange,
        userState,
    } = ProgressTrackerViewModel();

    const [formData, setFormData] = useState<CertificationFormData>({
        Name: initialData ? initialData.Name : '',
        level: initialData ? initialData.level : 0,
        students: initialData ? initialData.students : [],
        academy: initialData ? initialData.academy : '',
        discipline: initialData ? initialData.discipline : '',
        users: initialData ? initialData.users : '',
        Remarks: initialData ? initialData.Remarks : '',
        assignment_technique_requests:
            mode === 'add' ? (initialData ? initialData.assignment_technique_requests : []) : [], // Initialize as an empty array
    });

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        }
    }, [initialData]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleTechniqueChange = (selectedOptions: MultiValue<OptionType>) => {
        setFormData({
            ...formData,
            assignment_technique_requests: selectedOptions.map(
                (option: OptionType) => option.value
            ),
        });
    };

    // useEffect(() => {
    //     console.log(academySelected, selectDiscipline)
    // }, [selectDiscipline, academySelected])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const data = {
            ...formData,
            academy: academySelected?.toString() || '',
            discipline: selectDiscipline?.toString() || '',
            users: userState.user?.id.toString() || '',
            students: [],
        };

        // console.log({
        //     data: data,
        //     academy: academySelected,
        //     selectDiscipline: selectDiscipline,
        //     users: userState.user?.id,
        // });

        try {
            if (mode === 'add') {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/certifications`,
                    { data: data }, // Use the 'data' object here
                    {
                        headers: {
                            Authorization: `Bearer ${getJWTToken()}`,
                        },
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    fetchcertifications();
                    onClose();
                } else {
                    console.error('Error:', response.statusText);
                }
            } else if (mode === 'edit') {
                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/api/certifications/${id}`,
                    { data: data }, // Use the 'data' object here
                    {
                        headers: {
                            Authorization: `Bearer ${getJWTToken()}`,
                        },
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    fetchcertifications();
                    onClose();
                } else {
                    console.error('Error:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed max-h-screen sm:h-full inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[1000] overflow-y-scroll">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-scroll">
                <h2 className="text-2xl mb-4">
                    {initialData ? 'Edit Certification' : 'Add Certification'}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Level</label>
                        <input
                            type="number"
                            name="level"
                            value={formData.level}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Remarks</label>
                        <input
                            type="text"
                            name="Remarks"
                            value={formData.Remarks}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Assignment Techniques</label>
                        <Select
                            isMulti
                            options={mapTechniqueToOptions()}
                            className="text-sm text-gray-500 resize-none border-gray-500 border-opacity-30 rounded disabled:bg-slate-100 w-full"
                            classNamePrefix="Select Classes"
                            onChange={handleTechniqueChange}
                            value={mapTechniqueToOptions().filter((item: OptionType) =>
                                formData.assignment_technique_requests?.includes(item.value)
                            )}
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 mr-2 bg-gray-600 text-white rounded-lg"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEditCertificationForm;
