import React, { useMemo } from 'react';
import NavMenu from '../../components/widgets/nav-menu';
import style from './style.module.css';
import arrowDropdownIcon from '../../../assets/icons/arrow-dropdown.png';
import filterIcon from '../../../assets/icons/filter.png';
import arrowLeftIcon from '../../../assets/icons/chevron-left.png';
import arrowRightIcon from '../../../assets/icons/chevron-right.png';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import FilterCheckBoxItem from '../../components/pieces/filter-check-box-item';
import { AiOutlinePlus } from 'react-icons/ai';
import CoachListItem from '../../components/pieces/coach-list-item';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import NewUserForm from '../../components/widgets/new-user-form';
import UserManagementViewModel from '../../view-models/user-management-view-model';

const UserManagement = () => {
    const {
        UsersList,
        isUserDetailsDataFetchSuccess,
        visibleFilter,
        toggleVisibleFilter,
        isfilterModalOpen,
        handleFilterModalClose,
        indexOfFirstItem,
        indexOfLastItem,
        itemsPerPage,
        currentPage,
        total,
        state,
        dispatch,
        handleFilterBtnClick,
        isNewCoachModalOpen,
        handleAddNewCoachBtnClick,
        handleNewCoachModalClose,
        isEditCoachModalOpen,
        handleEditCoachModalClose,
        onAcademyFilterSelect,
        expandedCoachItem,
        handleCoachItemExpand,
        profileImageInputKey,
        handleProfileImgChange,
        isCoachProfileImagePosting,
        coachFormData,
        mapAcademiesToOptions,
        handleInputChange,
        handleAddNewCoach,
        coachDocumentInputKey,
        handleCoachDocumentChange,
        isCoachDocumentPosting,
        mapDiciplinesOfSelectedAcademiesToOptions,
        // mapClassesOfSelectedDisciplinesToOptions,
        isCoachAcademiesListExpanded,
        handleCoachListItemAcademiesExpandClick,
        handleUserEditClick,
        coachEditingData,
        isNewCoachUserAdding,
        isNewCoachAdding,
        isCoachUserUpdating,
        isCoachUpdating,
        switchTab,
        activeUserFormTab,
        checkedQuickAccess,
        handleQuickAccessCheck,
        mapClassesOfSelectedAcademiesToOptions,
        handleBlockUserClick,
        handleUnblockUserClick,
        isUserBlocking,
        currentUserNames,
        currentUserEmails,
        allDisciplinesOptions,
        academiesDisciplinesSelected,
        handleDisciplineChange,
    } = UserManagementViewModel();

    const renderFilters = useMemo(() => {
        return (
            <>
                <div>
                    <div className={style.filterLabelContentWrapper}>
                        <div
                            className={style.filterName}
                            onClick={() => toggleVisibleFilter(1)}
                        >
                            Academy Name
                        </div>
                        {visibleFilter === 1 && (
                            <div className={style.filterItemsContainer}>
                                {state.academies.map((academy, index) => (
                                    <FilterCheckBoxItem
                                        key={index}
                                        filterName={academy?.attributes?.name}
                                        onFilterSelected={onAcademyFilterSelect.bind(
                                            this,
                                            academy.id
                                        )}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={style.filterLabelContentWrapper}>
                        <div
                            className={style.filterName}
                            onClick={() => toggleVisibleFilter(2)}
                        >
                            Discipline
                        </div>
                        {visibleFilter === 2 && (
                            <div className={style.filterItemsContainer}>
                                {state.academies.map((academy, index) => (
                                    <FilterCheckBoxItem
                                        key={index}
                                        filterName={academy?.attributes?.name}
                                        onFilterSelected={onAcademyFilterSelect.bind(
                                            this,
                                            academy.id
                                        )}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }, [state.academies, visibleFilter]);

    return (
        <NavMenu>
            {(isNewCoachUserAdding ||
                isNewCoachAdding ||
                isCoachUserUpdating ||
                isCoachUpdating ||
                isUserBlocking) && <FullScreenLoader />}
            <div className={style.studentsContainer}>
                <div className={style.studentsLeftPanelContainer}>
                    <div className={style.studentsLabelContainer}>
                        <div className={style.studentsLabel}>Users</div>
                    </div>
                    {/* Coaches Filter Container */}
                    <div className={style.studentsFilterContainer}>
                        <div className={style.studentsFilterTitle}>
                            <div className={style.filterTitle}>
                                Filters
                                <img
                                    className={style.filterIcon}
                                    src={filterIcon}
                                    alt="filter"
                                />
                            </div>
                            {state.academies.length > 0 && renderFilters}
                        </div>
                    </div>
                </div>
                {/* Coach List Container */}
                <div className={style.studentsContentContainer}>
                    <div className={style.studentsAddButtonContainer}>
                        <button
                            className={style.studentsAddButton}
                            onClick={handleAddNewCoachBtnClick}
                        >
                            <AiOutlinePlus /> Add new user
                        </button>
                    </div>

                    {isUserDetailsDataFetchSuccess ? (
                        <div>
                            <div className={`${style.studentsHeader} relative`}>
                                <div className={style.studentsHeaderLabelContainer}>
                                    <div className={style.studentsLabel}>Total users: {total}</div>

                                    {/* <div
                                        className={
                                            style.headerSearchContainerMain
                                        }>
                                        <div
                                            className={`${style.headerSearchContainer} relative`}>
                                            <img
                                                className={style.menuSearchIcon}
                                                src={searchIcon}
                                                alt="search"
                                            />

                                            <input
                                                className={style.searchBar}
                                                placeholder="Pick student"
                                                value={searchTerm}
                                                onFocus={() =>
                                                    setIsSearchInputOnFocus(
                                                        true,
                                                    )
                                                } // Set isSearchInputOnFocus to true when the input is focused
                                                onBlur={() =>
                                                    setTimeout(
                                                        () =>
                                                            setIsSearchInputOnFocus(
                                                                false,
                                                            ),
                                                        200,
                                                    )
                                                }
                                                onChange={
                                                    handlesearchInputChange
                                                }
                                            />
                                        </div>
                                    </div> */}

                                    <div className={style.studentFilterIconContainer}>
                                        <img
                                            src={filterIcon}
                                            className={`${style.studentFilterIcon} cursor-pointer mr-2`}
                                            onClick={handleFilterBtnClick}
                                        />
                                    </div>
                                </div>
                            </div>

                            {UsersList?.map((item, index) => (
                                <div
                                    className={style.studentsListItemContainer}
                                    key={index}
                                >
                                    <CoachListItem
                                        isExpandable={true}
                                        isCoachItemExpanded={expandedCoachItem === item?.id}
                                        coachData={item}
                                        handleCoachItemExpand={() => handleCoachItemExpand(item.id)}
                                        isCoachAcademiesListExpanded={isCoachAcademiesListExpanded}
                                        handleCoachListItemAcademiesExpandClick={
                                            handleCoachListItemAcademiesExpandClick
                                        }
                                        handleUserEditClick={() => handleUserEditClick(item)}
                                        handleBlockUserClick={() => handleBlockUserClick(item)}
                                        handleUnblockUserClick={() => handleUnblockUserClick(item)}
                                    />
                                </div>
                            ))}

                            <div className={style.studentsFooterContainer}>
                                <div className={style.studentsRecordsLabel}>
                                    {indexOfFirstItem + 1}-{indexOfLastItem} of{' '}
                                    {UsersList?.length ?? 0} records
                                </div>
                                <div className={style.studentsRecordsDropdownContainerWrapper}>
                                    <div>Show</div>
                                    <div className={style.studentsRecordsDropdownContainer}>
                                        10{' '}
                                        <img
                                            className={style.studentsArrowDropdownIcon}
                                            src={arrowDropdownIcon}
                                            alt="down"
                                        />
                                    </div>
                                </div>
                                <div className={style.studentsPaginationContainer}>
                                    <img
                                        className={style.studentsPaginationIcon}
                                        src={arrowLeftIcon}
                                        alt="arrowLeftIcon"
                                        onClick={() =>
                                            dispatch({
                                                type: 'setCurrentPage',
                                                payload: currentPage == 1 ? 1 : currentPage - 1,
                                            })
                                        }
                                    />
                                    {Array.from({
                                        length: Math.ceil(total / itemsPerPage),
                                    }).map((_, index) => (
                                        <div
                                            className={`cursor-pointer ${
                                                index + 1 == currentPage
                                                    ? 'text-black'
                                                    : 'text-blue-500'
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload: index + 1,
                                                })
                                            }
                                        >
                                            {index + 1}
                                        </div>
                                    ))}
                                    <img
                                        className={style.studentsPaginationIcon}
                                        src={arrowRightIcon}
                                        alt="arrowRightIcon"
                                        onClick={() =>
                                            dispatch({
                                                type: 'setCurrentPage',
                                                payload:
                                                    currentPage + itemsPerPage + 1 > total
                                                        ? currentPage
                                                        : currentPage + 1,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={style.loaderContainer}>
                            <Loader />
                        </div>
                    )}
                </div>
            </div>

            <PopupModal
                handleModalClose={handleNewCoachModalClose}
                isOpen={isNewCoachModalOpen}
                background="white"
                title="Add User"
            >
                <NewUserForm
                    isEditMode={false}
                    profileImageInputKey={profileImageInputKey}
                    coachDocumentInputKey={coachDocumentInputKey}
                    handleProfileImgChange={handleProfileImgChange}
                    isCoachProfileImagePosting={isCoachProfileImagePosting}
                    coachFormData={coachFormData}
                    mapAcademiesToOptions={mapAcademiesToOptions}
                    handleInputChange={handleInputChange}
                    handleAddNewCoach={handleAddNewCoach}
                    handleCoachDocumentChange={handleCoachDocumentChange}
                    isCoachDocumentPosting={isCoachDocumentPosting}
                    mapDiciplinesOfSelectedAcademiesToOptions={
                        mapDiciplinesOfSelectedAcademiesToOptions
                    }
                    // mapClassesOfSelectedDisciplinesToOptions={mapClassesOfSelectedDisciplinesToOptions}
                    mapClassesOfSelectedAcademiesToOptions={mapClassesOfSelectedAcademiesToOptions}
                    activeUserFormTab={activeUserFormTab}
                    switchTab={switchTab}
                    checkedQuickAccess={checkedQuickAccess}
                    handleQuickAccessCheck={handleQuickAccessCheck}
                    currentUserNames={currentUserNames}
                    currentUserEmails={currentUserEmails}
                    allDisciplinesOptions={allDisciplinesOptions}
                    academiesDisciplinesSelected={academiesDisciplinesSelected}
                    handleDisciplineChange={handleDisciplineChange}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleEditCoachModalClose}
                isOpen={isEditCoachModalOpen}
                background="white"
                title="Edit Coach"
            >
                <NewUserForm
                    isEditMode={true}
                    profileImageInputKey={profileImageInputKey}
                    coachDocumentInputKey={coachDocumentInputKey}
                    handleProfileImgChange={handleProfileImgChange}
                    coachFormData={coachFormData}
                    mapAcademiesToOptions={mapAcademiesToOptions}
                    handleInputChange={handleInputChange}
                    handleAddNewCoach={handleAddNewCoach}
                    handleCoachDocumentChange={handleCoachDocumentChange}
                    isCoachDocumentPosting={isCoachDocumentPosting}
                    mapDiciplinesOfSelectedAcademiesToOptions={
                        mapDiciplinesOfSelectedAcademiesToOptions
                    }
                    // mapClassesOfSelectedDisciplinesToOptions={mapClassesOfSelectedDisciplinesToOptions}
                    mapClassesOfSelectedAcademiesToOptions={mapClassesOfSelectedAcademiesToOptions}
                    activeUserFormTab={activeUserFormTab}
                    coachEditingData={coachEditingData}
                    switchTab={switchTab}
                    checkedQuickAccess={checkedQuickAccess}
                    handleQuickAccessCheck={handleQuickAccessCheck}
                    currentUserNames={currentUserNames}
                    currentUserEmails={currentUserEmails}
                    allDisciplinesOptions={allDisciplinesOptions}
                    academiesDisciplinesSelected={academiesDisciplinesSelected}
                    handleDisciplineChange={handleDisciplineChange}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleFilterModalClose}
                isOpen={isfilterModalOpen}
                background="white"
            >
                <div className="w-[80vw] h-[80vh] p-2">
                    <div className="p-4 border rounded">
                        <div className={style.filterTitle}>
                            Filters
                            <img
                                className={style.filterIcon}
                                src={filterIcon}
                                alt="filter"
                            />
                        </div>
                        {renderFilters}
                    </div>
                </div>
            </PopupModal>
        </NavMenu>
    );
};

export default UserManagement;
