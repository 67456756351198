import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './style.module.css';
import NavMenu from '../../components/widgets/nav-menu';
import locationPinIcon from '../../../assets/icons/location-pin.png';
import inboxIcon from '../../../assets/icons/inbox.png';
// import userIdBadgeIcon from '../../../assets/icons/user-id-badge.png';
import phoneIcon from '../../../assets/icons/phone-call.png';
// import arrowDownIcon from '../../../assets/icons/arrow-dropdown-white.png';
import arrowDown from '../../../assets/icons/down-arrow.png';
import arrowUp from '../../../assets/icons/up-arrow.png';
import StudentDetailsViewModel from '../../view-models/student-details-view-model';
import StudentRelationItem from '../../components/pieces/student-relation-item';
import Loader from '../../components/bits/loader';
import PopupModal from '../../components/widgets/popup-modal';
import NewStudentForm from '../../components/widgets/new-student-form';
import DropStudentForm from '../../components/widgets/drop-student-form';
import {
    formatDate,
    formatTimeToAMPM,
    getFormattedDate,
    getFormattedWeekDay,
} from '../../../utils/helpers/helpers';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import Academies from '../academies';
import AcademiesViewModel from '../../view-models/academies-view-model';
import AddStudent from '../../actions/add-student';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import RecordFitnessTest from '../../actions/record-fitness-test';
import DropdownList from '../../components/widgets/dropdown-list';
import { DROPDOWN_LISTS } from '../students/data';
import { StudentData } from '../../../models/student/student-data';
import CollectFees from '../../actions/collect-fees';
import RecordLeaveForm from '../../components/widgets/record-leave-form';
import threeDotIcon from '../../../assets/icons/horizontal-ellipsis.png'
import {LineChart,Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import FullScreenLoader from '../../components/bits/fullscreen-loader';

const StudentDetails = () => {
    const {
        studentDetailsData,
        openTab,
        setOpenTab,
        isDetailsFetchSuccess,
        isProfileDropdownOpen,
        userState,
        handleStudentDropout,
        handleEditStudent,
        handleEditStudentModalClose,
        isEditStudentModalOpen,
        handleDropStudentModalClose,
        isDropStudentFormOpen,
        studentActiveAcademies,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        isFeeHistoryDropdownOpen,
        handleFeeListCardClick,
        studentsFeeData,
        selectedFeeCard,
        ownersAcademies,
        formattedStudentAttendance,
        activeClassesOfStudent,
        handleClassChangeInAttendance,
        selectedClassForAttendance,
        isAttendanceEditModeOn,
        handleEditAttendanceClick,
        handleDayClickOnEditMode,
        selectedCalendarDate,
        getDayClassName,
        tileContent,
        getDayClassNameForEdit,
        tileContentForEdit,
        isPresentOnSelectedDate,
        toggleAttendanceValue,
        handleAttendanceChangesSubmit,
        handleCancelEditingClick,
        isOnLeaveOnSelectedDate,
        hasClassOnSelectedDate,
        previousClassesOfStudent,
        handleArchiveFeeRecord,
        handleGenerateFeeReport,
        academyExpand,
        handleAcademyClick,
        currentClassEnrollmentDate,
        studentsEvaluationData,
        assignmentSubmissions,
        studentFitnessTests,
        expandedFitnessCard,
        handleExpandCollapseFitnessCard,
        isRecordFitnessTestModalOpen,
        handleRecordFitnessTestModalClose,
        selectedRecordedFitnessTest,
        handleRecordFitnessTestModalOpen,
        goToFeeHistory,
        onCollectFeeClicked,
        isMenuOpen,
        handleMenu,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        openRecordLeaveFormModal,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        formValues,
        recordLeaveModalStudentData,
        isRecordPostSuccess,
        moreActionsDropDownRef,
        studentAttendanceArray,
        isAttendanceUpdating,
        isAttendancePosting
    } = StudentDetailsViewModel();

    const renderStudentFeeHistory = useMemo(() => {
        
        return (
            <div className="bg-white">
                <div className="w-full flex justify-center items-center p-6">
                    <div
                        className="px-4 py-1 h-full bg-primary cursor-pointer rounded text-white active:scale-90 transition-all"
                        onClick={handleGenerateFeeReport}
                    >
                        Download Report
                    </div>
                </div>
                {studentsFeeData?.map(data => (
                    <div
                        key={data?.id}
                        className="w-full p-4 border-b-4 border-x-4 border-gray-200 rounded-b flex text-[13px] drop-shadow-lg shadow-gray-300 shadow-inner"
                    >
                        <div className="flex-grow flex flex-col gap-2">
                            <div className="w-full grid grid-cols-2 ">
                                <div className="font-semibold text-[16px] sm:flex gap-2">
                                    Class: {data?.attributes?.class?.data?.attributes?.class_name}
                                    <div className="flex gap-2">
                                        {data?.attributes?.archived === true && (
                                            <button className="font-normal text-[11px] px-2 bg-slate-300 rounded h-[20px]">
                                                Archived
                                            </button>
                                        )}
                                        {!studentDetailsData?.data?.data.attributes.classes.data
                                            .map(item => item.id)
                                            .includes(data?.attributes?.class?.data?.id) && (
                                            <button className="font-normal text-[11px] px-2 bg-red-200 rounded h-[20px]">
                                                Inactive
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div>Fee Schedule: {data?.attributes?.payment_schedule}</div>
                            </div>
                            <div className="w-full grid grid-cols-4">
                                <div>
                                    Last Payment On:{' '}
                                    {getFormattedDate(data?.attributes?.fee_dates[0]?.fee_date)}
                                </div>
                                <div>Amount Paid: ₹{data?.attributes?.fee_dates[0]?.amount}</div>
                                <div>Balance Amount: ₹{data?.attributes?.balance}</div>
                                <div>
                                    Due Date:{' '}
                                    {getFormattedDate(data?.attributes?.fee_dates[0]?.dueDate)}
                                </div>
                            </div>
                            {selectedFeeCard.includes(data?.id) && (
                                <div className="w-full p-2 gap-2 flex">
                                    <div className="border border-gray-200 flex-grow grid grid-cols-2 p-2">
                                        <div>
                                            Start Date:{' '}
                                            {getFormattedDate(
                                                data?.attributes?.fee_dates[0].startDate
                                            )}
                                        </div>
                                        <div>
                                            Payment Mode:{' '}
                                            {data?.attributes?.fee_dates[0].payment_mode}
                                        </div>
                                        <div className="col-span-2">
                                            Message: {data?.attributes?.fee_dates[0].message}
                                        </div>
                                    </div>
                                    {data?.attributes?.archived === true ? (
                                        <></>
                                    ) : studentDetailsData?.data?.data.attributes.classes.data
                                          .map(item => item.id)
                                          .includes(data?.attributes?.class?.data?.id) ? (
                                        <div></div>
                                    ) : (
                                        <div
                                            className="px-4 py-1 h-full bg-primary cursor-pointer rounded text-white flex items-center"
                                            onClick={() => handleArchiveFeeRecord(data?.id)}
                                        >
                                            Archive
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="w-6 flex items-center">
                            {selectedFeeCard.includes(data?.id) ? (
                                <img
                                    src={arrowUp}
                                    className="w-6 h-6 cursor-pointer"
                                    onClick={() => handleFeeListCardClick(data?.id)}
                                />
                            ) : (
                                <img
                                    src={arrowDown}
                                    className="w-6 h-6 cursor-pointer"
                                    onClick={() => handleFeeListCardClick(data?.id)}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    }, [openTab, selectedFeeCard, isFeeHistoryDropdownOpen, studentsFeeData]);

    const renderStudentPrimaryDetails = useMemo(() => {
        return (
            <div>
                {/* <div className={style.tabDropdownToggleContainer} onClick={() => handleDropdownToggle('PROFILE')}>
                    PROFILE
                    {isProfileDropdownOpen ?
                            <img
                                src={arrowDown}
                                className={style.expandMinimizeIcon}
                            />
                        :
                            <img
                                src={arrowUp}
                                className={style.expandMinimizeIcon}
                            />
                    }
                </div> */}
                <div
                    className={`${style.studentDetailsContainerWrapper} ${
                        isProfileDropdownOpen ? style.hideOnMobileScreen : ''
                    }`}
                >
                    <div className={style.studentDetailsContainer}>
                        <div className={style.studentPrimaryAndContactGrid}>
                            <div className={style.studentPrimaryDetailscontainer}>
                                <div className={style.studentProfileContentDetailsLabel}>
                                    Primary details
                                </div>
                                <br />
                                <div className={style.studentPrimaryDetailsContainer}>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            First Name
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.firstName ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Last Name
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.lastName ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Gender
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.gender ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Physically Handicapped
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data.attributes
                                                    ?.handicapped
                                                    ? 'Yes'
                                                    : 'No'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Middle Name
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    .middleName ?? '-'}
                                            </div>
                                        </div>
                                        {/* <div
                                            className={
                                                style.studentProfileAcademicDetailsLabel
                                            }>
                                            Display Name
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }>
                                                Chandak
                                            </div>
                                        </div> */}
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Date of Birth
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes?.dob ??
                                                    ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Blood Group
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.bloodGroup ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.studentContactDetailscontainer}>
                                <div className={style.studentProfileContentDetailsLabel}>
                                    Contact details
                                </div>
                                <br />
                                <div className={style.studentPrimaryDetailsContainer}>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Parent’s Email ID
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.parentEmailId ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Current Address
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.currentAddress ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Parent’s contact number
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.parentContactNo ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Permanent Address
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.apartmentName ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-1" />
                                <div className={style.studentPrimaryDetailsContainer}>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            School
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.school ?? ''}
                                            </div>
                                        </div>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Grade
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.grade ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.studentPrimaryDetails}>
                                        <div className={style.studentProfileAcademicDetailsLabel}>
                                            Board
                                            <div
                                                className={
                                                    style.studentProfileAcademicDetailsContent
                                                }
                                            >
                                                {studentDetailsData?.data?.data?.attributes
                                                    ?.board ?? ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={style.emergencyContactsDetailsContainer}>
                            <div className={style.studentProfileContentDetailsLabel}>
                                Emergency contacts
                            </div>
                            <div>
                                {studentDetailsData?.data?.data?.attributes?.studentRelation.map(
                                    (relation, index) => (
                                        <StudentRelationItem
                                            key={index}
                                            relation={relation}
                                            index={index}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    {/* temporarily commented out for demo */}

                    {/* <div className={style.studentProfileEditButtonContainer}>
                        <div className={style.studentProfileEditButton}>
                            Edit Profile
                        </div>
                    </div> */}

                    {/* temporarily commented out for demo */}
                </div>
            </div>
        );
    }, [studentDetailsData, isProfileDropdownOpen]);

    const renderStudentAttendanceInfo = useMemo(() => {
        
        return (
            <>
                {(isAttendancePosting || isAttendanceUpdating) && <FullScreenLoader/>}
            <div className={style.studentAttendanceTopCalendarContainer}>
                <div className={style.classSelectDownloadBtnWrapper}>
                        {/* <span className='text-sm'>Last 12 months trends</span> */}
                        <span className='text-lg'>Select Class</span>
                        <select
                            className={style.classSelectOptionTop}
                            onChange={e => handleClassChangeInAttendance(e)}
                            value={selectedClassForAttendance}
                            disabled={isAttendanceEditModeOn}
                        >
                            {/* <option key={0} value={0} className={style.classOptionsForAttendance}>All Classes</option> */}
                            {[
                                ...(activeClassesOfStudent || []),
                                ...(previousClassesOfStudent || []),
                            ]
                                ?.filter(
                                    (obj, index, self) =>
                                        index === self?.findIndex(o => o?.id === obj?.id)
                                )
                                .map(item => (
                                    <option
                                        key={item?.id}
                                        value={item?.id}
                                        className={style.classOptionsForAttendance}
                                    >
                                        {item?.attributes?.class_name}
                                    </option>
                                ))}
                        </select>
                        {/* <div className={style.editAttendanceBtn}>Download</div> */}
                    </div>
                   
                    {/* <ResponsiveContainer width="80%" height={300}>
                        <LineChart data={studentAttendanceArray} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="present" stroke="#82ca9d" name="Present" />
                        </LineChart>
                    </ResponsiveContainer> */}
            </div>
            
            <div className={style.attendanceTabcontainer}>
                
                {<div className={style.studentAttendanceCalendarContainer}>
                    <div className={style.classSelectEditBtnWrapper}>
                        <select
                            className={style.classSelectOption}
                            onChange={e => handleClassChangeInAttendance(e)}
                            value={selectedClassForAttendance}
                            // disabled
                        >
                            {[
                                ...(activeClassesOfStudent || []),
                                ...(previousClassesOfStudent || []),
                            ]
                                ?.filter(
                                    (obj, index, self) =>
                                        index === self?.findIndex(o => o?.id === obj?.id)
                                )
                                .map(item => (
                                    <option
                                        key={item?.id}
                                        value={item?.id}
                                        className={style.classOptionsForAttendance}
                                    >
                                        {item?.attributes?.class_name}
                                    </option>
                                ))}
                        </select>
                        {activeClassesOfStudent
                            ?.map(item => item.id)
                            ?.includes(selectedClassForAttendance ?? 0) ? (
                            <div className={style.classOptionActiveTag}>Active</div>
                        ) : (
                            <div className={style.classOptionInactiveTag}>Inactive</div>
                        )}
                        {!isAttendanceEditModeOn && (
                            <div
                                className={style.editAttendanceBtn}
                                onClick={handleEditAttendanceClick}
                            >
                                Edit Attendance
                            </div>
                        )}
                    </div>
                    {isAttendanceEditModeOn ? (
                        <Calendar
                            tileClassName={({ date, view }) =>
                                getDayClassNameForEdit({ date, view })
                            }
                            tileContent={tileContentForEdit}
                            className={`font-semibold !border-4 !border-black-600 !w-[96vw] sm:!w-[800px] `}
                            onClickDay={(value, event) => handleDayClickOnEditMode(value)}
                            minDate={currentClassEnrollmentDate}
                            maxDate={new Date()}
                        />
                    ) : (
                        <Calendar
                            tileClassName={({ date, view }) => getDayClassName({ date, view })}
                            tileContent={tileContent}
                            className={`font-semibold !border-4 !border-black-600 !w-[96vw] sm:!w-[800px] !bg-slate-100 `}
                        />
                    )}
                </div>}
                {isAttendanceEditModeOn && (
                    <div className={style.editSectionContainer}>
                        <div className={style.cancelSaveBtnWrapper}>
                            <div
                                className={style.cancelEditBtn}
                                onClick={handleCancelEditingClick}
                            >
                                Cancel
                            </div>
                            <div
                                className={style.saveAttendanceBtn}
                                onClick={handleAttendanceChangesSubmit}
                            >
                                Submit changes
                            </div>
                        </div>
                        <div className={style.editSectionInfoContainer}>
                            <div className={style.sectionHeader}>Edit Attendance</div>
                            <div className="text-xs text-gray-500 font-semibold">
                                Only allowed to edit attendance from the enrollment date of student
                            </div>
                            <hr className={style.divider} />
                            <div>
                                <div>
                                    Selected class:{' '}
                                    {[
                                        ...(activeClassesOfStudent || []),
                                        ...(previousClassesOfStudent || []),
                                    ]
                                        ?.filter(
                                            (obj, index, self) =>
                                                index === self?.findIndex(o => o?.id === obj?.id)
                                        )
                                        ?.filter(item => item?.id === selectedClassForAttendance)[0]
                                        ?.attributes?.class_name ??
                                        (activeClassesOfStudent &&
                                            activeClassesOfStudent[0]?.attributes?.class_name)}
                                </div>
                                <div>
                                    Discipline:{' '}
                                    {[
                                        ...(activeClassesOfStudent || []),
                                        ...(previousClassesOfStudent || []),
                                    ]
                                        ?.filter(
                                            (obj, index, self) =>
                                                index === self?.findIndex(o => o?.id === obj?.id)
                                        )
                                        ?.filter(item => item?.id === selectedClassForAttendance)[0]
                                        ?.attributes?.class_discipline?.data?.attributes?.name ??
                                        (activeClassesOfStudent &&
                                            activeClassesOfStudent[0]?.attributes?.class_discipline
                                                ?.data?.attributes?.name)}
                                </div>
                                <div>
                                    Skill level:{' '}
                                    {[
                                        ...(activeClassesOfStudent || []),
                                        ...(previousClassesOfStudent || []),
                                    ]
                                        ?.filter(
                                            (obj, index, self) =>
                                                index === self?.findIndex(o => o?.id === obj?.id)
                                        )
                                        ?.filter(item => item?.id === selectedClassForAttendance)[0]
                                        ?.attributes?.skill_level ??
                                        (activeClassesOfStudent &&
                                            activeClassesOfStudent[0]?.attributes?.skill_level)}
                                </div>
                            </div>
                            <hr className={style.divider} />
                            {selectedCalendarDate ? (
                                <>
                                    <div>
                                        <div className={style.attendanceDate}>
                                            {selectedCalendarDate.toLocaleDateString()}
                                            {isOnLeaveOnSelectedDate && (
                                                <span className="p-1 rounded m-1 bg-red-200 text-white">
                                                    On Leave
                                                </span>
                                            )}
                                        </div>
                                        {isPresentOnSelectedDate === null ? (
                                            <div className={style.studentNotMarked}>
                                                <FaCheck
                                                    className={style.presentMarkerIcon}
                                                    onClick={() => toggleAttendanceValue('present')}
                                                />
                                                Not Marked
                                                <IoClose
                                                    className={style.onleaveMarkerIcon}
                                                    onClick={() => toggleAttendanceValue('absent')}
                                                />
                                            </div>
                                        ) : isPresentOnSelectedDate === false ? (
                                            <div className={style.studentAbsent}>
                                                <FaCheck
                                                    className={style.presentMarkerIconDisabled}
                                                    onClick={() => toggleAttendanceValue('present')}
                                                />
                                                Absent
                                                <IoClose className={style.onleaveMarkerIcon} />
                                            </div>
                                        ) : (
                                            <div className={style.studentPresent}>
                                                <FaCheck className={style.presentMarkerIcon} />
                                                Present
                                                <IoClose
                                                    className={style.onleaveMarkerIconDisabled}
                                                    onClick={() => toggleAttendanceValue('absent')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {!hasClassOnSelectedDate && (
                                        <div className={style.warningInfoContainer}>
                                            <div className={style.infoicon}>i</div>
                                            <div>
                                                you are marking attendance for the date which the
                                                student has no sessions!
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div>No date is selected</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
        );
    }, [
        formattedStudentAttendance,
        previousClassesOfStudent,
        activeClassesOfStudent,
        selectedClassForAttendance,
        isAttendanceEditModeOn,
        selectedCalendarDate,
        isPresentOnSelectedDate,
        currentClassEnrollmentDate,
        studentAttendanceArray
    ]);

    const navigate = useNavigate();

    const renderStudentEvalutionHistory = useMemo(() => {
        return (
            <div className="bg-white">
                {studentsEvaluationData?.data?.data?.map(data => (
                    <div
                        key={data.id}
                        onClick={() =>
                            navigate(
                                `${Routes.Students}/${studentDetailsData?.data?.data.id}/${data.id}`
                            )
                        }
                        className="w-full p-4 border-b-4 border-x-4 border-gray-200 rounded-b flex text-[13px] drop-shadow-lg shadow-gray-300 shadow-inner justify-between"
                    >
                        <p>{data.attributes.techniqueName}</p>
                        <p>
                            {`🕗 ${formatTimeToAMPM(data.attributes.updatedAt)}`}{' '}
                            {`📅 ${formatDate(data.attributes.updatedAt)}`}
                        </p>
                    </div>
                ))}
                {studentsEvaluationData &&
                    studentsEvaluationData?.data?.data &&
                    studentsEvaluationData?.data?.data.length < 1 && (
                        <div>No Results for this Students.</div>
                    )}
            </div>
        );
    }, [openTab, selectedFeeCard, isFeeHistoryDropdownOpen, studentsEvaluationData]);

    const renderStudentAssignments = useMemo(() => {
        return (
            <div className="bg-white p-4 flex flex-col gap-2">
                {assignmentSubmissions.map(submission => {
                    return (
                        <div key={submission?.id} className='p-2 rounded shadow-sm bg-slate-100'>
                            <div className='flex items-center justify-between mb-2'>
                                <div className='flex items-center gap-2'>
                                    <div className='text-xl'>{submission?.attributes?.assignment?.data?.attributes?.name}</div>
                                    {submission?.attributes?.badges?.data?.length > 0 && (
                                        submission?.attributes?.badges?.data?.map(badge => (
                                            <div key={badge?.id} className="relative  group">
                                                <img
                                                    src={badge?.attributes?.badgeImage?.data?.attributes?.url}
                                                    alt="badge"
                                                    className="h-10 w-10 transform transition-transform duration-200 ease-in-out group-hover:scale-150"
                                                />
                                                <img
                                                    src={badge?.attributes?.badgeImage?.data?.attributes?.url}
                                                    alt="badge"
                                                    className="absolute h-[300px] min-w-[300px] left-0 top-0  hidden group-hover:block drop-shadow-2xl"
                                                />
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className='text-sm text-slate-500'>Submitted on: {getFormattedDate(new Date(submission?.attributes?.createdAt))}</div>
                            </div>
                            <div className='flex justify-end'>
                                {submission?.attributes?.fileSubmission?.data && 
                                    <a 
                                        href={submission?.attributes?.fileSubmission?.data?.attributes?.url} 
                                        target='_blank' 
                                        className='text-sm px-4 py-2 bg-slate-300 text-gray-600 rounded'
                                        rel='noreferrer'
                                    >
                                        View Submission
                                    </a>
                                }
                            </div>
                            {submission?.attributes?.assignment?.data?.attributes?.submissionFileType === 'TEXT' && 
                                <div className='w-full rounded p-2 bg-slate-50'>
                                    <div className='text-sm text-gray-600'>{submission?.attributes?.textSubmission}</div>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }, [openTab]);

    const renderStudentFitness = useMemo(() => {
        return (
            <div className="bg-white p-4 flex flex-col gap-2">
                {studentFitnessTests.map(test => {
                    return (
                        <div key={test?.id}>
                            <div className='p-2 rounded shadow-sm bg-slate-100 flex justify-between items-center'>
                                <div>
                                    <div className='text-xl text-gray-600 font-semibold'>{test?.attributes?.testInfo?.data?.attributes?.template?.data?.attributes?.name}</div>
                                    <div className='text-sm text-gray-500'>{getFormattedDate(new Date(test?.attributes?.createdAt))}</div>
                                </div>
                                <div 
                                    className='cursor-pointer'
                                    onClick={() => handleExpandCollapseFitnessCard(test?.id)}
                                >
                                    <img
                                        src={expandedFitnessCard === test?.id ? arrowUp : arrowDown}
                                        className='h-8 w-8'
                                    />
                                </div>
                            </div>
                            {expandedFitnessCard === test?.id && (
                                <div className='w-full p-2 pt-0 flex items-center gap-8'>
                                    <div className='bg-slate-50 w-full'>
                                        {test?.attributes?.testResults?.map((result,index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='grid grid-cols-2 p-2'
                                                >
                                                    <div className='text-sm text-gray-600'>{result?.label}</div>
                                                    <div className='text-sm text-gray-600'>{result?.value}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {test?.attributes?.testInfo?.data?.attributes?.isPublished ? (
                                        <div className='text-sm px-4 py-2 bg-slate-300 text-gray-600 rounded text-center'>
                                            <a 
                                                href={test?.attributes?.report?.data?.attributes?.url} 
                                                target='_blank' 
                                                rel='noreferrer' 
                                                className='text-center'
                                            >
                                                View Report
                                            </a>
                                        </div>
                                    ) : (
                                        <div 
                                            className='text-sm px-4 py-2 bg-slate-300 text-gray-600 rounded text-center cursor-pointer'
                                            onClick={() => handleRecordFitnessTestModalOpen(test?.attributes?.testInfo?.data)}
                                        >
                                            Edit
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
                <RecordFitnessTest 
                    isOpen={isRecordFitnessTestModalOpen}
                    handleClose={handleRecordFitnessTestModalClose}
                    selectedConductableTestId={selectedRecordedFitnessTest?.id || 0}
                    student={studentDetailsData?.data?.data?.id || 0}
                />
            </div>
        );
    }, [openTab,studentFitnessTests,expandedFitnessCard,isRecordFitnessTestModalOpen,selectedRecordedFitnessTest]);

    const renderTabs = useMemo(() => {
        return (
            <>
                <div className={style.tabContainerWrapper}>
                    <div className={style.tabContainerMain}>
                        <ul
                            className={style.tabListContainer}
                            role="tablist"
                        >
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab == 1 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    PROFILE
                                </a>
                            </li>

                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab == 2 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    ATTENDANCE
                                </a>
                            </li>

                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab == 3 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    FEES HISTORY
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab === 4 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(4);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    ASSIGNMENTS
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab === 5 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(5);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    FITNESS
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${openTab == 6 ? 'bg-white' : 'bg-transparent'}`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(6);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    AI EVALUATION RESULTS
                                </a>
                            </li>
                        </ul>
                        <div className="bg-white">
                            <div
                                className={openTab === 1 ? 'block' : 'hidden'}
                                id="link1"
                            >
                                {renderStudentPrimaryDetails}
                            </div>
                            <div
                                className={openTab === 2 ? 'block' : 'hidden'}
                                id="link2"
                            >
                                {renderStudentAttendanceInfo}
                            </div>
                            <div
                                className={openTab === 3 ? 'block' : 'hidden'}
                                id="link3"
                            >
                                {renderStudentFeeHistory}
                            </div>
                            <div
                                className={openTab === 4 ? 'block' : 'hidden'}
                                id="link3"
                            >
                                {renderStudentAssignments}
                            </div>
                            <div
                                className={openTab === 5 ? 'block' : 'hidden'}
                                id="link3"
                            >
                                {renderStudentFitness}
                            </div>
                            <div
                                className={openTab === 6 ? 'block' : 'hidden'}
                                id="link3"
                            >
                                {renderStudentEvalutionHistory}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }, [
        openTab,
        studentDetailsData,
        isProfileDropdownOpen,
        isFeeHistoryDropdownOpen,
        selectedFeeCard,
        selectedClassForAttendance,
        formattedStudentAttendance,
        activeClassesOfStudent,
        isAttendanceEditModeOn,
        studentsFeeData,
        selectedCalendarDate,
        isPresentOnSelectedDate,
        currentClassEnrollmentDate,
        studentFitnessTests,
        expandedFitnessCard,
        isRecordFitnessTestModalOpen,
        selectedRecordedFitnessTest,
    ]);

    const studentSecondRowDetails = useMemo(() => {
        
        return (
            <div className={style.studentProfileAcademicDetailsContainer}>
                <div className={style.studentProfileAcademicDetailsLabel}>
                    ACADEMY
                    {studentDetailsData?.data?.data?.attributes?.academies?.data?.map(academy => {
                        if (ownersAcademies?.map(item => item.id).includes(academy?.id)) {
                            return (
                                <div key={academy.id}>
                                    <div className="relative group">
                                        <div
                                            className={`flex items-center justify-center  cursor-pointer text-[13px] 

                                                            ${academyExpand === academy.id ? 'bg-white' : ''}
                                                            leading-5 
                                                            tracking-[0px] 
                                                             text-blue-500`}
                                            onClick={() => handleAcademyClick(academy?.id)}
                                        >
                                            {academy?.attributes?.name}
                                        </div>

                                        {academyExpand === academy.id && (
                                            <div className="sm:max-w-[40vw] max-w-[60vw] w-max absolute top-0 left-[105%] bg-white border border-gray-300 p-2 rounded shadow-md">
                                                {academy.attributes.name}
                                                <br />
                                                Enrollment Date:{' '}
                                                {getFormattedDate(
                                                    studentDetailsData?.data?.data.attributes.studentAcademyHistory.find(
                                                        studentHistory =>
                                                            studentHistory.academy.data.id ===
                                                            academyExpand
                                                    )?.enrolmentDate ?? ''
                                                )}
                                                <br />
                                                {studentDetailsData?.data?.data.attributes.studentAcademyHistory.find(
                                                    studentHistory =>
                                                        studentHistory.academy.data.id ===
                                                        academyExpand
                                                )?.remarks && <> Remarks: </>}
                                                {
                                                    studentDetailsData?.data?.data.attributes.studentAcademyHistory.find(
                                                        studentHistory =>
                                                            studentHistory.academy.data.id ===
                                                            academyExpand
                                                    )?.remarks
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>

                <div className={style.studentProfileAcademicDetailsLabel}>
                    DISCIPLINE
                    <div className={style.studentProfileAcademicDetailsContent}>
                        {studentDetailsData?.data?.data?.attributes?.classes?.data &&
                            studentDetailsData.data.data.attributes.classes.data.map(c => {
                                return (
                                    <div key={c.id}>
                                        {c.attributes?.class_discipline?.data?.attributes?.name}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className={style.studentProfileAcademicDetailsLabel}>
                    SKILL LEVEL
                    <div className={style.studentProfileAcademicDetailsContent}>
                        {(studentDetailsData?.data?.data?.attributes?.classes?.data &&
                            studentDetailsData.data.data.attributes.classes.data[0]?.attributes
                                ?.skill_level) ??
                            ''}
                    </div>
                </div>

                {/* temporarily commented out for demo */}

                {/* <div
                    className={
                        style.studentProfileAcademicDetailsLabel
                    }>
                    COACH
                    <div
                        style={{color: '#2986CE'}}
                        className={
                            style.studentProfileAcademicDetailsContent
                        }>
                        Coach Surya
                    </div>
                </div>
                <div
                    className={
                        style.studentProfileAcademicDetailsLabel
                    }>
                    STUDENT ID
                    <div
                        className={
                            style.studentProfileAcademicDetailsContent
                        }>
                        SpArts-ACG-1
                    </div>
                </div> */}
            </div>
        );
    }, [studentDetailsData, ownersAcademies, academyExpand]);


    return (
        <NavMenu>
            {isDetailsFetchSuccess ? (
                <div className={style.studentProfileContainer}>
                    <div className={style.studentProfileDetailsWrapper}>
                        <div className={style.studentProfileDetailsContainer}>
                            <div className={style.studentProfileImageContainer}>
                                {studentDetailsData?.data?.data?.attributes?.photo?.data?.attributes
                                    ?.url ? (
                                    <img
                                        className={style.studentProfileImage}
                                        src={
                                            studentDetailsData?.data?.data?.attributes?.photo?.data
                                                ?.attributes?.url ?? ''
                                        }
                                        alt="profile_image"
                                    />
                                ) : (
                                    <div
                                        className={`${style.studentProfileImage} flex text-slate-500 items-center justify-center`}
                                    >
                                        No Image
                                    </div>
                                )}
                                {studentDetailsData?.data?.data?.attributes?.academies?.data &&
                                studentDetailsData?.data?.data?.attributes?.academies?.data
                                    ?.length <= 0 ? (
                                    <div className={style.studentProfileStatusInactive}>
                                        Inactive
                                    </div>
                                ) : (
                                    <div className={style.studentProfileStatusActive}>Active</div>
                                )}
                            </div>
                            <div className={style.studentProfileDetails}>
                                <div className={style.studentProfileNameContainer}>
                                    <div className={style.studentProfileName}>
                                        {studentDetailsData?.data?.data?.attributes?.firstName}{' '}
                                        {studentDetailsData?.data?.data?.attributes?.middleName}{' '}
                                        {studentDetailsData?.data?.data?.attributes?.lastName}
                                    </div>
                                    {userState?.userDetails?.attributes?.studentsAccess !==
                                        'restrictedView' && (
                                        <div className={style.dropStudentBtnContainer}>
                                            <button
                                                className={style.editStudentBtn}
                                                onClick={handleEditStudent}
                                            >
                                                Edit student
                                            </button>
                                            <button
                                                className={style.dropStudentBtn}
                                                onClick={handleStudentDropout}
                                            >
                                                Drop student
                                            </button>
                                            <div className='relative'>
                                                <div
                                                    className="cursor-pointer flex items-center"
                                                    onClick={handleMenu}
                                                >
                                                    <img
                                                        src={threeDotIcon}
                                                        className="h-8 w-8 p-1 transition-all hover:scale-110 active:scale-95"
                                                    />
                                                </div>
                                                {isMenuOpen && (
                                                    <div
                                                        ref={moreActionsDropDownRef}
                                                        className={
                                                            style.studentsOptionsDropdownContainer
                                                        }
                                                    >
                                                        <DropdownList
                                                            className="text-[15px] font-bold"
                                                            options={DROPDOWN_LISTS.filter(
                                                                item =>
                                                                    item.label != 'View Profile' &&
                                                                    item.label !== 'Dropout' &&
                                                                    item.label !== 'Edit Student' &&
                                                                    item.label !== 'Fees History'
                                                            )}
                                                            gotoFeeCollection={() =>
                                                                onCollectFeeClicked(
                                                                    studentDetailsData?.data
                                                                        ?.data as StudentData
                                                                )
                                                            }
                                                            openRecordLeaveFormModal={() =>
                                                                openRecordLeaveFormModal(
                                                                    studentDetailsData?.data
                                                                        ?.data as StudentData
                                                                )
                                                            }
                                                        ></DropdownList>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={style.studentProfilePersonalDetailsContainer}>
                                    <div className={style.studentProfilePersonalDetails}>
                                        <img
                                            className={style.studentProfileIcon}
                                            src={locationPinIcon}
                                            alt="icon"
                                        />
                                        {studentDetailsData?.data?.data?.attributes?.city ?? ''}
                                    </div>
                                    <div className={style.studentProfilePersonalDetails}>
                                        <img
                                            className={style.studentProfileIcon}
                                            src={inboxIcon}
                                            alt="icon"
                                        />
                                        {studentDetailsData?.data?.data?.attributes
                                            ?.parentEmailId ?? ''}
                                    </div>
                                    <div className={style.studentProfilePersonalDetails}>
                                        <img
                                            className={style.studentProfileIcon}
                                            src={phoneIcon}
                                            alt="icon"
                                        />
                                        {studentDetailsData?.data?.data?.attributes
                                            ?.parentContactNo ?? ''}
                                    </div>

                                    {/* temporarily commented out for demo */}

                                    {/* <div
                                            className={
                                                style.studentProfilePersonalDetails
                                            }>
                                            <img
                                                className={style.studentProfileIcon}
                                                src={userIdBadgeIcon}
                                                alt="icon"
                                            />
                                            ID Card
                                        </div> */}

                                    {/* temporarily commented out for demo */}
                                </div>

                                <div className={style.studentProfileSeperator}></div>

                                <div
                                    className={style.studentProfileAcademicDetailsContainerWrapper}
                                >
                                    <div className={style.visibleOnLargeScreen}>
                                        {studentSecondRowDetails}
                                    </div>

                                    {/* temporarily commented out for demo */}

                                    {/* <button className={style.studentProfileButton}>
                                        Actions{' '}
                                        <img
                                            className={
                                                style.studentProfileButtonArrowIcon
                                            }
                                            src={arrowDownIcon}
                                            alt="down"
                                        />
                                    </button> */}

                                    {/* temporarily commented out for demo */}
                                </div>
                            </div>
                        </div>
                        <div className={style.visibleOnSmallScreen}>{studentSecondRowDetails}</div>
                    </div>

                    {renderTabs}
                </div>
            ) : (
                <div className={style.loaderContainer}>
                    <Loader />
                </div>
            )}

            <AddStudent
                isOpen={isEditStudentModalOpen}
                handleClose={handleEditStudentModalClose}
                isEdit={true}
                studentData={studentDetailsData?.data?.data}
            />

            <PopupModal
                handleModalClose={handleDropStudentModalClose}
                isOpen={isDropStudentFormOpen}
                background="white"
                title="Drop Student"
            >
                <DropStudentForm
                    studentActiveAcademies={studentActiveAcademies}
                    handleStudentDropAction={handleStudentDropAction}
                    handleDropStudentAcademyCheck={handleDropStudentAcademyCheck}
                    checkedAcademiesToDropStudent={checkedAcademiesToDropStudent}
                />
            </PopupModal>

            <CollectFees
                isOpen={isFeeCollectionModalOpen}
                handleClose={handleFeeCollectionModalClose}
                activeStudentData={studentDetailsData?.data?.data as StudentData}
                isStudentChangable={false}
            />

            <PopupModal
                isOpen={isRecordLeaveModalOpen}
                handleModalClose={handleRecordLeaveModalClose}
            >
                <RecordLeaveForm
                    handleInputChange={handleRecordLeaveFormInputChange}
                    handleFormSubmit={handleRecordLeavesFormSubmit}
                    formValues={formValues}
                    studentData={recordLeaveModalStudentData}
                    isRecordPostSuccess={isRecordPostSuccess}
                />
            </PopupModal>
        </NavMenu>
    );
};

export default StudentDetails;
