import { useEffect, useState } from 'react';
import {
    ACADEMY_QUERY,
    FEE_STRUCTURES_LIST_DATA_QUERY,
    SCHEDULE_QUERY,
} from '../../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AcademiesService } from '../../../services/academies-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import ClassService from '../../../services/class-service';
import { ClassData } from '../../../models/class/class-data';
import { FeeStructurePostData } from '../../../models/fee-structure/fee-structure-post-data';
import { FeeStructureDataWrapper } from '../../../models/fee-structure/fee-structure-data-wrapper';
import { FeePaymentService } from '../../../services/fee-payment-service';
import { FeeStructureService } from '../../../services/fee-structure-service';
import { FeeStructureData } from '../../../models/fee-structure/fee-structure-data';

export interface FeeStructureFormDataType {
    academy: number | null;
    feeType: 'Training' | 'Uniform/Gear' | 'Event/Competition' | 'Registration' | null;
    classes: number[];
    remarks: string;

    trainingFee: TrainingFeeItemType[];
    apparelFee: ApparelFeeItemType[];
    qualificationFee: QualificationItemType[];
    registrationFee: number | null;
}

export interface TrainingFeeItemType {
    schedule: 'Monthly' | 'Quarterly' | 'SemiAnnually' | 'Annually' | null;
    sessions: number | null;
    amount: number | null;
}

export interface ApparelFeeItemType {
    type: 'Uniform' | 'Gear' | null;
    gearName: string;
    apparelDescription: string;
    apparelPrice: number | null;
}

export interface QualificationItemType {
    type: 'Certification' | 'Competition' | 'Exam' | 'Others' | null;
    info: string;
    amount: number | null;
}

const AddFeeStructureViewModel = ({
    handleClose,
    feeStructuresData,
}: {
    handleClose: () => void;
    feeStructuresData: FeeStructureData[];
}) => {
    const initialFeeStructureFormData = {
        academy: null,
        feeType: null,
        classes: [],
        registrationFee: null,
        trainingFee: [],
        apparelFee: [],
        qualificationFee: [],
        remarks: '',
    };
    const [feeStructureFormData, setFeeStructureFormData] = useState<FeeStructureFormDataType>(
        initialFeeStructureFormData
    );
    const userState = useSelector((state: RootState) => state.user);

    const handleFormInputChange = (
        field: string,
        value: string | number | number[] | undefined
    ) => {
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                [field]: value === '' ? null : value,
            };
        });
    };

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading: isClassFetching,
        data: classesData,
    } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        const existingFeesClassesForSelctedType = feeStructuresData
            ?.filter(
                item =>
                    item?.attributes?.type === feeStructureFormData?.feeType &&
                    item?.attributes?.academy?.data?.id === feeStructureFormData?.academy
            )
            ?.flatMap(item => item?.attributes?.classes?.data)
            .map(item => item?.id);
        if (classesData?.data?.data && feeStructureFormData.academy) {
            return classesData?.data?.data
                ?.filter(
                    (item: ClassData) =>
                        feeStructureFormData?.academy === item?.attributes?.academies?.data?.id
                )
                ?.filter(
                    (item: ClassData) => !existingFeesClassesForSelctedType?.includes(item?.id)
                )
                ?.map((item: ClassData) => ({
                    value: item.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const handleFeeStructureModalClose = () => {
        handleClose();
        setFeeStructureFormData(initialFeeStructureFormData);
        resetAll();
    };

    const initialTrainingFeeItemData = {
        schedule: null,
        sessions: null,
        amount: null,
    };

    const initialApparelFeeItemData = {
        type: null,
        gearName: '',
        apparelDescription: '',
        apparelPrice: null,
    };

    const initialQualificationFeeItemData = {
        type: null,
        info: '',
        amount: null,
    };

    const [currentTrainingFeeItemData, setCurrentTrainingFeeItemData] =
        useState<TrainingFeeItemType>(initialTrainingFeeItemData);
    const [currentApparelFeeItemData, setCurrentApparelFeeItemData] =
        useState<ApparelFeeItemType>(initialApparelFeeItemData);
    const [currentQualificationFeeItemData, setCurrentQualificationFeeItemData] =
        useState<QualificationItemType>(initialQualificationFeeItemData);
    const queryClient = useQueryClient();

    const handleTrainingFeeFormInputChange = (field: string, value: string | number | number[]) => {
        setCurrentTrainingFeeItemData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleApparelFeeFormInputChange = (field: string, value: string | number | number[]) => {
        setCurrentApparelFeeItemData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleQualificationFeeFormInputChange = (
        field: string,
        value: string | number | number[]
    ) => {
        setCurrentQualificationFeeItemData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleAddQualificationFeeItem = (e: React.FormEvent) => {
        e.preventDefault();
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                qualificationFee: [
                    ...prev.qualificationFee,
                    {
                        type: currentQualificationFeeItemData.type,
                        info: currentQualificationFeeItemData.info,
                        amount: currentQualificationFeeItemData.amount,
                    },
                ],
            };
        });
        setCurrentQualificationFeeItemData(initialQualificationFeeItemData);
    };

    const handleQualificationFeeItemDelete = (index: number) => {
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                qualificationFee: prev.qualificationFee.filter((item, i) => i !== index),
            };
        });
    };

    const handleAddApparelFeeItem = (e: React.FormEvent) => {
        e.preventDefault();
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                apparelFee: [
                    ...prev.apparelFee,
                    {
                        type: currentApparelFeeItemData.type,
                        gearName: currentApparelFeeItemData.gearName,
                        apparelDescription: currentApparelFeeItemData.apparelDescription,
                        apparelPrice: currentApparelFeeItemData.apparelPrice,
                    },
                ],
            };
        });
        setCurrentApparelFeeItemData(initialApparelFeeItemData);
    };

    const handleApparelFeeItemDelete = (index: number) => {
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                apparelFee: prev.apparelFee.filter((item, i) => i !== index),
            };
        });
    };

    const scheduleOrder: { [key: string]: number } = {
        Monthly: 1,
        Quarterly: 2,
        SemiAnnually: 3,
        Annually: 4,
    };

    const compareSchedule = (a: TrainingFeeItemType, b: TrainingFeeItemType): number => {
        if (a.schedule === null) return 1;
        if (b.schedule === null) return -1;
        return (scheduleOrder[a.schedule] || 0) - (scheduleOrder[b.schedule] || 0);
    };

    const handleAddTrainingFeeItem = (e: React.FormEvent) => {
        e.preventDefault();
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                trainingFee: [
                    ...prev.trainingFee,
                    {
                        schedule: currentTrainingFeeItemData.schedule,
                        sessions: currentTrainingFeeItemData.sessions,
                        amount: currentTrainingFeeItemData.amount,
                    },
                ].sort(compareSchedule),
            };
        });
        setCurrentTrainingFeeItemData(initialTrainingFeeItemData);
    };

    const handleTrainingFeeItemDelete = (index: number) => {
        setFeeStructureFormData(prev => {
            return {
                ...prev,
                trainingFee: prev.trainingFee.filter((item, i) => i !== index),
            };
        });
    };

    const postBulkAttendance = async (
        data: FeeStructurePostData
    ): Promise<FeeStructureDataWrapper | undefined> => {
        const response = await FeeStructureService.instance.postFeeStructure(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addFeeStructure,
        isLoading: isFeeStructurePosting,
        error: feeStructurePostError,
    } = useMutation(postBulkAttendance, {
        onSuccess: data => {
            alert('Fee structure added Successfully!');
            handleFeeStructureModalClose();
            queryClient.invalidateQueries(FEE_STRUCTURES_LIST_DATA_QUERY);
        },
        onError: (error, variables, context) => {
            alert('Failed to add fee structure! Please try again');
        },
    });

    const handleSubmitFeeStructureForm = () => {
        let body: FeeStructurePostData;

        switch (feeStructureFormData.feeType) {
            case 'Training':
                if (feeStructureFormData.classes.length === 0) {
                    alert('Please select at least one class');
                    return;
                } else {
                    body = {
                        academy: feeStructureFormData.academy ?? 0,
                        type: feeStructureFormData.feeType,
                        classes: feeStructureFormData.classes,
                        trainingFees: feeStructureFormData.trainingFee.map(item => {
                            return {
                                frequency: item.schedule,
                                sessionsPerWeek: item.sessions,
                                amount: item.amount,
                            };
                        }),
                        remarks: feeStructureFormData.remarks,
                        users: [userState?.user?.id ?? 0],
                    };
                }
                break;
            case 'Uniform/Gear':
                body = {
                    academy: feeStructureFormData.academy ?? 0,
                    type: feeStructureFormData.feeType,
                    classes: feeStructureFormData.classes,
                    apparelFees: feeStructureFormData.apparelFee.map(item => {
                        return {
                            apparelType: item.type,
                            name: item.gearName,
                            sizeOrDescription: item.apparelDescription,
                            amount: item.apparelPrice ?? 0,
                        };
                    }),
                    remarks: feeStructureFormData.remarks,
                    users: [userState?.user?.id ?? 0],
                };
                break;
            case 'Event/Competition':
                body = {
                    academy: feeStructureFormData.academy ?? 0,
                    type: feeStructureFormData.feeType,
                    classes: feeStructureFormData.classes,
                    qualificationFees: feeStructureFormData.qualificationFee.map(item => {
                        return {
                            type: item.type,
                            info: item.info,
                            amount: item.amount ?? 0,
                        };
                    }),
                    remarks: feeStructureFormData.remarks,
                    users: [userState?.user?.id ?? 0],
                };
                break;
            case 'Registration':
                if (feeStructureFormData.classes.length === 0) {
                    alert('Please select at least one class');
                    return;
                } else {
                    body = {
                        academy: feeStructureFormData.academy ?? 0,
                        type: feeStructureFormData.feeType,
                        classes: feeStructureFormData.classes,
                        registrationFees: {
                            amount: feeStructureFormData.registrationFee ?? 0,
                        },
                        remarks: feeStructureFormData.remarks,
                        users: [userState?.user?.id ?? 0],
                    };
                }
                break;
            default:
                return;
        }

        addFeeStructure(body);
    };

    const [trainingFeeAvailable, setTrainingFeeAvailable] = useState(true);
    const [registrationFeesAvailable, setRegistrationFeesAvailable] = useState(true);
    const [apparelFeesAvailable, setApparelFeesAvailable] = useState(true);
    const [qualificationFeesAvailable, setQualificationFeesAvailable] = useState(true);

    useEffect(() => {
        resetAll();
        if (
            feeStructuresData?.find(
                item =>
                    item?.attributes?.type === 'Event/Competition' &&
                    item?.attributes?.academy?.data?.id === feeStructureFormData?.academy
            )
        ) {
            setQualificationFeesAvailable(false);
        }

        if (
            feeStructuresData?.find(
                item =>
                    item?.attributes?.type === 'Uniform/Gear' &&
                    item?.attributes?.academy?.data?.id === feeStructureFormData?.academy
            )
        ) {
            setApparelFeesAvailable(false);
        }

        const existingTrainingFeesClasses = feeStructuresData
            ?.filter(
                item =>
                    item?.attributes?.type === 'Training' &&
                    item?.attributes?.academy?.data?.id === feeStructureFormData?.academy
            )
            ?.flatMap(item => item?.attributes?.classes?.data);
        const allClassesOfSelectedAcademy = classesData?.data?.data.filter(
            item => item?.attributes?.academies?.data?.id === feeStructureFormData?.academy
        );
        if (
            existingTrainingFeesClasses?.length === allClassesOfSelectedAcademy?.length &&
            feeStructureFormData?.academy !== null
        ) {
            setTrainingFeeAvailable(false);
        }

        const existingRegistrationFeesClasses = feeStructuresData
            ?.filter(
                item =>
                    item?.attributes?.type === 'Registration' &&
                    item?.attributes?.academy?.data?.id === feeStructureFormData?.academy
            )
            ?.flatMap(item => item?.attributes?.classes?.data);
        if (
            existingRegistrationFeesClasses?.length === allClassesOfSelectedAcademy?.length &&
            feeStructureFormData?.academy !== null
        ) {
            setRegistrationFeesAvailable(false);
        }
    }, [feeStructureFormData?.academy, feeStructuresData]);

    const resetAll = () => {
        setTrainingFeeAvailable(true);
        setRegistrationFeesAvailable(true);
        setApparelFeesAvailable(true);
        setQualificationFeesAvailable(true);
    };

    const scheduleOptions = ['Monthly', 'Quarterly', 'SemiAnnually', 'Annually'];
    const sessionOptions = ['1', '2', '3', '4', '5', '6', '7'];

    const [availableScheduleOptions, setAvailableScheduleOptions] = useState(scheduleOptions);
    const [availableSessionOptions, setAvailableSessionOptions] = useState(sessionOptions);

    useEffect(() => {
        setAvailableScheduleOptions(
            scheduleOptions.filter(
                schedule =>
                    feeStructureFormData.trainingFee.filter(item => item.schedule === schedule)
                        .length < 7
            )
        );

        setAvailableSessionOptions(
            sessionOptions.filter(
                sessions =>
                    !feeStructureFormData.trainingFee.some(
                        item =>
                            item.schedule === currentTrainingFeeItemData.schedule &&
                            item.sessions === Number(sessions)
                    )
            )
        );
    }, [feeStructureFormData.trainingFee, currentTrainingFeeItemData.schedule]);

    return {
        isLoading: isFeeStructurePosting,
        feeStructureFormData,
        handleFormInputChange,
        mapClassesToOptions,
        allAcademies: academiesData?.data?.data ?? [],
        handleFeeStructureModalClose,
        handleAddTrainingFeeItem,
        currentTrainingFeeItemData,
        handleTrainingFeeFormInputChange,
        handleTrainingFeeItemDelete,
        handleSubmitFeeStructureForm,
        handleApparelFeeFormInputChange,
        handleAddApparelFeeItem,
        handleApparelFeeItemDelete,
        currentApparelFeeItemData,
        currentQualificationFeeItemData,
        handleQualificationFeeFormInputChange,
        handleAddQualificationFeeItem,
        handleQualificationFeeItemDelete,
        trainingFeeAvailable,
        registrationFeesAvailable,
        apparelFeesAvailable,
        qualificationFeesAvailable,
        availableScheduleOptions,
        availableSessionOptions,
    };
};

export default AddFeeStructureViewModel;
