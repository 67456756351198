import React, { useEffect } from 'react';
import CollectFeesViewModel from '../../view-models/actions-view-models/collect-fees-view-model';
import PopupModal from '../../components/widgets/popup-modal';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import FeecollectionForm from '../../components/widgets/fee-collection-form';
import { StudentData } from '../../../models/student/student-data';

interface CollectFeesProps {
    isOpen: boolean;
    handleClose: () => void;
    activeStudentData?: StudentData;
    isStudentChangable: boolean;
}
const CollectFees = ({
    isOpen,
    handleClose,
    activeStudentData,
    isStudentChangable,
}: CollectFeesProps) => {
    const {
        isLoading,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        searchTerm,
        handlesearchInputChange,
        onStudentSearchListItemClick,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        selectedStudentData,
        handleClassInputChange,
        handleStudentFeeSearch,
        studentsClassFeeData,
        feePaymentFormData,
        handleFeeFormInputChange,
        allClasses,
        handleFeeCollectSubmit,
        confirmedStudentClassId,
        handleStudentFeeReset,
        selectedStudentClassId,
        inactiveFeeData,
        expandInactive,
        handleExpandInactive,
        setSelectedStudentData,
        handleFeeCollectionModalClose,
    } = CollectFeesViewModel({ handleClose, isStudentChangable });

    useEffect(() => {
        if (!isStudentChangable) {
            setSelectedStudentData(activeStudentData);
        }
    }, [activeStudentData, isStudentChangable]);

    return (
        <PopupModal
            isOpen={isOpen}
            handleModalClose={handleFeeCollectionModalClose}
            background="white"
            title="Collect Fees"
        >
            {isLoading && <FullScreenLoader />}
            <FeecollectionForm
                isStudentDataFetchLoading={isStudentDataFetchLoading}
                studentSuggestionListData={studentSuggestionListData}
                searchTerm={searchTerm}
                handlesearchInputChange={handlesearchInputChange}
                onStudentSearchListItemClick={onStudentSearchListItemClick}
                isSearchInputOnFocus={isSearchInputOnFocus}
                setIsSearchInputOnFocus={setIsSearchInputOnFocus}
                selectedStudentData={selectedStudentData}
                isStudentChangable={isStudentChangable}
                handleClassInputChange={handleClassInputChange}
                handleStudentFeeSearch={handleStudentFeeSearch}
                studentsClassFeeData={studentsClassFeeData}
                feePaymentFormData={feePaymentFormData}
                handleFeeFormInputChange={handleFeeFormInputChange}
                allClasses={allClasses}
                handleFeeCollectSubmit={handleFeeCollectSubmit}
                confirmedStudentClassId={confirmedStudentClassId}
                handleStudentFeeReset={handleStudentFeeReset}
                selectedStudentClassId={selectedStudentClassId}
                inactiveFeeData={inactiveFeeData}
                expandInactive={expandInactive}
                handleExpandInactive={handleExpandInactive}
            />
        </PopupModal>
    );
};

export default CollectFees;
