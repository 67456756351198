import React from 'react';
import company from '../../pages/landing-page/data/company.json';
// import Section1 from './Section1'

const Companies = () => {
    return (
        <div
            className="flex flex-col items-center"
            id="Customer"
        >
            <div className="text-center italic p-10">
                Serving 450+ students enrolled in Sports and Creative Arts Academies
            </div>
            <div className="w-[100%] flex flex-col items-center">
                <div className="bg-white my-6 overflow-hidden relative w-[90vw] slider">
                    <div className={`flex w-[7500px] animate-scroll`}>
                        {[...Array(10)].map((_, repeatIndex) => (
                            <React.Fragment key={repeatIndex}>
                                {company.map((logo, index) => (
                                    <div
                                        key={index}
                                        className="h-[100%] w-[100%] p-4 flex items-center justify-center"
                                    >
                                        <img
                                            src={logo.src}
                                            alt={`Logo ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companies;
