import React from 'react';
import style from './style.module.css';

interface DropdownListOption {
    label: string;
    action: string;
}

interface DropdownListProps {
    options: DropdownListOption[];
    goToStudentDetails: () => void;
    goToFeeHistory: () => void;
    gotoFeeCollection: () => void;
    openRecordLeaveFormModal: () => void;
    openStudentDropoutConfirmation: () => void;
    openStudentEditForm: () => void;
}

const DropdownList = ({
    options,
    goToStudentDetails,
    goToFeeHistory,
    gotoFeeCollection,
    openRecordLeaveFormModal,
    openStudentDropoutConfirmation,
    openStudentEditForm,
}: DropdownListProps) => {
    return (
        <div>
            <ul className={style.dropdownListContainer}>
                {options.map((option, index) => (
                    <li
                        key={index}
                        className={style.dropdownListItem}
                        onClick={() => {
                            switch (option.action) {
                                case 'GO_TO_DETAIL':
                                    goToStudentDetails();
                                    break;
                                case 'GO_TO_FEE_HISTORY':
                                    goToFeeHistory();
                                    break;
                                case 'GO_TO_COLLECT_FEE':
                                    gotoFeeCollection();
                                    break;
                                case 'OPEN_RECORD_LEAVE_FORM':
                                    openRecordLeaveFormModal();
                                    break;
                                case 'OPEN_STUDENT_DROPOUT_PROMPT':
                                    openStudentDropoutConfirmation();
                                    break;
                                case 'OPEN_STUDENT_EDIT_FORM':
                                    openStudentEditForm();
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DropdownList;
