import React, { useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { StudentService } from '../../services/student-service';
import {
    ACADEMY_QUERY,
    CLASS_DETAILS_QUERY,
    FEE_PAYMENT_QUERY,
    SCHEDULE_DETAILS_QUERY,
    SCHEDULE_QUERY,
    STUDENTS_FEE_RECORDS_QUERY,
    STUDENT_DETAILS_QUERY,
    STUDENTS_EVALUATION_RECORDS_QUERY,
    ASSIGNMENT_SUBMISSIONS_OF_STUDENT,
    STUDENTS_FITNESS_TESTS_LIST,
} from '../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
// import {useReducer} from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import StudentsViewModel from './students-view-model';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { FeePaymentState, feePaymentReducer } from '../reducers/fee-payment-reducer';
import { useImmerReducer } from 'use-immer';
import { FeePaymentService } from '../../services/fee-payment-service';
import { AttendanceAttributesWraper } from '../../models/attendance/attendance-attributes-wrapper';
import ClassService from '../../services/class-service';
import { ClassData } from '../../models/class/class-data';
import AttendanceRequest from '../../utils/types/attendance-request';
import { AttendanceListWrapper } from '../../models/attendance/attendance-list-wrapper';
import ScheduleService from '../../services/schedule-service';
import { FeePaymentPostData } from '../../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../../models/fee/fee-payment-post-data-wrapper';
import { SpartsWatermark } from '../../assets/graphics/sparts-watermark';
import { SpartsLogo } from '../../assets/graphics/sparts-logo';

// import { AcademiesState, academiesReducer } from '../reducers/academies-reducer';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDateToInputDate, getFormattedDate } from '../../utils/helpers/helpers';
import { AcademiesService } from '../../services/academies-service';
import { AiEvaluationService } from '../../services/ai-evaluation-service';
import { AttendanceDataWraper } from '../../models/attendance/attendance-data-wrapper';
import { ProgressTrackerService } from '../../services/progress-tracker-service';
import { FitnessTestConductedData } from '../../models/progress-tracker/fitness-test-conducted-data';
import { StudentData } from '../../models/student/student-data';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const StudentDetailsViewModel = () => {
    const initialState: FeePaymentState = {
        openTab: 1,
        academies: [],
        disciplines: [],
        pendingFee: [],
        receivedFee: [],
        queryString: '',
        currentPage: 1,
    };

    const [state, dispatch] = useImmerReducer(feePaymentReducer, initialState);

    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();
    const location = useLocation();

    const navigate = useNavigate();
    const { id } = useParams();

    const { studentId, techniqueId } = useParams();

    const [openTab, setOpenTab] = useState(1);

    const moreActionsDropDownRef = useRef<HTMLDivElement>(null);
    
    const {
        openStudentDropoutConfirmation,
        mapAcademiesToOptions,
        mapClassesToOptions,
        handleDropStudentModalClose,
        isDropStudentFormOpen,
        studentActiveAcademies,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        handleProfileImgChange,
        isProfileImagePosting,
        profileImageInputKey,
        goToFeeHistory,
        onCollectFeeClicked,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
    } = StudentsViewModel();
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false);
    const [isFeeHistoryDropdownOpen, setIsFeeHistoryDropdownOpen] = useState<boolean>(false);
    const [academyExpand, setAcademyExpand] = useState(-1);
    const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isRecordLeaveModalOpen, setIsRecordLeaveModalOpen] = useState(false);
    const [recordLeaveModalStudentData, setRecordLeaveModalStudentData] = useState<StudentData>();
    const [isRecordPostSuccess, setIsRecordPostSuccess] = useState(false);
    const [formValues, setFormValues] = useState({
        leaveFrom: '',
        leaveTo: '',
        message: '',
    });

    const handleAcademyClick = (id: number) => {
        if (academyExpand === id) {
            setAcademyExpand(-1);
        } else {
            setAcademyExpand(id);
        }
    };

    const openRecordLeaveFormModal = (student: StudentData) => {
        setIsRecordLeaveModalOpen(true);
        setRecordLeaveModalStudentData(student);
        setFormValues({
            leaveFrom: '',
            leaveTo: '',
            message: '',
        });
    };

    const handleRecordLeaveFormInputChange = (fieldName: string, value: string) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const postLeaveRecord = async (
        data: AttendanceRequest
    ): Promise<AttendanceListWrapper | undefined> => {
        const response = await StudentService.instance.postLeaveRecord(data);
        if (response.success) {
            return response.data;
        } else {
            console.log(
                `On Post Attendacnce Error ${JSON.stringify(response.error)}`,
            );
            throw new Error(response.error?.message);
        }
    };
    
    const generateAttendanceRecords = () => {
        const startDate = new Date(formValues.leaveFrom);
        const endDate = new Date(formValues.leaveTo);
        const attendanceRecords: AttendanceRequest[] = [];

        // Iterate over each class in the classes array
        recordLeaveModalStudentData?.attributes?.classes?.data.forEach(classData => {
            // Iterate over dates from leaveFrom to leaveTo
            for (
                let date = new Date(startDate);
                date <= endDate;
                date.setDate(date.getDate() + 1)
            ) {
                const formattedDate = date.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
                const attendanceRecord = {
                    present: false,
                    on_leave: true,
                    note: formValues.message,
                    attendance_date: formattedDate,
                    class: classData?.id || 0,
                    student: recordLeaveModalStudentData?.id || 0,
                    time: new Date().toLocaleTimeString(),
                    markedBy: userState?.user?.id ?? 0,
                    systemMarked: false,
                };
                attendanceRecords.push(attendanceRecord);
            }
        });

        return attendanceRecords;
    };

    const handleRecordLeavesFormSubmit = () => {
        const attendanceRecords = generateAttendanceRecords();
        attendanceRecords?.map(data => {
            if (data.class !== undefined && data.student !== undefined) {
                addLeaveRecord(data);
            }
        });
    };

    const {
        mutate: addLeaveRecord,
        isLoading: isLeaveRecordPosting,
        // error: isLeaveRecordPostError,
        isSuccess: isLeaveRecordPosted,
    } = useMutation(postLeaveRecord, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            setIsRecordPostSuccess(true);
        },
        onError: error => {
            // console.log(`On Attendance Error:`, error);
            // Error actions
        },
    });

    const handleRecordLeaveModalClose = () => {
        setIsRecordLeaveModalOpen(false);
        setIsRecordPostSuccess(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                moreActionsDropDownRef.current &&
                !moreActionsDropDownRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [moreActionsDropDownRef]);

    useEffect(() => {
        if (location.state) {
            setOpenTab(location.state);
        } else {
            setOpenTab(1);
        }
    }, [location.state]);

    const handleMenu = () => {
        setIsMenuOpen(prev => !prev);
    }

    const {
        isSuccess: isDetailsFetchSuccess,
        isLoading: isDetailsLoading,
        data: studentDetailsData,
    } = useQuery({
        queryKey: [STUDENT_DETAILS_QUERY, id],
        queryFn: StudentService.instance.getStudentDetails.bind(this, Number(id)),
        refetchOnWindowFocus: false,
    });

    const { isSuccess: studentsFeeDataFetchSuccess, data: studentsFeeData } = useQuery({
        queryKey: [STUDENTS_FEE_RECORDS_QUERY, id],
        queryFn: FeePaymentService.instance.getStudentsFeeRecords.bind(
            this,
            userState?.user?.id ?? 0,
            parseInt(id ?? '')
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: studentsAIEvaluationDataFetchSuccess, data: studentsEvaluationData } =
        useQuery({
            queryKey: [STUDENTS_EVALUATION_RECORDS_QUERY, id],
            queryFn: AiEvaluationService.instance.getStudentsAiEvaluationRecords.bind(
                this,
                parseInt(id ? id : (studentId ?? ''))
            ),
            refetchOnWindowFocus: false,
            enabled: (userState?.user?.id ?? 0) > 0,
        });

    const { isSuccess: isOwnersAcademiesFetchSuccess, data: ownersAcademies } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: StudentService.instance.getOwnersAcademies.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isLoading: iStudentFitnessTestsFetching, data: studentFitnessTests } = useQuery({
        queryKey: [STUDENTS_FITNESS_TESTS_LIST, id],
        queryFn: () => ProgressTrackerService.instance.getStudentsFitnessRecords(Number(id) ?? 0, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { data: allClassesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isStudentAttendanceFetchSuccess, data: studentAttendanceData } = useQuery({
        queryKey: [SCHEDULE_DETAILS_QUERY, id],
        queryFn: StudentService.instance.getStudentAttendance.bind(this, Number(id) ?? 0),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const {
        isSuccess: isAssignmentSubmissionsFetchSuccess,
        isLoading: isAssignmentSubmissionsFetching,
        data: assignmentSubmissionsList,
      } = useQuery({
        queryKey: [ASSIGNMENT_SUBMISSIONS_OF_STUDENT, id],
        queryFn: () => ProgressTrackerService.instance.getAssignmentSubmissionsOfStudent(Number(id) ?? 0),
        refetchOnWindowFocus: true,
        enabled: true,
      });

    const handleEditStudentModalClose = () => {
        setIsEditStudentModalOpen(false);
    };

    const goToAcademyDetail = useCallback(
        (academyId: number) => {
            const dataToSend = {
                studentAcademyId: academyId,
            };
            navigate(Routes.Academies, { state: dataToSend });
        },
        [navigate]
    );

    const handleDropdownToggle = (tabName: string) => {
        switch (tabName) {
            case 'PROFILE':
                setIsProfileDropdownOpen(prev => !prev);
                break;
            case 'FEEHISTORY':
                setIsFeeHistoryDropdownOpen(prev => !prev);
                break;
        }
    };

    const [selectedFeeCard, setSelectedFeeCard] = useState<number[]>([]);

    const handleFeeListCardClick = (id: number) => {
        setSelectedFeeCard(prev => {
            if (prev.includes(id)) {
                // Remove the id if it's already present
                return prev.filter(cardId => cardId !== id);
            } else {
                // Add the id if it's not present
                return [...prev, id];
            }
        });
    };

    const handleStudentDropout = () => {
        openStudentDropoutConfirmation(studentDetailsData?.data?.data);
    };

    const handleEditStudent = () => {
        setIsEditStudentModalOpen(true);
    };

    const [attendanceData, setAttendanceData] = useState<AttendanceAttributesWraper[]>();

    useEffect(() => {
        setAttendanceData(studentAttendanceData?.data?.data);
        setPeviousClassesOfStudent(
            studentAttendanceData?.data?.data
                .map(item => item?.attributes?.class?.data)
                .filter(obj => obj) // This removes null or falsy values
                .filter((obj, index, self) => index === self?.findIndex(o => o?.id === obj?.id))
        );
    }, [studentAttendanceData]);

    const [activeClassesOfStudent, setActiveClassesOfStudent] = useState<ClassData[]>();
    const [previousClassesOfStudent, setPeviousClassesOfStudent] = useState<ClassData[]>();
    const [selectedClassForAttendance, setSelectedClassForAttendance] = useState<number>(0);

    const handleClassChangeInAttendance = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedClassForAttendance(parseInt(e.target.value));
    };


    const transformAttendanceData = (): Record<string, string> => {

            const allClassesSet = new Set([
            ...activeClassesOfStudent?.map(c => c?.id) ?? [],
            ...previousClassesOfStudent?.map(c => c?.id) ?? []
            ]);
            const selectedAttendance = attendanceData?.filter(a =>
            allClassesSet.has(a?.attributes?.class?.data?.id)
            );
            //remove duplicates
        const modifiedAttendance = selectedAttendance?.reduce((unique: { classId: number; attendanceDate: string; isPresent: boolean; onLeave: boolean }[], current) => {
            const classId = current?.attributes?.class?.data?.id;
            const attendanceDate = new Date(current.attributes.attendance_date).toISOString().split('T')[0];
                const isPresent = current.attributes.present;
                const onLeave = current.attributes.on_leave;

            // Check if the current entry is already in the unique array with the same classId and date, and if marked as present
            const alreadyExists = unique.some(
            item => 
                item.classId === classId &&
                item.attendanceDate === attendanceDate
            );

            // If it doesn't exist, add it to the unique array
            if (!alreadyExists) {
            unique.push({ classId, attendanceDate, isPresent,onLeave });
            }
            return unique;
            }, []);
        
        
            if (selectedClassForAttendance === 0) {
            return modifiedAttendance?.reverse().reduce((result: Record<any, any>, item) => {
                const formattedDate = new Date(item?.attendanceDate).toISOString().split('T')[0];
                const classId = item?.classId;
                const isPresent = item?.isPresent ? 1 : 0;

            // Track classes counted on each date to avoid duplicates
            if (!result[formattedDate]) {
                result[formattedDate] = isPresent;
            }

            else {
                result[formattedDate] += isPresent;
            }
        return result; // Return the updated result without spreading
    }, {}) || {};
}
        else {
            const data = attendanceData?.filter(item => item?.attributes?.class?.data?.id === selectedClassForAttendance) || [];
            
            return data?.reverse().reduce((result, item) => {
            const formattedDate = new Date(item?.attributes?.attendance_date).toISOString().split('T')[0];
            const status = item?.attributes.present
                ? 'present'
                : item?.attributes?.on_leave
                  ? 'on_leave'
                        : 'absent';
            return {
                ...result,
                [formattedDate]: status,
            };
            }, {});
        }
    };

    const [isAttendanceEditModeOn, setIsAttendanceEditModeOn] = useState<boolean>(false);
    const [formattedStudentAttendance, setFormattedStudentAttendance] = useState<
        Record<string, string >
    >({});
    const [formattedStudentAttendanceForEdit, setFormattedStudentAttendanceForEdit] = useState<Record<string,string>>({});
    const [studentAttendanceArray, setStudentAttendanceArray] = useState<{ month: string; present: string | number }[]>([]);
    useEffect(() => {
        setFormattedStudentAttendance(transformAttendanceData());
        setFormattedStudentAttendanceForEdit(transformAttendanceData());
            }, [
        attendanceData,
        selectedClassForAttendance,
        activeClassesOfStudent,
        isAttendanceEditModeOn,
    ]);

    useEffect(() => {
        setActiveClassesOfStudent(
            allClassesData?.data?.data.filter(item =>
                studentDetailsData?.data?.data.attributes.classes.data
                    .map(item => item?.id)
                    .includes(item?.id)
            )
        );
    }, [allClassesData, studentDetailsData]);

    useEffect(() => {
        if (activeClassesOfStudent && activeClassesOfStudent.length > 0) {
            setSelectedClassForAttendance(activeClassesOfStudent[0]?.id);
        }
    }, [activeClassesOfStudent]);

    // useEffect(() => {
    
    useEffect(() => {
        formatDate();
    },[formattedStudentAttendance])


    // Function to format the date to "Month-Year" and group data
    function formatDate() {
        const currentDate = new Date();
        const twelveMonthsAgo = new Date();
        twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);

        const generateLast12Months = (): string[] => {
            const months = [];
            const current = new Date();
            for (let i = 0; i < 12; i++) {
                months.push(current.toLocaleString('default', { month: 'short', year: 'numeric' }));
                current.setMonth(current.getMonth() - 1);
            }
            return months.reverse(); // Reverse to get ascending order (oldest to newest)
        };
        
        const studentAttendance = Object.entries(formattedStudentAttendance).reduce(
            (acc: Record<string, { month: string; present: number }>, [date, status]: [string, string]) => {
                const dateObj = new Date(date);

                if (dateObj >= twelveMonthsAgo) {
                    const monthYear: string = dateObj.toLocaleString('default', { month: 'short', year: 'numeric' });

                    if (!acc[monthYear]) {
                        acc[monthYear] = { month: monthYear, present: 0 };
                    }
                    const numericStatus = isNaN(Number(status)) ? 0 : Number(status);

                    if (status === 'present' || numericStatus > 0) {
                        acc[monthYear].present += numericStatus > 0 ? numericStatus : 1;
                    }
                }

                return acc;
            },
            {} as Record<string, { month: string; present: number }>
        );

        // Generate the last 12 months and merge with attendance data
        const last12Months = generateLast12Months();
        const attendanceArrayForClass = last12Months.map((month) => {
            // If the month exists in the attendance data, use it; otherwise, default to 0 "present" days
            return studentAttendance[month] || { month, present: 0 };
        });
        setStudentAttendanceArray(attendanceArrayForClass);
    }

    const currentClassTimings =
        activeClassesOfStudent?.filter(item => item?.id === selectedClassForAttendance)[0]
            ?.attributes?.class_timings ??
        (activeClassesOfStudent && activeClassesOfStudent[0]?.attributes?.class_timings);

    const getDayClassName = ({ date, view }: { date: Date; view: string }) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const isCurrentDay = date.toDateString() === new Date().toDateString();

        const status = formattedStudentAttendance[formattedDate];
        if (view === 'month' && isCurrentDay && status === undefined) {
            return '!text-black sm:!py-6 !bg-white !shadow-inner !shadow-gray-600 !relative';
        } else {
            switch (status) {
                case 'present':
                    return `!text-black !bg-green-300 sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                case 'absent':
                    return `!text-black !bg-red-100 sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                default:
                    return `!text-black sm:!py-6  !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
            }
        }
    };

    const getDayClassNameForEdit = ({ date, view }: { date: Date; view: string }) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const isCurrentDay = date.toDateString() === new Date().toDateString();

        const status = formattedStudentAttendanceForEdit[formattedDate];
        // console.log(formattedStudentAttendanceForEdit)

        if (view === 'month' && isCurrentDay && status === undefined) {
            return '!text-black sm:!py-6 !bg-white !shadow-inner !shadow-gray-600 !relative';
        } else {
            switch (status) {
                case 'present':
                    return `!text-black !bg-green-300 sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                case 'absent':
                    return `!text-black !bg-red-100 sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                default:
                    return `!text-black sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
            }
        }
    };

    const getDayName = (date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    };

    const tileContent = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            const status = formattedStudentAttendance[formattedDate];

            const isInClassTiming = currentClassTimings?.some(
                timing => timing.day === getDayName(date)
            );

            if (isInClassTiming && status === 'on_leave') {
                return (
                    <>
                        <span className="p-1 rounded m-1 bg-red-100 text-white text-[8px] sm:text-xs">
                            L
                        </span>
                        <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2" />
                    </>
                );
            } else if (isInClassTiming && status !== 'on_leave') {
                return (
                    <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2" />
                );
            }
            // } else if (status === 'on_leave' && !isInClassTiming) {
            //     return (
            //             <span className='p-1 rounded m-1 bg-red-100 text-white'>L</span>
            //     );
            // }
        }
        return null;
    };

    const tileContentForEdit = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            const status = formattedStudentAttendanceForEdit[formattedDate];

            const isInClassTiming = currentClassTimings?.some(
                timing => timing.day === getDayName(date)
            );

            if (isInClassTiming && status === 'on_leave') {
                return (
                    <>
                        <span className="p-1 rounded m-1 bg-red-100 text-white text-[8px] sm:text-xs">
                            L
                        </span>
                        <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2" />
                    </>
                );
            } else if (isInClassTiming && status !== 'on_leave') {
                return (
                    <div className="rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2" />
                );
            }
            // } else if (status === 'on_leave' && !isInClassTiming) {
            //     return (
            //             <span className='p-1 rounded m-1 bg-red-100 text-white'>L</span>
            //     );
            // }
        }
        return null;
    };

    const handleEditAttendanceClick = () => {
        setIsAttendanceEditModeOn(true);
    };

    const handleCancelEditingClick = () => {
        const userConfirmed = window.confirm(
            'Are you sure you want to cancel editing? all the changes made to attendance for this particular class will be lost!'
        );

        if (userConfirmed) {
            setIsAttendanceEditModeOn(false);
            setFormattedStudentAttendanceForEdit({});
            setSelectedCalendarDate(undefined);
        }
    };

    const [selectedCalendarDate, setSelectedCalendarDate] = useState<Date>();
    const [isPresentOnSelectedDate, setIsPresentOnSelectedDate] = useState<boolean | null>(null);
    const [isOnLeaveOnSelectedDate, setIsOnLeaveOnSelectedDate] = useState<boolean>();
    const [hasClassOnSelectedDate, setHasClassOnSelectedDate] = useState<boolean>();
    const [currentClassEnrollmentDate, setCurrentClassEnrollmentDate] = useState<Date>();

    useEffect(() => {
        setCurrentClassEnrollmentDate(
            new Date(
                new Date(
                    studentDetailsData?.data?.data?.attributes?.studentAcademyHistory
                        ?.filter(
                            h =>
                                h?.academy?.data?.id ===
                                    activeClassesOfStudent?.find(
                                        item => item?.id === selectedClassForAttendance
                                    )?.attributes?.academies?.data?.id && h?.status === 'Active'
                        )
                        ?.sort((a, b) => b?.id - a?.id)[0]?.enrolmentDate ?? ''
                ).setHours(0, 0, 0, 0)
            )
        );
    }, [selectedClassForAttendance]);

    const handleDayClickOnEditMode = (value: Date) => {
        if (value > new Date()) {
            alert('Not allowed to edit attendance for future dates!');
            return;
        } else if (currentClassEnrollmentDate && value < currentClassEnrollmentDate) {
            alert('Cannot edit attendance for students who joined after the selected date!');
            return;
        } else {
            setSelectedCalendarDate(value);
            const year = value.getFullYear();
            const month = String(value.getMonth() + 1).padStart(2, '0');
            const day = String(value.getDate()).padStart(2, '0');
            const selectedDateString = `${year}-${month}-${day}`;

            if (formattedStudentAttendanceForEdit[selectedDateString]) {
                const attendanceStatus = formattedStudentAttendanceForEdit[selectedDateString];
                switch (attendanceStatus) {
                    case 'on_leave':
                        setIsPresentOnSelectedDate(false);
                        setIsOnLeaveOnSelectedDate(true);
                        break;
                    case 'absent':
                        setIsPresentOnSelectedDate(false);
                        setIsOnLeaveOnSelectedDate(false);
                        break;
                    case 'present':
                        setIsPresentOnSelectedDate(true);
                        setIsOnLeaveOnSelectedDate(false);
                        break;
                    default:
                        break;
                }
            } else {
                setIsPresentOnSelectedDate(null);
            }

            const isInClassTiming = currentClassTimings?.some(
                timing => timing.day === getDayName(value)
            );
            if (isInClassTiming) {
                setHasClassOnSelectedDate(true);
            } else {
                setHasClassOnSelectedDate(false);
            }
        }
    };

    useEffect(() => {
        if (selectedCalendarDate) {
            const year = selectedCalendarDate.getFullYear();
            const month = String(selectedCalendarDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedCalendarDate.getDate()).padStart(2, '0');
            const selectedDateString = `${year}-${month}-${day}`;

            if (formattedStudentAttendanceForEdit[selectedDateString]) {
                const attendanceStatus = formattedStudentAttendanceForEdit[selectedDateString];
                switch (attendanceStatus) {
                    case 'on_leave':
                        setIsPresentOnSelectedDate(false);
                        break;
                    case 'absent':
                        setIsPresentOnSelectedDate(false);
                        break;
                    case 'present':
                        setIsPresentOnSelectedDate(true);
                        break;
                    default:
                        break;
                }
            } else {
                setIsPresentOnSelectedDate(null);
            }
        }
    }, [formattedStudentAttendanceForEdit]);

    const toggleAttendanceValue = (value: string) => {
        if (selectedCalendarDate) {
            const year = selectedCalendarDate.getFullYear();
            const month = String(selectedCalendarDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedCalendarDate.getDate()).padStart(2, '0');
            const selectedDateString = `${year}-${month}-${day}`;

            formattedStudentAttendanceForEdit[selectedDateString] = value;

            setFormattedStudentAttendanceForEdit(prevAttendance => {
                const updatedAttendance = { ...prevAttendance };
                updatedAttendance[selectedDateString] = value;

                return updatedAttendance;
            });
        }
    };

    const getEditedEntries = (
        originalObject: Record<string, string>,
        editedObject: Record<string, string>
    ): Record<string, string> => {
        const editedEntries: Record<string, string> = {};

        Object.keys(editedObject).forEach(key => {
            // Check if the key exists in the original object and the values are different
            if (originalObject[key] && originalObject[key] !== editedObject[key]) {
                editedEntries[key] = editedObject[key];
            }
        });

        return editedEntries;
    };

    const getNewEntries = (
        originalObject: Record<string, string | number >,
        editedObject: Record<string, string>
    ): Record<string, string> => {
        const newEntries: Record<string, string> = {};

        Object.keys(editedObject).forEach(key => {
            // Check if the key is not present in the original object
            if (!originalObject[key]) {
                newEntries[key] = editedObject[key];
            }
        });

        return newEntries;
    };

    const postAttendance = async (
        data: AttendanceRequest
    ): Promise<AttendanceDataWraper | undefined> => {
        console.log(data);
        
        const response = await ScheduleService.instance.postAttendance(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addAttendance,
        isLoading: isAttendancePosting,
        error: isAttendancePostError,
    } = useMutation(postAttendance, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
        // onError: error => {
        //     console.log(`On Attendance Error`);
        // },
    });

    const putAttendance = async (args: {
        attendanceId: number;
        data: AttendanceRequest;
    }): Promise<AttendanceDataWraper | undefined> => {
        const { attendanceId, data } = args;
        const response = await ScheduleService.instance.putAttendance(attendanceId, data);
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On Post Attendacnce Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateAttendance,
        isLoading: isAttendanceUpdating,
        error: isAttendanceUpdateError,
    } = useMutation(putAttendance, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
        },
        onError: error => {
            // console.log(`On Attendance Error`);
        },
    });

    const handleAttendanceChangesSubmit = async () => {
        const editedAttendances = getEditedEntries(
            formattedStudentAttendance,
            formattedStudentAttendanceForEdit
        );

        const newAttendances = getNewEntries(
            formattedStudentAttendance,
            formattedStudentAttendanceForEdit
        );

        for (const date in editedAttendances) {
            if (Object.prototype.hasOwnProperty.call(editedAttendances, date)) {
                const status = editedAttendances[date];
                const matchingAttendance = attendanceData?.find(item => {
                    return (
                        new Date(item?.attributes?.attendance_date)?.toISOString().split('T')[0] ===
                            date &&
                        item?.attributes?.class?.data?.id ===
                            (selectedClassForAttendance ??
                                (activeClassesOfStudent && activeClassesOfStudent[0]?.id))
                    );
                });

                if (matchingAttendance) {
                    const requestData = {
                        present: status === 'present' ? true : false,
                        on_leave: false,
                        markedBy: userState?.user?.id ?? 0,
                        systemMarked: false,
                    };
                    await updateAttendance({
                        attendanceId: matchingAttendance?.id,
                        data: requestData,
                    });
                }
            }
        }

        for (const date in newAttendances) {
            if (Object.prototype.hasOwnProperty.call(newAttendances, date)) {
                const status = newAttendances[date];
                const requestData = {
                    present: status === 'present' ? true : false,
                    on_leave: false,
                    note: '',
                    attendance_date: date,
                    class:
                        selectedClassForAttendance ??
                        (activeClassesOfStudent && activeClassesOfStudent[0]?.id),
                    student: Number(id),
                    time: new Date().toLocaleTimeString(),
                    markedBy: userState?.user?.id ?? 0,
                    systemMarked: false,
                };
                await addAttendance(requestData);
            }
        }

        await setIsAttendanceEditModeOn(false);
        await setFormattedStudentAttendanceForEdit({});
        await setSelectedCalendarDate(undefined);
    };

    const postFeeCollectionAsArchived = async (
        data: FeePaymentPostData,
        id: number
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.updateFeeCollection(data, id);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.er/////aaaror)}`);xxxxxxxxxxxxxxxxxxxx
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: archiveFeeCollection,
        isLoading: isFeeCollectionarchieving,
        isSuccess: isFeeCollectionArchieved,
    } = useMutation(
        ({ feePaymentPostData, id }: { feePaymentPostData: FeePaymentPostData; id: number }) =>
            postFeeCollectionAsArchived(feePaymentPostData, id),
        {
            onSuccess: data => {
                alert('Archived Fee Collection Successfully!');
                queryClient.invalidateQueries(STUDENTS_FEE_RECORDS_QUERY);
            },
            onError: error => {
                // console.log(`On fee collection Error:`, error);
                alert('Failed to archieve fee collection! Please try again');
            },
        }
    );

    const handleArchiveFeeRecord = (id: number) => {
        archiveFeeCollection({ feePaymentPostData: { archived: true }, id });
    };

    const handleGenerateFeeReport = () => {
        const tableContent: any[] = [];

        const FeeTableDataArray: any[][] = [];
        const tablebody: [number, number, number, number] = [0, 5, 0, 5];

        studentsFeeData?.data?.data?.map(data => {
            data?.attributes?.fee_dates?.map(fee => {
                FeeTableDataArray.push([
                    { text: getFormattedDate(fee?.fee_date) || 'N/A', alignment: 'right' },
                    { text: data?.attributes?.academy?.data?.attributes?.name || 'N/A' },
                    { text: data?.attributes?.class?.data?.attributes?.class_name || 'N/A' },
                    { text: data?.attributes?.payment_schedule || 'N/A' },
                    { text: data?.attributes?.sessions.toString() || 'N/A', alignment: 'right' },
                    { text: getFormattedDate(fee?.startDate) || 'N/A', alignment: 'right' },
                    { text: getFormattedDate(fee?.dueDate) || 'N/A', alignment: 'right' },
                    { text: fee?.payment_mode || 'N/A' },
                    { text: fee?.message || 'N/A' },
                    { text: `₹${fee?.amount.toString()}` || 'N/A', alignment: 'right' },
                ]);
            });
        });

        tableContent.push(
            ...[
                {
                    layout: { type: 'flex', justifyContent: 'space-between' },
                    columns: [
                        {
                            stack: [
                                {
                                    image: SpartsLogo,
                                    width: 120,
                                },
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: `Student Fee Collection Report`,
                                    style: 'header',
                                    alignment: 'center',
                                    fillColor: '#ffffff',
                                },
                                {
                                    text: `${studentDetailsData?.data?.data?.attributes?.firstName} ${studentDetailsData?.data?.data?.attributes?.middleName || ''} ${studentDetailsData?.data?.data?.attributes?.lastName || ''}`,
                                    style: 'subheader',
                                    alignment: 'center',
                                    fillColor: '#ffffff',
                                },
                            ],
                        },
                        { stack: [] },
                    ],
                },
                {
                    style: 'tablebody',
                    table: {
                        widths: [
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                            'auto',
                        ],
                        body: [
                            [
                                {
                                    text: 'Fee Payment Date',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                    alignment: 'right',
                                },
                                { text: 'Academy Name', style: 'subheader', fillColor: '#dddddd' },
                                { text: 'Class', style: 'subheader', fillColor: '#dddddd' },
                                {
                                    text: 'Payment Schedule',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                },
                                {
                                    text: 'Sessions/week',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                    alignment: 'right',
                                },
                                {
                                    text: 'Fee Start Date',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                    alignment: 'right',
                                },
                                {
                                    text: 'Fee End Date',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                    alignment: 'right',
                                },
                                { text: 'Payment Mode', style: 'subheader', fillColor: '#dddddd' },
                                { text: 'Message', style: 'subheader', fillColor: '#dddddd' },
                                {
                                    text: 'Amount Received',
                                    style: 'subheader',
                                    fillColor: '#dddddd',
                                    alignment: 'right',
                                },
                            ],
                            ...FeeTableDataArray,
                        ],
                    },
                    margin: [0, 30, 0, 30],
                },
            ]
        );

        const dd = {
            pageSize: { width: 1200, height: 660 },
            background: {
                image: SpartsWatermark,
                width: 900,
                opacity: 0.15,
                absolutePosition: { x: 110, y: -110 },
            },
            content: tableContent,
            styles: {
                header: { fontSize: 16, bold: true, marginBottom: 0, marginTop: 0 },
                subheader: { fontSize: 14, bold: true },
                tablebody: { margin: tablebody, fontSize: 12 },
            },
            pageOrientation: 'landscape' as const,
        };

        pdfMake
            .createPdf(dd)
            .download(`Fee-Report_${studentDetailsData?.data?.data?.attributes?.firstName}.pdf`);
    };

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });
    // console.log(academiesData?.data?.data.map(item => item.id));
    const { isSuccess: isClassFetchSuccess, data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const [expandedFitnessCard, setExpandedFitnessCard] = useState(0);

    const handleExpandCollapseFitnessCard = (id: number) => {
        if (expandedFitnessCard === id) {
            setExpandedFitnessCard(0);
        } else {
            setExpandedFitnessCard(id);
        }
    };

    const [isRecordFitnessTestModalOpen, setIsRecordFitnessTestModalOpen] = useState(false);
    const [selectedRecordedFitnessTest, setSelectedRecordedFitnessTest] = useState<FitnessTestConductedData | null>(null);

    const handleRecordFitnessTestModalOpen = (data: FitnessTestConductedData) => {
        setIsRecordFitnessTestModalOpen(true);
        setSelectedRecordedFitnessTest(data);
    }

    const handleRecordFitnessTestModalClose = () => {
        setIsRecordFitnessTestModalOpen(false);
        setSelectedRecordedFitnessTest(null);
    }

    return {
        isDetailsFetchSuccess,
        isDetailsLoading,
        studentDetailsData,
        ownersAcademies: ownersAcademies?.data?.data,
        setOpenTab,
        goToAcademyDetail,
        handleGenerateFeeReport,
        openTab,
        handleEditStudentModalClose,
        isEditStudentModalOpen,
        mapAcademiesToOptions,
        mapClassesToOptions,
        isProfileDropdownOpen,
        isFeeHistoryDropdownOpen,
        handleDropdownToggle,
        handleStudentDropout,
        userState,
        handleEditStudent,
        handleDropStudentModalClose,
        isProfileImagePosting,
        isDropStudentFormOpen,
        studentActiveAcademies,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        studentsFeeData: studentsFeeData?.data?.data ?? [],
        selectedFeeCard,
        handleProfileImgChange,
        handleFeeListCardClick,
        profileImageInputKey,
        formattedStudentAttendance,
        activeClassesOfStudent,
        handleClassChangeInAttendance,
        selectedClassForAttendance,
        isAttendanceEditModeOn,
        handleEditAttendanceClick,
        handleDayClickOnEditMode,
        selectedCalendarDate,
        getDayClassName,
        tileContent,
        getDayClassNameForEdit,
        tileContentForEdit,
        isPresentOnSelectedDate,
        toggleAttendanceValue,
        formattedStudentAttendanceForEdit,
        handleAttendanceChangesSubmit,
        handleCancelEditingClick,
        isOnLeaveOnSelectedDate,
        hasClassOnSelectedDate,
        previousClassesOfStudent,
        handleArchiveFeeRecord,
        academyExpand,
        handleAcademyClick,
        studentsEvaluationData,
        currentClassEnrollmentDate,
        assignmentSubmissions: assignmentSubmissionsList?.data?.data ?? [],
        studentFitnessTests: studentFitnessTests?.data?.data ?? [],
        expandedFitnessCard,
        handleExpandCollapseFitnessCard,
        isRecordFitnessTestModalOpen,
        handleRecordFitnessTestModalClose,
        selectedRecordedFitnessTest,
        handleRecordFitnessTestModalOpen,
        goToFeeHistory,
        onCollectFeeClicked,
        isMenuOpen,
        handleMenu,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        openRecordLeaveFormModal,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        formValues,
        recordLeaveModalStudentData,
        isRecordPostSuccess,
        moreActionsDropDownRef,
        studentAttendanceArray,
        isAttendanceUpdating,
        isAttendancePosting
    };
};

export default StudentDetailsViewModel;
