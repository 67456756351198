// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_cardContainer__SuIgY {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 1rem
}
.style_cardHeader__k-PY\\+ {
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
    border-bottom-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity));
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-transform: uppercase
}
.style_flexGrid__VAghh {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem
}
.style_flexGrid__VAghh > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}
@media (min-width: 640px) {
    .style_flexGrid__VAghh {
        flex-direction: row
    }
    .style_flexGrid__VAghh > :not([hidden]) ~ :not([hidden]) {
        --tw-divide-y-reverse: 0;
        border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
        border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
        --tw-divide-x-reverse: 0;
        border-right-width: calc(1px * var(--tw-divide-x-reverse));
        border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
    }
}
.style_flexBasis__QH9JQ {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    padding-top: 1rem
}
.style_marginLeft2__NF7vX {
    margin-left: 0.5rem
}
`, "",{"version":3,"sources":["webpack://./src/view/components/widgets/growth-bar-chart/style.module.css"],"names":[],"mappings":"AACI;IAAA,aAA6F;IAA7F,YAA6F;IAA7F,sBAA6F;IAA7F,8BAA6F;IAA7F,kBAA6F;IAA7F,iBAA6F;IAA7F,sBAA6F;IAA7F,yDAA6F;IAA7F,kBAA6F;IAA7F,yDAA6F;IAA7F;AAA6F;AAG7F;IAAA,sBAAuF;IAAvF,aAAuF;IAAvF,8BAAuF;IAAvF,wBAAuF;IAAvF,sBAAuF;IAAvF,yDAAuF;IAAvF,mBAAuF;IAAvF,oBAAuF;IAAvF,mBAAuF;IAAvF,oBAAuF;IAAvF;AAAuF;AAGvF;IAAA,aAAgG;IAAhG,YAAgG;IAAhG,sBAAgG;IAAhG,mBAAgG;IAAhG;AAAgG;AAAhG;IAAA,wBAAgG;IAAhG,kEAAgG;IAAhG;AAAgG;AAAhG;IAAA;QAAA;IAAgG;IAAhG;QAAA,wBAAgG;QAAhG,kEAAgG;QAAhG,2DAAgG;QAAhG,wBAAgG;QAAhG,0DAAgG;QAAhG;IAAgG;AAAA;AAGhG;IAAA,aAAyC;IAAzC,eAAyC;IAAzC,uBAAyC;IAAzC;AAAyC;AAGzC;IAAA;AAAW","sourcesContent":[".cardContainer {\n    @apply bg-white border border-zinc-200 p-4 rounded-[4px] h-full flex flex-col justify-between;\n}\n.cardHeader {\n    @apply text-lg flex justify-between border-b-2 border-zinc-100 uppercase py-2 pb-4 mb-3;\n}\n.flexGrid {\n    @apply flex flex-col sm:flex-row gap-2 divide-y sm:divide-y-0 sm:divide-x flex-grow items-center;\n}\n.flexBasis {\n    @apply basis-1/2 pt-4 flex justify-center;\n}\n.marginLeft2 {\n    @apply ml-2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `style_cardContainer__SuIgY`,
	"cardHeader": `style_cardHeader__k-PY+`,
	"flexGrid": `style_flexGrid__VAghh`,
	"flexBasis": `style_flexBasis__QH9JQ`,
	"marginLeft2": `style_marginLeft2__NF7vX`
};
export default ___CSS_LOADER_EXPORT___;
