import React, { useEffect, useRef } from 'react';
import style from './style.module.css';
import NavMenu from '../../components/widgets/nav-menu';
import AcademyHeader from '../../components/widgets/academy-header';
// import downarrowIcon from "../../../assets/icons/union.png";
import { DATA_DISCIPLINES_LIST } from './data';
import CustomInput from '../../components/bits/custom-input';
import DropdownTags from '../../components/widgets/dropdown-tags';
import PhotoUploadInput from '../../components/widgets/photo-upload-input';
import AcademyListItem from '../../components/pieces/academy-list-item';
import AcademiesViewModel from '../../view-models/academies-view-model';
import Loader from '../../components/bits/loader';
import filterIcon from '../../../assets/icons/filter.png';
import ClassListItem from '../../components/pieces/class-list-item';
import { ClassData } from '../../../models/class/class-data';
import PopupModal from '../../components/widgets/popup-modal';
import NewClassForm from '../../components/widgets/new-class-form';
import Addacademy from '../../components/widgets/add-academy';
import { Roles } from '../../../utils/auth/roles';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import AddFeeStructure from '../../actions/add-fee-structure';
import { FeeStructureData } from '../../../models/fee-structure/fee-structure-data';
import FeeStructureListItem from '../../components/pieces/fee-structure-list-item';
import StudentsTimingsConfiltResolveForm from '../../components/widgets/students-timings-conflict-resolve-form';

const Academies = () => {
    const {
        isLoading,
        isModalVisible,
        // showModal,
        hideModal,
        state,
        // isLoading,
        isAcademiesFetchSuccess,
        // academiesData,
        // itemsPerPage,
        // currentPage,
        // indexOfLastItem,
        // indexOfFirstItem,
        academies,
        selectedClass,
        classes,
        isClassFetchSuccess,
        setOpenTab,
        selectedAcademy,
        handleItemClick,
        handleItemExpand,
        classDaysSelected,
        handleNewClassModalClose,
        handleNewClassModalOpen,
        isNewClassModalOpen,
        disciplines,
        handleDaySelect,
        handleInputChange,
        newClassFormData,
        handleAddNewClass,
        // handleExpandedItem,
        // isExpanded,
        total,
        isAddAcademyModalOpen,
        setIsAddAcademyModalOpen,
        onAddAcademyeClicked,
        handleFileChange,
        uploadedImageData,
        handleAddAcademyeModalClose,
        handleAddAcademyFormInputChange,
        addAcademyformValues,
        handlerSubmitForm,
        // mapdisciplinestoOptions,
        mapDisciplineListToOptions,
        isEditClassModalOpen,
        handleEditClassModalClose,
        handleEditClassModalOpen,
        handleAddBankName,
        deleteExistingBankAccount,
        userState,
        handleDeleteImage,
        isAcademyImagePosting,
        handleEditAcademyModalClose,
        isEditAcademyModalOpen,
        handleEditAcademyModalOpen,
        handleTimeAddForDay,
        isStudentsImpactedResolved,
        handleTimingsInputChange,
        handleTimeDeleteForDay,
        isNewFeeStructureModalOpen,
        handleNewFeeStuctureCreation,
        handleNewFeeStructureModalClose,
        handleResolveTimingConflictSubmit,
        isFeeStructureListFetching,
        feeStructuresData,
        expandFeeStructureCard,
        isImpactedStudentsFetching,
        selectedFeeStructure,
        groupedImpactedStudents,
        currentClassTimings,
        resolvedTimingEntries,
        handleNewTimingSelection,
    } = AcademiesViewModel();

    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (isModalVisible) {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalVisible]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                hideModal();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className={style.academiesContainerWrapper}>
            {isLoading && <FullScreenLoader />}
            <NavMenu>
                {userState?.userDetails?.attributes?.academyAccess === 'fullAccess' ||
                userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                    <>
                        <div className={style.academyNavigationHeaderContainer}>
                            <div
                                className={`${
                                    state.openTab === 1
                                        ? style.academyLabelContainerActive
                                        : style.academyLabelContainer
                                }`}
                                onClick={() => setOpenTab(1)}
                            >
                                <div className={style.academyLabel}>Academies</div>
                            </div>

                            <div
                                className={`${
                                    state.openTab === 2
                                        ? style.academyLabelContainerActive
                                        : style.academyLabelContainer
                                }`}
                                onClick={() => setOpenTab(2)}
                            >
                                <div className={style.academyLabel}>Classes</div>
                            </div>

                            <div
                                className={`${
                                    state.openTab === 3
                                        ? style.academyLabelContainerActive
                                        : style.academyLabelContainer
                                }`}
                                onClick={() => setOpenTab(3)}
                            >
                                <div className={style.academyLabel}>Fee Structure</div>
                            </div>
                        </div>
                        <div
                            className={
                                style.academyTabContainer + ` ${state.openTab == 1 ? '' : 'hidden'}`
                            }
                        >
                            {isAcademiesFetchSuccess ? (
                                <div className={style.academiesContainer}>
                                    <div className={style.academiesHeader}>
                                        <div className={style.academiesHeaderLabelContainer}>
                                            <div className={style.academiesLabel}>
                                                Total academies: {total}
                                            </div>
                                            {/* <img src={filterIcon} className="h-5 w-5   " /> */}

                                            {/* temporarily commented out for demo */}
                                            {userState?.userDetails?.attributes?.academyAccess !==
                                                'viewOnly' && (
                                                <button
                                                    className={style.academiesButton}
                                                    onClick={onAddAcademyeClicked}
                                                >
                                                    + Add new academy
                                                </button>
                                            )}

                                            {/* temporarily commented out for demo */}
                                        </div>

                                        {/* temporarily commented out for demo */}

                                        {/* 
                                            <div className={style.academiesDropdownContainer}>
                                                <div className={style.academiesDropdownLabel}>
                                                    Sort by : Academy Name (A-Z)
                                                </div>
                                                <img
                                                    className={style.academiesDropdownIcon}
                                                    src={downarrowIcon}
                                                    alt="downarrow"
                                                />
                                            </div> 
                                        */}

                                        {/* temporarily commented out for demo */}
                                    </div>
                                    <div>
                                        {(academies ?? [])?.map((item, index) => (
                                            <AcademyListItem
                                                key={index}
                                                academyAttributes={item.attributes}
                                                handleItemClick={() => handleItemClick(item.id)}
                                                isAcademyShouldExpanded={
                                                    selectedAcademy === item.id
                                                }
                                                handleEditAcademyModalOpen={() =>
                                                    handleEditAcademyModalOpen(item)
                                                }
                                                viewOnly={
                                                    userState?.userDetails?.attributes
                                                        ?.academyAccess === 'viewOnly'
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={style.loaderContainer}>
                                    <Loader />
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                style.academyTabContainer + ` ${state.openTab == 2 ? '' : 'hidden'}`
                            }
                        >
                            {isClassFetchSuccess ? (
                                <div className={style.academiesContainer}>
                                    <div className={style.academiesHeader}>
                                        <div className={style.academiesHeaderLabelContainer}>
                                            <div className={style.academiesLabel}>
                                                Total classes: {classes?.length}
                                            </div>
                                            {userState?.userDetails?.attributes?.academyAccess !==
                                                'viewOnly' && (
                                                <div
                                                    className={style.createNewClassBtn}
                                                    onClick={handleNewClassModalOpen}
                                                >
                                                    + Create a new class
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {(classes ?? []).map((item: ClassData, index: number) => (
                                            <div key={index}>
                                                <ClassListItem
                                                    key={index}
                                                    classAttributes={item.attributes}
                                                    handleItemExpand={() =>
                                                        handleItemExpand(item.id)
                                                    }
                                                    isClassItemExpanded={selectedClass === item.id}
                                                    handleEditClassModalOpen={() =>
                                                        handleEditClassModalOpen(item)
                                                    }
                                                    viewOnly={
                                                        userState?.userDetails?.attributes
                                                            ?.academyAccess === 'viewOnly'
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={style.loaderContainer}>
                                    <Loader />
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                style.academyTabContainer + ` ${state.openTab == 3 ? '' : 'hidden'}`
                            }
                        >
                            {!isFeeStructureListFetching ? (
                                <div className={style.academiesContainer}>
                                    <div className={style.academiesHeader}>
                                        <div className={style.academiesHeaderLabelContainer}>
                                            <div className={style.academiesLabel}>
                                                Total items: {feeStructuresData?.length}
                                            </div>
                                            {userState?.userDetails?.attributes?.academyAccess !==
                                                'viewOnly' && (
                                                <div
                                                    className={style.createNewClassBtn}
                                                    onClick={handleNewFeeStuctureCreation}
                                                >
                                                    + Create a new fee or charge
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {feeStructuresData.map(
                                            (item: FeeStructureData, index: number) => (
                                                <div key={index}>
                                                    <FeeStructureListItem
                                                        data={item}
                                                        isExpaned={selectedFeeStructure === item.id}
                                                        expandFeeStructureCard={() =>
                                                            expandFeeStructureCard(item.id)
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className={style.loaderContainer}>
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <AccessDeniedPage />
                )}
            </NavMenu>

            <PopupModal
                handleModalClose={handleAddAcademyeModalClose}
                isOpen={isAddAcademyModalOpen}
                background="white"
                title="Add Academy"
            >
                <Addacademy
                    // images={null}
                    handleFileChange={handleFileChange}
                    uploadedImageData={uploadedImageData}
                    handleDeleteImage={handleDeleteImage}
                    handleAddAcademyFormInputChange={handleAddAcademyFormInputChange}
                    mapdisciplinestoOptions={mapDisciplineListToOptions}
                    addAcademyformValues={addAcademyformValues}
                    // isUploading={isUploading}
                    // setIsUploading={setIsUploading}
                    handlerSubmitForm={handlerSubmitForm}
                    //  addAcademyFormData={addAcademyFormData}
                    handleAddBankName={handleAddBankName}
                    deleteExistingBankAccount={deleteExistingBankAccount}
                    isEditMode={false}
                    isAcademyImagePosting={isAcademyImagePosting}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleEditAcademyModalClose}
                isOpen={isEditAcademyModalOpen}
                background="white"
                title="Edit Academy"
            >
                <Addacademy
                    // images={null}
                    handleFileChange={handleFileChange}
                    uploadedImageData={uploadedImageData}
                    handleAddAcademyFormInputChange={handleAddAcademyFormInputChange}
                    mapdisciplinestoOptions={mapDisciplineListToOptions}
                    // addAcademyFormData={addAcademyFormData}
                    handleDeleteImage={handleDeleteImage}
                    addAcademyformValues={addAcademyformValues}
                    // isUploading={isUploading}
                    // setIsUploading={setIsUploading}
                    handlerSubmitForm={handlerSubmitForm}
                    handleAddBankName={handleAddBankName}
                    deleteExistingBankAccount={deleteExistingBankAccount}
                    isEditMode={true}
                    isAcademyImagePosting={isAcademyImagePosting}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleNewClassModalClose}
                isOpen={isNewClassModalOpen}
                background="white"
                title="Add Class"
            >
                <NewClassForm
                    classDaysSelected={classDaysSelected}
                    handleDaySelect={handleDaySelect}
                    handleInputChange={handleInputChange}
                    academies={academies}
                    newClassFormData={newClassFormData}
                    handleAddNewClass={handleAddNewClass}
                    disciplines={disciplines}
                    isEditMode={false}
                    handleTimeAddForDay={handleTimeAddForDay}
                    handleTimingsInputChange={handleTimingsInputChange}
                    handleTimeDeleteForDay={handleTimeDeleteForDay}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleEditClassModalClose}
                isOpen={isEditClassModalOpen}
                background="white"
                title="Edit Class"
            >
                <NewClassForm
                    classDaysSelected={classDaysSelected}
                    handleDaySelect={handleDaySelect}
                    handleInputChange={handleInputChange}
                    academies={academies}
                    newClassFormData={newClassFormData}
                    handleAddNewClass={handleAddNewClass}
                    disciplines={disciplines}
                    isEditMode={true}
                    handleTimeAddForDay={handleTimeAddForDay}
                    handleTimeDeleteForDay={handleTimeDeleteForDay}
                    handleTimingsInputChange={handleTimingsInputChange}
                />
            </PopupModal>

            <PopupModal
                handleModalClose={handleEditClassModalClose}
                isOpen={isEditClassModalOpen && !isStudentsImpactedResolved}
                background="white"
                title="Resolve Class Timing Conflicts"
            >
                <StudentsTimingsConfiltResolveForm 
                    isImpactedStudentsFetching={isImpactedStudentsFetching}
                    groupedImpactedStudents={groupedImpactedStudents}
                    currentClassTimings={currentClassTimings}
                    resolvedTimingEntries={resolvedTimingEntries}
                    handleNewTimingSelection={handleNewTimingSelection}
                    handleResolveTimingConflictSubmit={handleResolveTimingConflictSubmit}
                />
            </PopupModal>

            <AddFeeStructure
                isOpen={isNewFeeStructureModalOpen}
                handleClose={handleNewFeeStructureModalClose}
                feeStructuresData={feeStructuresData}
            />
        </div>
    );
};

export default Academies;
