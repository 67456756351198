// academies-viewmodel.tsx
import { RootState } from '../../utils/redux/store';
import { useCallback, useEffect, useReducer, useState } from 'react';
import React from 'react';
import { AcademiesState, academiesReducer } from '../reducers/academies-reducer';
import { AcademiesService } from '../../services/academies-service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    ACADEMY_QUERY,
    CLASS_DETAILS_QUERY,
    DISCIPLINE_QUERY,
    FEE_STRUCTURES_LIST_DATA_QUERY,
    SCHEDULE_QUERY,
    STUDENT_DATA_QUERY,
} from '../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ClassService from '../../services/class-service';
import { ClassPostData } from '../../models/class/class-post-data';
import { ClassDetailsWrapper } from '../../models/class/class-details-wrapper';
import { UploadService } from '../../services/upload-service';
import AcademyPostData from '../../utils/types/academy-post-data';
import { ClassData } from '../../models/class/class-data';
import { AcademyData } from '../../models/academy/academy-data';
import { AcademyDetailsWrapper } from '../../models/academy/academy-details-wrapper';
import { DisciplinesService } from '../../services/disciplines-service';
import { FeeStructureService } from '../../services/fee-structure-service';
import { StudentData } from '../../models/student/student-data';
import { StudentService } from '../../services/student-service';
import { StudentClassSessions } from '../../models/student/student-class-sessions';

// const academyFormInitialState = {
//     adacemyName: '',
//     disciplines: '',
//     contactnumber: '',
//     emailid: '',
//     webpage: '',
//     academyAddress: '',
//     GSTNo: '',
//     bankAccName: '',
//     bankAccounts: [],
//     remarks: '',
// }

// const [addAcademyformValues, SetAddAcademyFormValues] = useState<AddAcademyFormValues>(academyFormInitialState);

export interface DayClassTimings {
    from: string;
    to: string;
}

export interface newClassFormDataType {
    academy: number;
    discipline: number;
    classType: string;
    className: string;
    skillLevel: string;
    monday: DayClassTimings[];
    tuesday: DayClassTimings[];
    wednesday: DayClassTimings[];
    thursday: DayClassTimings[];
    friday: DayClassTimings[];
    saturday: DayClassTimings[];
    sunday: DayClassTimings[];
}

export interface AddAcademyFormValues {
    adacemyName: string;
    // disciplines: string;
    contactnumber: string;
    emailid: string;
    webpage: string;
    academyAddress: string;
    GSTNo: string;
    images: number[];
    bankAccName: string;
    bankAccounts: string[];
    remarks: string;
    selectedDisciplines: number[];
}

interface ClassTiming {
    day: string;
    start_time: string;
    end_time: string;
}

interface DayTime {
    from: string;
    to: string;
}

// export interface AddAcademyFormValues {
//     adacemyName: string;
//     disciplines: string;
//     contactnumber: string;
//     emailid: string;
//     webpage: string;
//     academyAddress: string;
//     GSTNo: string;
//     bankAccName: string;
//     bankAccounts: string[];
//     remarks: string;
// }

const AcademiesViewModel = () => {
    const location = useLocation();
    const parameters = location.state || {};

    const initialState: AcademiesState = {
        openTab: 1,
        currentPage: 1,
        isModalVisible: false,
        selectedAcademy: 0,
        selectedClass: 0,
    };

    const initialDayTimings = [
        {
            from: '',
            to: '',
        },
    ];

    const initialClassFormState = {
        academy: 0,
        discipline: 0,
        classType: '',
        className: '',
        skillLevel: '',
        monday: initialDayTimings,
        tuesday: initialDayTimings,
        wednesday: initialDayTimings,
        thursday: initialDayTimings,
        friday: initialDayTimings,
        saturday: initialDayTimings,
        sunday: initialDayTimings,
    };

    const academyFormInitialState = {
        adacemyName: '',
        // disciplines: '',
        contactnumber: '',
        emailid: '',
        webpage: '',
        academyAddress: '',
        GSTNo: '',
        images: [],
        bankAccName: '',
        bankAccounts: [],
        remarks: '',
        selectedDisciplines: [],
    };

    const [state, dispatch] = useReducer(academiesReducer, initialState);
    const [uploadedImageData, setUploadedImageData] = useState<string[]>([]);
    const [isAddAcademyModalOpen, setIsAddAcademyModalOpen] = useState(false);
    const [imageid, setImageId] = useState<number[]>([]);
    const [editingClassData, setEditingClassData] = useState<ClassData>();
    const [editingAcademyData, setEditingAcademyData] = useState<AcademyData>();

    const userState = useSelector((state: RootState) => state.user);
    const [isNewClassModalOpen, setIsNewClassModalOpen] = useState(false);
    const [isEditClassModalOpen, setIsEditClassModalOpen] = useState(false);
    const [isEditAcademyModalOpen, setIsEditAcademyModalOpen] = useState(false);
    const [classDaysSelected, setClassDaysSelected] = useState<number[]>([]);
    const queryClient = useQueryClient();
    const [disciplines, setDisciplines] = useState<{ value: number; label: string }[]>([]);
    const [newClassFormData, setNewClassFormData] =
        useState<newClassFormDataType>(initialClassFormState);
    const [addAcademyformValues, SetAddAcademyFormValues] =
        useState<AddAcademyFormValues>(academyFormInitialState);
    // const [AddAcademyFormValues, setAddAcademyFormValues] =
    //     useState<AddAcademyFormValues>(initialAcademyFormState);
    const [selectedAcademy, setSelectedAcademy] = useState(0);
    // const [isUploading, setIsUploading] = useState(false);
    // const [disciplineList, setDisciplineList] = useState<string[]>([]);

    const setOpenTab = useCallback(
        (openTab: number) => {
            dispatch({
                type: 'setOpenTab',
                payload: openTab,
            });
        },
        [state.openTab]
    );

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isDisciplineFetchSuccess, data: disciplineData } = useQuery({
        queryKey: [DISCIPLINE_QUERY],
        queryFn: () => DisciplinesService.instance.getDisciplinesList(),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isLoading: isFeeStructureListFetching,
        isSuccess: isFeeStructuresFetchSuccess,
        data: feeStructuresData,
    } = useQuery({
        queryKey: [FEE_STRUCTURES_LIST_DATA_QUERY],
        queryFn: () => FeeStructureService.instance.getFeeStructureList(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isClassFetchSuccess, data: classesData } = useQuery({
        queryKey: [CLASS_DETAILS_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const handleItemClick = (academyId: number) => {
        dispatch({
            type: 'setSelectedAcademy',
            payload: state.selectedAcademy === academyId ? 0 : academyId,
        });
    };

    useEffect(() => {
        !parameters.studentAcademyId
            ? dispatch({
                  type: 'setSelectedAcademy',
                  payload:
                      academiesData?.data?.data && academiesData.data.data.length > 0
                          ? academiesData.data.data[0]?.id
                          : 0,
              })
            : dispatch({
                  type: 'setSelectedAcademy',
                  payload: parameters.studentAcademyId,
              });
    }, [isAcademiesFetchSuccess, parameters.studentAcademyId]);

    const handleItemExpand = (classId: number) => {
        dispatch({
            type: 'setSelectedClass',
            payload: state.selectedClass === classId ? 0 : classId,
        });
    };

    const handleNewClassModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsNewClassModalOpen(false);
            setNewClassFormData(initialClassFormState);
            setClassDaysSelected([]);
        } else {
            return;
        }
    };

    const handleNewClassModalOpen = () => {
        setIsNewClassModalOpen(true);
    };

    const [isNewFeeStructureModalOpen, setIsNewFeeStructureModalOpen] = useState(false);

    const handleNewFeeStuctureCreation = () => {
        setIsNewFeeStructureModalOpen(true);
    };

    const handleEditClassModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsEditClassModalOpen(false);
            setClassDaysSelected([]);
            setNewClassFormData(initialClassFormState);
            setIsStudentsImpactedResolved(true)
        } else {
            return;
        }
    };

    const handleEditAcademyModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsEditAcademyModalOpen(false);
            SetAddAcademyFormValues(academyFormInitialState);
        } else {
            return;
        }
    };

    const generateClassFormData = (classData: ClassData) => {
        const { attributes } = classData;
        const classFormData = {
            academy: attributes?.academies?.data?.id,
            discipline: attributes?.class_discipline?.data?.id,
            classType: attributes?.class_type,
            className: attributes?.class_name,
            skillLevel: attributes?.skill_level,
            monday:
                attributes?.class_timings?.filter(item => item?.day === 'Monday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Monday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Monday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            tuesday:
                attributes?.class_timings?.filter(item => item?.day === 'Tuesday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Tuesday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Tuesday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            wednesday:
                attributes?.class_timings?.filter(item => item?.day === 'Wednesday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Wednesday').length >
                    0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Wednesday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            thursday:
                attributes?.class_timings?.filter(item => item?.day === 'Thursday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Thursday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Thursday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            friday:
                attributes?.class_timings?.filter(item => item?.day === 'Friday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Friday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Friday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            saturday:
                attributes?.class_timings?.filter(item => item?.day === 'Saturday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Saturday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Saturday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
            sunday:
                attributes?.class_timings?.filter(item => item?.day === 'Sunday') &&
                attributes?.class_timings?.filter(item => item?.day === 'Sunday').length > 0
                    ? attributes?.class_timings
                          ?.filter(item => item?.day === 'Sunday')
                          .map(data => ({
                              from: data.start_time?.substring(0, 5) ?? '',
                              to: data.end_time?.substring(0, 5) ?? '',
                          }))
                    : initialDayTimings,
        }

        return classFormData;
    }

    const handleEditAcademyModalOpen = (academy: AcademyData) => {
        setEditingAcademyData(academy);
        setSelectedAcademy(academy.id);

        const imageData = academy.attributes?.images?.data;
        const uploadedImages = imageData ? imageData.map(item => item.attributes.url) : [];
        const bankAccounts =
            academy?.attributes?.bankAccounts === ''
                ? []
                : academy?.attributes?.bankAccounts?.split(',');

        setUploadedImageData(uploadedImages);
        setImageId(imageData?.map(item => item.id));

        SetAddAcademyFormValues({
            adacemyName: academy.attributes.name,
            contactnumber: academy.attributes.contactNo,
            emailid: academy.attributes.email,
            webpage: academy.attributes.webpage,
            academyAddress: academy.attributes.address,
            GSTNo: academy.attributes.GSTNo,
            images: imageData ? imageData.map(item => item.id) : [],
            bankAccName: '',
            bankAccounts: bankAccounts,
            remarks: academy?.attributes?.remarks,
            selectedDisciplines: academy?.attributes?.selectedDisciplines?.data?.map(
                item => item.id
            ),
        });
        setIsEditAcademyModalOpen(true);
    };

    const handleEditClassModalOpen = (data: ClassData) => {
        setEditingClassData(data);

        const weekdays = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
        weekdays.forEach((day, index) => {
            if (data?.attributes?.class_timings?.some(item => item?.day === day)) {
                setClassDaysSelected(prev => [...prev, index + 1]);
            }
        });

        setNewClassFormData(generateClassFormData(data));
        setIsEditClassModalOpen(true);
    };

    const handleTimeAddForDay = (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >
    ) => {
        setNewClassFormData(prev => ({
            ...prev,
            [day]: [
                ...(prev[day] as DayClassTimings[]),
                {
                    from: '',
                    to: '',
                },
            ],
        }));
    };

    const handleTimeDeleteForDay = (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number
    ) => {
        setNewClassFormData(prev => ({
            ...prev,
            [day]: prev[day].filter((item, i) => i !== index),
        }));
    };

    const handleDaySelect = (day: number) => {
        setClassDaysSelected(prevState => {
            const isSelected = prevState.includes(day);

            if (isSelected) {
                return prevState.filter(selectedDay => selectedDay !== day);
            } else {
                return [...prevState, day];
            }
        });
    };

    const handleInputChange = (field: string, value: string | number[] | number) => {
        setNewClassFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleTimingsInputChange = (
        day: keyof Omit<
            newClassFormDataType,
            'academy' | 'discipline' | 'classType' | 'className' | 'skillLevel'
        >,
        index: number,
        field: 'from' | 'to',
        value: string
    ) => {
        setNewClassFormData(prev => {
            const updatedDayTimings = [...(prev[day] as DayClassTimings[])];
            updatedDayTimings[index] = {
                ...updatedDayTimings[index],
                [field]: value,
            };

            return {
                ...prev,
                [day]: updatedDayTimings,
            };
        });
    };

    const postNewClass = async (data: ClassPostData): Promise<ClassDetailsWrapper | undefined> => {
        const response = await ClassService.instance.postNewClass(data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewClass,
        isLoading: isNewClassAdding,
        isSuccess: isNewClassAdded,
    } = useMutation(postNewClass, {
        onSuccess: data => {
            alert('Added Class Successfully!');
            setIsNewClassModalOpen(false);
            setClassDaysSelected([]);
            setNewClassFormData(initialClassFormState);
            queryClient.invalidateQueries(CLASS_DETAILS_QUERY);
        },
        onError: error => {
            // console.log(`On class add Error:`, error);
            alert('Failed to create class! Please try again');
        },
    });

    const updateClass = async (data: ClassPostData): Promise<ClassDetailsWrapper | undefined> => {
        const response = await ClassService.instance.updateClass(data, editingClassData?.id ?? 0);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Edit Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editClass,
        isLoading: isClassEditing,
        isSuccess: isClassEdited,
    } = useMutation(updateClass, {
        onSuccess: data => {
            alert('Updated Class Successfully!');
            setIsEditClassModalOpen(false);
            setClassDaysSelected([]);
            setNewClassFormData(initialClassFormState);
            setGroupedImpactedStudents({})
            setResolvedTimingEntries({})
            queryClient.invalidateQueries(CLASS_DETAILS_QUERY);
        },
        onError: error => {
            // console.log(`On class update Error:`, error);
            alert('Failed to update class! Please try again');
        },
    });

    // post Academy Images
    const postAddAcademyImages = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.forEach(item => {
                        if (item.url) {
                            setUploadedImageData(prevData => [...prevData, item.url]);
                        }
                        if (item.id) {
                            setImageId(prevData => [...prevData, item.id]);
                        }
                    });
            } else {
                // console.log(
                //     `On Post postAddAcademyImages Error ${JSON.stringify(
                //         response.error,
                //     )}`,
                // );
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postAddAcademyImages: ${error}`);
            throw error;
        }
    };

    const {
        mutate: uploadAcademyImage,
        isLoading: isAcademyImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(postAddAcademyImages, {
        onSuccess: data => {
            queryClient.invalidateQueries();
            // console.log('image Upload Successful')
        },
        onError: error => {
            // console.log(`On upload images Error`);
            alert('Failed to Upload');
            // Error actions
        },
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;

        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes

                if (fileSizeInKB > 10240) {
                    alert('Image size exceeds 10MB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            // setIsUploading(true);
            uploadAcademyImage(data);
        }
    };

    const handleDeleteImage = async (index: number) => {
        const deletedImageId = imageid[index];
        try {
            await UploadService.instance.deleteImage(deletedImageId);
            setImageId(prevIds => prevIds.filter((_, i) => i !== index));
        } catch (error) {
            console.error('Error deleting image:', error);
            return;
        }

        setUploadedImageData(prevData => prevData.filter((_, i) => i !== index));
    };

    const generateClassTimings = (
        classDaysSelected: number[],
        newClassFormData: newClassFormDataType
      ): ClassTiming[] => {
        const addMilliseconds = ':00.000';
      
        const formatTime = (time: string): string => {
          return time.length === 5 ? time + addMilliseconds : time;
        };
      
        const mapDayToTiming = (dayName: string, dayArray: DayTime[]): ClassTiming[] =>
          dayArray.map(day => ({
            day: dayName,
            start_time: formatTime(day.from),
            end_time: formatTime(day.to),
          }));
      
        return classDaysSelected
            .flatMap(num => {
                switch (num) {
                case 1:
                    return mapDayToTiming('Monday', newClassFormData.monday);
                case 2:
                    return mapDayToTiming('Tuesday', newClassFormData.tuesday);
                case 3:
                    return mapDayToTiming('Wednesday', newClassFormData.wednesday);
                case 4:
                    return mapDayToTiming('Thursday', newClassFormData.thursday);
                case 5:
                    return mapDayToTiming('Friday', newClassFormData.friday);
                case 6:
                    return mapDayToTiming('Saturday', newClassFormData.saturday);
                case 7:
                    return mapDayToTiming('Sunday', newClassFormData.sunday);
                default:
                    return undefined;
                }
            })
            .filter(Boolean) as ClassTiming[];
    };

    function findRemovedTimings(oldArray: ClassTiming[], newArray: ClassTiming[]): ClassTiming[] {
        return oldArray.filter(oldItem =>
            !newArray.some(newItem =>
                newItem.day === oldItem.day &&
                newItem.start_time === oldItem.start_time &&
                newItem.end_time === oldItem.end_time
            )
        );
    }

    const [impactedStudents, setImpactedStudents] = useState<StudentData[]>([]);
    const [isStudentsImpactedResolved, setIsStudentsImpactedResolved] = useState(true);
    const [isImpactedStudentsFetching, setIsImpactedStudentsFetching] = useState(false);

    const fetchImpactedStudents = async (classId: number) => {
        setIsImpactedStudentsFetching(true);
        try {
            const response = await ClassService.instance.getStudentsImpactedAfterTimingsChange(classId);
            const imapactedStudents = response.data;
            return imapactedStudents?.data ?? [];
        } catch (error) {
            console.error("Error fetching impacted students:", error);
        } finally {
            setIsImpactedStudentsFetching(false);
        }
    };

    // const findImpactedStudents = (students: StudentData[], removed: ClassTiming[], classId: number) => {
    //     return students
    //         .filter(student =>
    //             student.attributes.sessions.some(session =>
    //             session.class.data.id === classId &&
    //                 session.sessionTimings.some(timing =>
    //                     removed.some(remove =>
    //                         remove.day === timing.day &&
    //                         remove.start_time === timing.start_time &&
    //                         remove.end_time === timing.end_time
    //                     )
    //                 )
    //             )
    //         )
    // };

    const [groupedImpactedStudents, setGroupedImpactedStudents] = useState<Record<string, StudentData[]>>({});

    const [resolvedTimingEntries, setResolvedTimingEntries] = useState<Record<string, string>>({});

    const handleNewTimingSelection = (key: string, value: string) => {
        setResolvedTimingEntries(prev => ({ ...prev, [key]: value }));
    }

    type RecordType = Record<string, StudentData[]>;

    const findImpactedStudents = (
        students: StudentData[],
        removed: ClassTiming[],
        classId: number
      ): RecordType => {
        const result: RecordType = {};
      
        students.forEach(student => {
          student.attributes.sessions.forEach(session => {
            if (session.class.data.id === classId) {
              session.sessionTimings.forEach(timing => {
                const match = removed.find(
                  remove =>
                    remove.day === timing.day &&
                    remove.start_time === timing.start_time &&
                    remove.end_time === timing.end_time
                );
      
                if (match) {
                  const key = `${timing.day}-${timing.start_time}-${timing.end_time}`;
      
                  if (!result[key]) {
                    result[key] = [];
                  }
      
                  result[key].push(student);
                }
              });
            }
          });
        });
      
        return result;
    };

    const [currentClassTimings, setCurrentClassTimings] = useState<ClassTiming[]>([]);


    const handleAddNewClass = async (e: React.FormEvent) => {
        e.preventDefault();
        if (classDaysSelected.length > 0) {
            const postNewClassData = {
                users: [userState.user?.id ?? 0],
                academies: newClassFormData.academy,
                class_discipline: newClassFormData.discipline,
                class_type: newClassFormData.classType,
                class_name: newClassFormData.className,
                skill_level: newClassFormData.skillLevel,
                class_timings: generateClassTimings(classDaysSelected,newClassFormData),
            };

            if (isEditClassModalOpen && editingClassData) {
                const oldClassTimings = editingClassData.attributes?.class_timings ?? [];
                const currentClassTimings = postNewClassData.class_timings;
                setCurrentClassTimings(currentClassTimings);
                const removedTimingsOfClass = findRemovedTimings(oldClassTimings,currentClassTimings)

                if(removedTimingsOfClass.length > 0) {
                    const impactedStudents = await fetchImpactedStudents(editingClassData.id);
                    const impactedStudentsRecord = findImpactedStudents(impactedStudents ?? [], removedTimingsOfClass, editingClassData.id);

                    if(Object.keys(impactedStudentsRecord).length > 0) {
                        setIsStudentsImpactedResolved(false)
                        setGroupedImpactedStudents(impactedStudentsRecord);
                    }else{
                        editClass(postNewClassData);
                    }
                }else{
                    editClass(postNewClassData);
                }
            } else {
                addNewClass(postNewClassData);
            }
        } else {
            alert('Please add a class timing to proceed!');
        }
    };

    const generateUpdatedStudentsWithAvailableTimings = () => {
        const updatedStudents = [];

        for (const oldTiming in groupedImpactedStudents) {
            const newTiming = resolvedTimingEntries[oldTiming];
            const [newDay, newStartTime, newEndTime] = newTiming.split('-');

            const students = groupedImpactedStudents[oldTiming];

            for (const student of students) {
                const updatedSessions = student.attributes.sessions.map((session) => {
                    const updatedSessionTimings = session.sessionTimings.map((timing) => {
                        const oldTimingString = `${timing.day}-${timing.start_time}-${timing.end_time}`;
                        if (oldTimingString === oldTiming) {
                            return {
                                day: newDay,
                                start_time: newStartTime,
                                end_time: newEndTime,
                            };
                        }
                        return timing;
                    });

                    return { ...session, sessionTimings: updatedSessionTimings };
                });

                updatedStudents.push({ id: student.id, sessions: updatedSessions });
            }
        }

        return updatedStudents;
    }

    const updateStudentSessions = async (data: {id: number, sessions: StudentClassSessions[]}[]) => {
        try {
            const response = await StudentService.instance.updateStudentSessionTimings(data);
            if (response.success) {
                response.data;
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in updateStudentSessions: ${error}`);
            throw error;
        }
    };

    const {
        mutate: postStudentSessions,
        isLoading: isStudentSessionsUpdating,
    } = useMutation(updateStudentSessions, {
        onSuccess: data => {
            alert('lfgvgchgjbvjhkhbhjbbkn!');
        },
        onError: error => {
            alert('Failed to update students class timings! Please try again.');
        },
    });

    const handleResolveTimingConflictSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const reqBody = generateUpdatedStudentsWithAvailableTimings();
        postStudentSessions(reqBody);
    }

    useEffect(() => {
        if (newClassFormData.academy && disciplineData) {
            const data = disciplineData?.data?.data.filter(item =>
                item?.attributes?.academies?.data
                    ?.map(item => item?.id)
                    .includes(newClassFormData.academy)
            );
            setDisciplines(
                data?.map(item => ({ value: item?.id, label: item?.attributes?.name })) ?? []
            );
        }
    }, [newClassFormData.academy, disciplineData]);

    const onAddAcademyeClicked = () => {
        setIsAddAcademyModalOpen(true);
    };

    const handleAddAcademyeModalClose = () => {
        setIsAddAcademyModalOpen(false);
    };

    const mapDisciplineListToOptions = () => {
        if (disciplineData?.data) {
            return disciplineData?.data?.data.map(item => ({
                value: item.id,
                label: item.attributes.name,
            }));
        } else {
            return [];
        }
    };

    const handleAddAcademyFormInputChange = (label: string, value: string) => {
        if (label === 'selectedDisciplines') {
            SetAddAcademyFormValues(prevValues => ({
                ...prevValues,
                [label]: value.split(',').map(number => parseInt(number)),
            }));
        } else {
            SetAddAcademyFormValues(prevValues => ({
                ...prevValues,
                [label]: value,
            }));
        }
    };

    const handleAddBankName = () => {
        const trimmedBankAccName = addAcademyformValues.bankAccName.trim();
        if (trimmedBankAccName === '') {
            return;
        }
        SetAddAcademyFormValues(prev => ({
            ...prev,
            bankAccounts: [...(prev?.bankAccounts || []), trimmedBankAccName],
            bankAccName: '',
        }));
    };

    const deleteExistingBankAccount = (index: number) => {
        SetAddAcademyFormValues(prev => ({
            ...prev,
            bankAccounts: prev.bankAccounts.filter((_, i) => i !== index),
        }));
    };

    const postAddAcademy = async (data: AcademyPostData) => {
        try {
            const response = await AcademiesService.instance.postAcademy(data);

            if (response.success) {
                response.data;
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postAddAcademy: ${error}`);
            throw error;
        }
    };

    const {
        mutate: postAddAcademyData,
        isLoading: ispostAddAcademyPosting,
        error: ispostAddAcademyError,
    } = useMutation(postAddAcademy, {
        onSuccess: data => {
            queryClient.invalidateQueries(ACADEMY_QUERY);
            alert('Created Academy Successfully!');
            setIsAddAcademyModalOpen(false);
            SetAddAcademyFormValues(academyFormInitialState);
        },
        onError: error => {
            // console.log(`on  post Add Academy Error`, error);
            alert('Failed to create the academy! Please try again');
            // Error actions
        },
    });

    const updateAcademy = async (
        data: AcademyPostData
    ): Promise<AcademyDetailsWrapper | undefined> => {
        //console.log("edit id:" , editingAcademyData)
        const response = await AcademiesService.instance.updateAcademy(
            data,
            editingAcademyData?.id ?? 0
        );
        // console.log("update response:",response);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Edit Academy Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editAcademy,
        isLoading: isAcademyEditing,
        isSuccess: isAcademyEdited,
    } = useMutation(updateAcademy, {
        onSuccess: data => {
            alert('Updated Academy Successfully!');
            setIsEditAcademyModalOpen(false);
            SetAddAcademyFormValues(academyFormInitialState);
            queryClient.invalidateQueries(ACADEMY_QUERY);
        },
        onError: error => {
            // console.log(`On academy update Error:`, error);
            alert('Failed to update academy! Please try again');
        },
    });


    const handlerSubmitForm = (e: React.FormEvent) => {
        e.preventDefault();

        // const imageData = academy.attributes?.images?.data;
        // const uploadedImages = imageData ? imageData.map(item => item.attributes.url) : [];

        const data: AcademyPostData = {
            name: addAcademyformValues.adacemyName,
            address: addAcademyformValues.academyAddress,
            ...(addAcademyformValues.contactnumber !== '' && {
                contactNo: addAcademyformValues.contactnumber,
            }),
            ...(addAcademyformValues.emailid !== '' && { email: addAcademyformValues.emailid }),
            GSTNo: addAcademyformValues.GSTNo,
            bankAccounts: addAcademyformValues?.bankAccounts?.join(','),
            remarks: addAcademyformValues?.remarks,
            webpage: addAcademyformValues.webpage,
            images: imageid,
            users: [userState?.user?.id ?? 0],
            selectedDisciplines: addAcademyformValues.selectedDisciplines,
        };

        if (isEditAcademyModalOpen) {
            editAcademy(data);
        } else {
            postAddAcademyData(data);
        }
    };

    const handleNewFeeStructureModalClose = () => {
        setIsNewFeeStructureModalOpen(false);
    };

    const [selectedFeeStructure, setSelectedFeeStructure] = useState<number>(-1);

    const expandFeeStructureCard = (id: number) => {
        if (id === selectedFeeStructure) {
            setSelectedFeeStructure(-1);
        } else {
            setSelectedFeeStructure(id);
        }
    };



    return {
        isLoading:
            isAcademyEditing || ispostAddAcademyPosting || isClassEditing || isNewClassAdding || isStudentSessionsUpdating,
        isModalVisible: state.isModalVisible,
        state,
        dispatch,
        isAcademiesFetchSuccess,
        isDisciplineFetchSuccess,
        academiesData,
        classes: classesData?.data?.data,
        isClassFetchSuccess,
        handleNewClassModalOpen,
        academies: academiesData?.data?.data,
        selectedAcademy: state.selectedAcademy,
        selectedClass: state.selectedClass,
        total: academiesData?.data?.data?.length ?? 0,
        showModal: () => dispatch({ type: 'showModal' }),
        hideModal: () => dispatch({ type: 'hideModal' }),
        handleItemClick,
        setOpenTab,
        handleItemExpand,
        isNewClassModalOpen,
        handleNewClassModalClose,
        classDaysSelected,
        handleDaySelect,
        handleInputChange,
        newClassFormData,
        handleAddNewClass,
        disciplines,
        isAddAcademyModalOpen,
        setIsAddAcademyModalOpen,
        onAddAcademyeClicked,
        handleFileChange,
        uploadedImageData,
        handleDeleteImage,
        handleAddAcademyeModalClose,
        handleAddAcademyFormInputChange,
        // handleEditAcademyFormInputChange,
        addAcademyformValues,
        handlerSubmitForm,
        // mapdisciplinestoOptions,
        mapDisciplineListToOptions,
        isEditClassModalOpen,
        handleEditClassModalClose,
        handleEditClassModalOpen,
        isEditAcademyModalOpen,
        handleEditAcademyModalOpen,
        handleEditAcademyModalClose,
        //addAcademyFormData,
        // handleEditAcademy,
        // isUploading,
        // setIsUploading,
        isAcademyImagePosting,
        handleAddBankName,
        deleteExistingBankAccount,
        userState,
        handleTimeAddForDay,
        handleTimingsInputChange,
        handleTimeDeleteForDay,
        isNewFeeStructureModalOpen,
        handleNewFeeStuctureCreation,
        handleNewFeeStructureModalClose,
        isFeeStructureListFetching,
        feeStructuresData: feeStructuresData?.data?.data ?? [],
        expandFeeStructureCard,
        selectedFeeStructure,
        isStudentsImpactedResolved,
        isImpactedStudentsFetching,
        groupedImpactedStudents,
        currentClassTimings,
        resolvedTimingEntries,
        handleResolveTimingConflictSubmit,
        handleNewTimingSelection,
    };
};

export default AcademiesViewModel;
