import { EnquiryData } from '../models/enquiry/enquiry-data';
import { EnquiryFollowupEmailAttributes } from '../models/enquiry/enquiry-followup-email-data';
import { EnquiryListWrapper } from '../models/enquiry/enquiry-list-wrapper';
import { EnquiryPostDataWrapper } from '../models/enquiry/enquiry-post-data-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';
import EnquiryFollowupWhatsappMessageData from '../utils/types/enquiry-followup-whatsapp-message-data';

export class EnquiryService {
    private static _instance: EnquiryService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new EnquiryService());
    }

    public getEnquiriesList = async (
        supplierId: number
    ): Promise<ApiResponse<EnquiryListWrapper>> => {
        return invokeApi<null, EnquiryListWrapper>({
            route: `enquiries?populate[0]=remarks.enquiredBy&populate[1]=academies&populate[2]=classesInterested&populate[3]=addedBy&populate[4]=users&filters[users][id]=${supplierId}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postNewEnquiry = async (data: EnquiryPostDataWrapper) => {
        return invokeApi<EnquiryPostDataWrapper, EnquiryData>({
            route: `enquiries`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public putEnquiry = async (data: EnquiryPostDataWrapper, id: number) => {
        return invokeApi<EnquiryPostDataWrapper, EnquiryData>({
            route: `enquiries/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public updateEnquiry = async (data: EnquiryPostDataWrapper, id: number) => {
        return invokeApi<EnquiryPostDataWrapper, EnquiryData>({
            route: `enquiries/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: data,
        });
    };

    public postEnquiryFollowupWhatsappMessage = async (
        data: EnquiryFollowupWhatsappMessageData
    ) => {
        return invokeApi<EnquiryFollowupWhatsappMessageData, null>({
            route: `whatsapp/send-enquiry-followup-message`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };

    public postEnquiryFollowupEmail = async (data: EnquiryFollowupEmailAttributes) => {
        return invokeApi<EnquiryFollowupEmailAttributes, string>({
            route: `enquiry-followup-email`,
            method: 'POST',
            privateRoute: true,
            data: data,
        });
    };
}
