import { useImmerReducer } from 'use-immer';
import { ProgressTrackerState, progressTrackerReducer } from '../reducers/progress-tracker-reducer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UploadService } from '../../services/upload-service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgressTrackerService } from '../../services/progress-tracker-service';
import { AssignmentTechniqueRequestPostData } from '../../models/progress-tracker/assignment-technique-request-post-data';
import { AssignmentTechniqueRequestDataWrapper } from '../../models/progress-tracker/assignemnt-technique-request-data-wrapper';
import {
    ACADEMY_QUERY,
    ASSIGNMENT_TECHNIQUE_REQUEST_QUERY,
    FITNESS_TEST_CONDUCTED_LIST_QUERY,
    FITNESS_TEST_DETAILS_LIST_QUERY,
    REGULAR_ASSIGNMENTS_LIST_QUERY,
    SCHEDULE_QUERY,
    STUDENT_DATA_QUERY,
    STUDENT_QUERY,
} from '../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import ClassService from '../../services/class-service';
import { ClassData } from '../../models/class/class-data';
import { RegularAssignmentPostData } from '../../models/progress-tracker/regular-assignment-post-data';
import { RegularAssignmentDataWrapper } from '../../models/progress-tracker/regular-assignment-data-wrapper';
import { AcademiesService } from '../../services/academies-service';
import { AcademyData } from '../../models/academy/academy-data';
import { StudentService } from '../../services/student-service';
import { StudentListWrapper } from '../../models/student/student-list-wrapper';
import axios from 'axios';
import { getJWTToken } from '../../utils/helpers/helpers';
import { StudentData } from '../../models/student/student-data';
import { AnnouncementService } from '../../services/announcement-service';
import { FitnessTestDetailsData } from '../../models/progress-tracker/fitness-test-details-data';
import { FitnessTestConductedData } from '../../models/progress-tracker/fitness-test-conducted-data';

export interface KeyPointDataType {
    timestamp: number;
    join1: number;
    join2: number;
    weightage: number;
}

export interface RegularAssignmentFormDataType {
    name: string;
    description: string;
    fileUrl: string;
    fileId: number | null;
    classes: number[];
    students: number[];
    assignTo: 'all' | 'classes' | 'students' | 'none';
    type: string;
    allowLateSubmission: boolean;
    submissionDeadline: string;
    submissionType: string;
}

interface Academy {
    id: number;
    attributes: {
        name: string;
        address: string;
        contactNo: string;
        email: string;
        webpage: string;
        disciplines: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        GSTNo: string | null;
        bankAccounts: string | null;
        remarks: string | null;
        toBeFeatured: boolean;
    };
}

interface Certification {
    id: number;
    attributes: {
        Name: string;
        level: number;
        Remarks: string | null;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        academy: {
            data: Academy;
        };
    };
}

const ProgressTrackerViewModel = () => {
    const initialState: ProgressTrackerState = {
        openTab: 1,
        currentPage: 1,
    };
    const KeyPointInitialState = {
        timestamp: 0,
        join1: -1,
        join2: -1,
        weightage: -1,
    };
    const [academySelected, setAcademySelected] = useState<null | number>(null);
    const [state, dispatch] = useImmerReducer(progressTrackerReducer, initialState);
    const [AIModules, setAIModules] = useState<boolean>(false);
    const [regularAssignment, setRegularAssignment] = useState<boolean>(false);
    const [addRegularAssignment, setAddRegularAssignment] = useState<boolean>(false);
    const [addAIAssignment, setAddAIAssignment] = useState<boolean>(false);
    const [addNewTechnique, setAddNewTechnique] = useState<boolean>(false);
    const [isView, setIsView] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const userState = useSelector((state: RootState) => state.user);

    const [techniqueName, setTechniqueName] = useState('');
    const [timestamp, setTimestamp] = useState<number | null>(null);
    const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
    const [file, setFile] = useState<File | null>(null);
    const [join1, setJoin1] = useState('');
    const [join2, setJoin2] = useState('');
    const [weightage, setWeightage] = useState('');
    const [rows, setRows] = useState<KeyPointDataType[]>([]);
    const [data, setData] = useState<KeyPointDataType[]>([]);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [url, setUrl] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rowToEdit, setRowtoEdit] = useState<number | null>(null);
    const queryClient = useQueryClient();
    const [selectDiscipline, setSelectDiscipline] = useState<number | null | string>(null);
    const [addedKeyPointsData, setAddedKeyPointsData] = useState<KeyPointDataType[]>([]);
    const [filteredKeyPointsData, setFilteredKeyPointsData] = useState<KeyPointDataType[]>([]);
    const [joinOneValue, setJoinOneValue] = useState<number>();
    const [joinTwoValue, setJoinTwoValue] = useState<number>();
    const [weightageValue, setWeightageValue] = useState<number>();
    const [selectedClassesId, setSelectedClassesId] = useState<number[]>([]);
    const [selectedAcademyId, setSelectedAcademyId] = useState<number[]>([]);
    const [selectedTechniqueId, setSelectedTechniqueId] = useState<number[]>([]);
    const [videoId, setVideoId] = useState<number>();
    const [selectedTechniqueToView, setSelectedTechniqueToView] = useState<number>(0);
    const [isRegularAssignmentEditing, setIsRegularAssignmentEditing] = useState<boolean>(false);
    const [isTechniqueEditing, setIsTechniqueEditing] = useState<boolean>(false);
    const [isEditKeyPointModalOpen, setIsEditKeyPointModalOpen] = useState<boolean>(false);
    const [editingKeyPointDataIndex, setEditingKeyPointDataIndex] = useState<number>(-1);
    const [editingKeyPointData, setEditingKeyPointData] =  useState<KeyPointDataType>(KeyPointInitialState);
    const [isRegularAssignmentViewing, setIsRegularAssignmentViewing] = useState<boolean>(false);

    const [certPgSec, setCertPgSec] = useState<string>('Init');

    const { isSuccess: isTechniquesFetchSuccess, data: techniquesData } = useQuery({
        queryKey: [ASSIGNMENT_TECHNIQUE_REQUEST_QUERY],
        queryFn: () =>
            ProgressTrackerService.instance.getAssignmentTechniquesList(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    // console.log({techniquesData:techniquesData})

    const { isSuccess: isRegularAssignmentsFetchSuccess, data: regularAssignments } = useQuery({
        queryKey: [REGULAR_ASSIGNMENTS_LIST_QUERY],
        queryFn: () => ProgressTrackerService.instance.getRegularAssignmentsList(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { data: classesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data?.map((item: ClassData) => ({
                value: item.id,
                label: item?.attributes?.class_name,
            }));
        }
        return [];
    };

    const mapStudentToOptions = (): { value: number; label: string }[] | [] => {
        if (studentsData) {
            return studentsData?.map((item: StudentData) => ({
                value: item.id,
                label: item?.attributes.firstName,
            }));
        }
        return [];
    };

    const mapAcademyToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map((item: AcademyData) => ({
                value: item.id,
                label: item?.attributes?.name,
            }));
        }
        return [];
    };

    const mapTechniqueToOptions = (): { value: number; label: string }[] => {
        // Check if techniquesData and its nested properties are defined and arrays
        if (
            techniquesData &&
            Array.isArray(techniquesData.data?.data) &&
            Array.isArray(academiesData?.data?.data)
        ) {
            // Extract IDs from academiesData
            const academyIds = academiesData?.data?.data.map(i => i.id);

            // Safely access techniquesData.data.data
            const techniques = techniquesData?.data?.data;

            // Filter techniquesData based on academyIds and map to options
            if (techniques && academyIds) {
                return techniques
                    .filter(item => academyIds.includes(item.id))
                    .map((item: AssignmentTechniqueRequestDataWrapper) => ({
                        value: item.id,
                        label: item?.attributes?.name || 'Unknown', // Handle possible undefined name
                    }));
            }
        }
        return [];
    };

    // useEffect(() => {
    //     if (isTechniqueEditing && selectedTechniqueToView) {
    //         const data = techniquesData?.data?.data?.filter(
    //             item => item?.id === selectedTechniqueToView,
    //         )[0]?.attributes?.academies?.data.map((item: AcademyData) => item.id) || [];
    //         setSelectedAcademyId(data);
    //     }
    // }, [isTechniqueEditing, selectedTechniqueToView]);

    // console.log({mapTechniqueToOptions:mapTechniqueToOptions()})

    const setOpenTab = useCallback(
        (openTab: number) => {
            dispatch({
                type: 'setOpenTab',
                payload: openTab,
            });
            setAIModules(false);
            setRegularAssignment(false);
            setAddNewTechnique(false);
            setSelectedTechniqueToView(0);
            setIsEdit(false);
            setAddRegularAssignment(false);
            setRegularAssignment(false);
            setIsRegularAssignmentViewing(false);
            setIsRegularAssignmentEditing(false);
            resetAll();
        },
        [state.openTab]
    );

    const resetAll = () => {
        setAddedKeyPointsData([]);
        setSelectedClassesId([]);
        setSelectedAcademyId([]);
        setSelectedTechniqueId([]);
        setTechniqueName('');
        setVideoId(undefined);
        setUrl('');
        setJoinOneValue(undefined);
        setJoinTwoValue(undefined);
        setWeightageValue(undefined);
        setTimestamp(null);
        setCurrentTimestamp(0);
        setAddAIAssignment(false);
        setCertPgSec('Init');
        setAcademySelected(null);
        setSelectDiscipline(null);
    };

    const handleTechniqueViewClick = (id: number) => {
        setSelectedTechniqueToView(id);
        setIsTechniqueEditing(false);

        const selectedTechnique = techniquesData?.data?.data?.find(item => item?.id === id);

        if (selectedTechnique) {
            // Extract keyFrames data and update the state
            const keyFrames = selectedTechnique.attributes.keyFrames || [];
            keyFrames.forEach(frame => {
                frame.data.forEach(item => {
                    setAddedKeyPointsData(prev => [
                        ...prev,
                        {
                            timestamp: parseInt(frame.timeStamp) || 0,
                            join1: item.join1 || 0,
                            join2: item.join2 || 0,
                            weightage: item.weightage || 0,
                        },
                    ]);
                });
            });

            // Extract academies data and update the state
            const academies = selectedTechnique.attributes.academies.data || [];
            academies.forEach(item => {
                setSelectedAcademyId(prev => [...prev, item.id]);
            });
        }
    };
    const handleAIModuleClick = () => {
        setAIModules(true);
    };

    const handleHomeWorkAssignmentClick = () => {
        setRegularAssignment(true);
    };

    const handleHomeassingmentclick = () => {
        setRegularAssignment(false);
        setAddRegularAssignment(true);
    };
    const handleAiHomeassingmentclick = () => {
        setRegularAssignment(false);
        setAddAIAssignment(true);
    };

    const handleAddNewTechniqueClick = () => {
        setAddNewTechnique(true);
    };
    const handleEditClick = () => {
        setIsEdit(true);
    };

    useEffect(() => {
        const sortedData = [...addedKeyPointsData].sort((a, b) => a?.timestamp - b?.timestamp);
        setFilteredKeyPointsData(sortedData);
    }, [addedKeyPointsData]);

    const handleInputChange = (value: number, field: string) => {
        switch (field) {
            case 'join1':
                setJoinOneValue(value);
                break;
            case 'join2':
                setJoinTwoValue(value);
                break;
            case 'weightage':
                setWeightageValue(value);
                break;
            default:
                break;
        }
    };

    const handleEditKeyPointModalInputChange = (value: number, field: string) => {
        setEditingKeyPointData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleEditTechniqueBtnClick = (data: AssignmentTechniqueRequestDataWrapper | null) => {
        if (data != null) {
            setTechniqueName(data?.attributes?.name);
            setSelectedAcademyId(data?.attributes?.academies?.data?.map(item => item.id));
            setIsTechniqueEditing(true);
        } else {
            alert('Something went wrong! Please try again.');
        }
    };

    const postNewTechniqueRequest = async (
        data: AssignmentTechniqueRequestPostData
    ): Promise<AssignmentTechniqueRequestDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.postNewAssignmentTechnique(data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewTechniqueRequest,
        isLoading: isNewTechniqueAdding,
        isSuccess: isNewTechniqueAdded,
    } = useMutation(postNewTechniqueRequest, {
        onSuccess: data => {
            alert('Added Technique Successfully!');
            setVideoId(undefined);
            setUrl('');
            dispatch({
                type: 'setCurrentPage',
                payload: 1,
            });
            setOpenTab(2);
            setAddedKeyPointsData([]);
            // queryClient.invalidateQueries(CLASS_DETAILS_QUERY)
        },
        onError: error => {
            // console.log(`On class add Error:`, error);
            alert('Failed to create technique! Please try again');
        },
    });

    const handleAddNewTechniqueSubmit = () => {
        const groupedData: { [timestamp: string]: KeyPointDataType[] } =
            filteredKeyPointsData.reduce(
                (acc: { [timestamp: string]: KeyPointDataType[] }, item) => {
                    const timestamp = item.timestamp.toFixed(6);
                    if (!acc[timestamp]) {
                        acc[timestamp] = [];
                    }
                    acc[timestamp].push({
                        join1: item.join1,
                        join2: item.join2,
                        weightage: item.weightage,
                        timestamp: item.timestamp, // Add timestamp here if it's needed in the transformed object
                    });
                    return acc;
                },
                {}
            );

        const keyFrames = Object.keys(groupedData).map(timestamp => ({
            timeStamp: timestamp,
            data: groupedData[timestamp],
        }));

        const reqBody = {
            name: techniqueName,
            video: videoId ?? 0,
            classes: selectedClassesId,
            academies: selectedAcademyId,
            keyFrames: keyFrames,
            users: [userState?.user?.id ?? 0],
        };

        addNewTechniqueRequest(reqBody);
    };

    const putNewTechniqueRequest = async (
        data: AssignmentTechniqueRequestPostData
    ): Promise<AssignmentTechniqueRequestDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.putNewAssignmentTechnique(
            data,
            selectedTechniqueToView
        );
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateTechniqueRequest,
        isLoading: isTechniqueAUpdating,
        isSuccess: isTechniqueUpdated,
    } = useMutation(putNewTechniqueRequest, {
        onSuccess: data => {
            alert('Updated Technique Successfully!');
            setVideoId(undefined);
            setSelectedClassesId([]);
            setTechniqueName('');
            setSelectedTechniqueToView(0);
            setUrl('');
            dispatch({
                type: 'setCurrentPage',
                payload: 1,
            });
            setOpenTab(2);
            setAddedKeyPointsData([]);
            queryClient.invalidateQueries(ASSIGNMENT_TECHNIQUE_REQUEST_QUERY);
        },
        onError: error => {
            // console.log(`On class add Error:`, error);
            alert('Failed to update technique! Please try again');
        },
    });

    const handleEditTechniqueSubmit = () => {
        console.log(selectedAcademyId);
        const reqBody = {
            classes: selectedClassesId,
            academies: selectedAcademyId,
            name: techniqueName,
        };
        updateTechniqueRequest(reqBody);
    };

    const deleteNewTechniqueRequest = async (): Promise<
        AssignmentTechniqueRequestDataWrapper | undefined
    > => {
        const response =
            await ProgressTrackerService.instance.deleteAssignmentTechniquesList(
                selectedTechniqueToView
            );
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: deleteTechniqueRequest,
        isLoading: isTechniqueADeleting,
        isSuccess: isTechniqueDeleted,
    } = useMutation(deleteNewTechniqueRequest, {
        onSuccess: data => {
            alert('Deleted Technique Successfully!');
            setVideoId(undefined);
            setSelectedClassesId([]);
            setTechniqueName('');
            setSelectedTechniqueToView(0);
            setUrl('');
            dispatch({
                type: 'setCurrentPage',
                payload: 1,
            });
            setOpenTab(2);
            setAddedKeyPointsData([]);
            queryClient.invalidateQueries(ASSIGNMENT_TECHNIQUE_REQUEST_QUERY);
        },
        onError: error => {
            // console.log(`On class add Error:`, error);
            alert('Failed to delete technique! Please try again');
        },
    });

    const handleDeleteTechniqueBtnClick = () => {
        const result = window.confirm('Are you sure you want to delete this Technique?');
        if (result) {
            deleteTechniqueRequest();
        } else {
            return;
        }
    };

    const handleAddTimeStamp = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const currentTime = videoElement.currentTime;
            setTimestamp(currentTime);
        }
    };

    const handleClassInputChange = (value: number[]) => {
        setSelectedClassesId(value);
    };
    // useEffect(() => {
    // //   console.log(selectedAcademyId)
    //   console.log({test:mapAcademyToOptions().filter(option =>
    //     selectedAcademyId.includes(option.value),
    // )})
    // }, [selectedAcademyId])

    const handleAcademyInputChange = (value: number[]) => {
        setSelectedAcademyId(value);
    };

    const handleTechniqueInputChange = (value: number[]) => {
        setSelectedTechniqueId(value);
    };

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleTimeUpdate = () => {
            if (videoElement) {
                setCurrentTimestamp(videoElement.currentTime);
            }
        };

        if (videoElement) {
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }

        // Cleanup event listener on component unmount
        return () => {
            if (videoElement) {
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [videoId]);

    const postNewTechniqueVideo = async (data: FormData) => {
        try {
            console.log('Upload Started.');
            // alert("Upload started. Please wait!")
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setUrl(item.url);
                            setVideoId(item.id);
                            setRegularAssignmentFormData(prev => {
                                return {
                                    ...prev,
                                    fileId: item.id,
                                    fileUrl: item.url,
                                };
                            });
                        }
                    });
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postNewTechniqueVideo: ${error}`);
            throw error;
        }
    };

    const { mutate: UploadNewTechniqueVideo, isLoading: isFileUploading } = useMutation(postNewTechniqueVideo, {
        onSuccess: data => {
            // console.log('Video upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! Please try again.');
        },
    });

    const handleNewTechniqueVideoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadNewTechniqueVideo(data);
            setFile(selectedFiles[0]);
        }
    };

    const handleEditRow = (index: number) => {
        setRowtoEdit(index);
        setIsModalOpen(true);
    };

    const handleDeleteRow = (index: number) => {
        setData(data.filter((row, i) => i !== index));
        // console.log(data);
    };

    const handleTechniqueNameChange = (name: string) => {
        setTechniqueName(name);
    };

    const handleEditAddedKeyPointData = (index: number) => {
        setEditingKeyPointData({
            timestamp: filteredKeyPointsData[index].timestamp,
            join1: filteredKeyPointsData[index].join1,
            join2: filteredKeyPointsData[index].join2,
            weightage: filteredKeyPointsData[index].weightage,
        });
        setIsEditKeyPointModalOpen(true);
        setEditingKeyPointDataIndex(index);
    };

    const handleEditKeyPointModalClose = () => {
        setIsEditKeyPointModalOpen(false);
    };

    const handleSaveKeyPointUpdatedData = () => {
        setAddedKeyPointsData(prev => {
            const newData = [...prev];
            const sortedData = newData.sort((a, b) => a?.timestamp - b?.timestamp);
            sortedData[editingKeyPointDataIndex] = editingKeyPointData;

            return sortedData;
        });
        setIsEditKeyPointModalOpen(false);
        setEditingKeyPointDataIndex(-1);
    };

    const handleDeleteKeyPointUpdatedData = () => {
        setAddedKeyPointsData(prev => {
            const sortedData = prev
                .sort((a, b) => a?.timestamp - b?.timestamp)
                .filter((_, i) => i !== editingKeyPointDataIndex);
            return sortedData;
        });
        setIsEditKeyPointModalOpen(false);
        setEditingKeyPointDataIndex(-1);
    };

    const [isJoinOneSearchInputOnFocus, setIsJoinOneSearchInputOnFocus] = useState<boolean>(false);
    const [isJoinTwoSearchInputOnFocus, setIsJoinTwoSearchInputOnFocus] = useState<boolean>(false);

    const [joinOneSearchTerm, setJoinOneSearchTerm] = useState<string>('');
    const [joinTwoSearchTerm, setJoinTwoSearchTerm] = useState<string>('');

    const handleRemoveUploadedVideo = () => {
        setUrl('');
        setVideoId(undefined);
        setAddedKeyPointsData([]);
        setJoinOneValue(undefined);
        setJoinTwoValue(undefined);
        setWeightageValue(undefined);
        setTimestamp(null);
        setCurrentTimestamp(0);
    };

    useEffect(() => {
        setAddedKeyPointsData([]);
        setJoinOneValue(undefined);
        setJoinTwoValue(undefined);
        setWeightageValue(undefined);
        setTimestamp(null);
        setCurrentTimestamp(0);
    }, [videoId]);

    const handleAddKeyPointData = () => {
        setAddedKeyPointsData(prev => [
            ...prev,
            {
                timestamp: timestamp ?? 0,
                join1: joinOneValue ?? 0,
                join2: joinTwoValue ?? 0,
                weightage: weightageValue ?? 0,
            },
        ]);
        setJoinOneValue(undefined);
        setJoinTwoValue(undefined);
        setWeightageValue(undefined);
        setJoinOneSearchTerm('');
        setJoinTwoSearchTerm('');
    };

    const handleCancelTechniqueCreation = () => {
        setAddNewTechnique(false);
        resetAll();
    };

    const postNewRegularAssignment = async (
        data: RegularAssignmentPostData
    ): Promise<RegularAssignmentDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.postNewRegularAssignment(data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewRegularAssignment,
        isLoading: isRegularAssignmentAdding,
        isSuccess: isRegularAssignmentAdded,
    } = useMutation(postNewRegularAssignment, {
        onSuccess: data => {
            alert('Added Assignment Successfully!');
            setRegularAssignmentFormData(initialRegularAssignmentFormData);
            dispatch({
                type: 'setCurrentPage',
                payload: 1,
            });
            queryClient.invalidateQueries(REGULAR_ASSIGNMENTS_LIST_QUERY);
            setOpenTab(2);
        },
        onError: error => {
            alert('Failed to create assignment! Please try again');
        },
    });

    const initialRegularAssignmentFormData: RegularAssignmentFormDataType = {
        name: '',
        description: '',
        fileUrl: '',
        fileId: null,
        classes: [],
        students: [],
        type: '',
        assignTo: 'none',
        allowLateSubmission: false,
        submissionDeadline: '',
        submissionType: '',
    };

    const [regularAssignmentFormData, setRegularAssignmentFormData] = useState<RegularAssignmentFormDataType>(initialRegularAssignmentFormData);

    const handleRegularAssignmentInputChange = (field: string, value: string | number[] | boolean) => {
        setRegularAssignmentFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleAddRegularAssignment = () => {
        if(regularAssignmentFormData.assignTo === 'all') {
            addNewRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forAll: userState?.user?.id ?? 0,
                forClasses: null,
                forStudents: null,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }else if(regularAssignmentFormData.assignTo === 'students') {
            addNewRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forClasses: null,
                forAll: null,
                forStudents: regularAssignmentFormData.students,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }else if(regularAssignmentFormData.assignTo === 'classes') {
            addNewRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forClasses: regularAssignmentFormData.classes,
                forAll: null,
                forStudents: null,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }
    };

    const [selectedRegularAssignmentToView, setSelectedRegularAssignmentToView] = useState<RegularAssignmentDataWrapper>();

    const handleCancelRegularAssignmentCreation = () => {
        setAddRegularAssignment(false);
    };


    const handleRegularAssignmentViewClick = (id: number) => {
        setRegularAssignment(false);
        setIsRegularAssignmentViewing(true);
        const data = regularAssignments?.data?.data.filter(item => item.id === id);
        
        if (data && data?.length > 0) {
            setSelectedRegularAssignmentToView(data[0]);
            setRegularAssignmentFormData({
                name: data[0].attributes?.name,
                description: data[0].attributes?.description,
                fileUrl: data[0].attributes?.file?.data?.attributes?.url,
                fileId: data[0].attributes?.file?.data?.id,
                classes: data[0].attributes?.forClasses?.data?.map(classItem => classItem.id),
                students: data[0].attributes?.forStudents?.data?.map(studentItem => studentItem.id),
                type: data[0].attributes?.type,
                assignTo: data[0].attributes?.forAll?.data !== null ? 'all' : data[0].attributes?.forClasses?.data?.length > 0 ? 'classes' : 'students',
                allowLateSubmission: data[0].attributes?.acceptSubmission,
                submissionDeadline: data[0].attributes?.submissionDeadline,
                submissionType: data[0].attributes?.submissionFileType,
            });
        }
    };

    const putRegularAssignmentt = async (
        data: RegularAssignmentPostData
    ): Promise<RegularAssignmentDataWrapper | undefined> => {
        const response = await ProgressTrackerService.instance.putRegularAssignment(
            data,
            selectedRegularAssignmentToView?.id ?? 0
        );
        if (response.success) {
            return response?.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateRegularAssignment,
        isLoading: isRegularAssignmentUpdating,
        isSuccess: isRegularAssignmentUpdated,
    } = useMutation(putRegularAssignmentt, {
        onSuccess: data => {
            alert('Updated Technique Successfully!');
            setIsRegularAssignmentViewing(false);
            setIsRegularAssignmentEditing(false);
            setRegularAssignmentFormData(initialRegularAssignmentFormData);
            queryClient.invalidateQueries(REGULAR_ASSIGNMENTS_LIST_QUERY);
        },
        onError: error => {
            // console.log(`On class add Error:`, error);
            alert('Failed to update technique! Please try again');
        },
    });

    const handleUpdateRegularAssignment = () => {
        if(regularAssignmentFormData.assignTo === 'all') {
            updateRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forAll: userState?.user?.id ?? 0,
                forClasses: null,
                forStudents: null,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }else if(regularAssignmentFormData.assignTo === 'students') {
            updateRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forClasses: null,
                forAll: null,
                forStudents: regularAssignmentFormData.students,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }else if(regularAssignmentFormData.assignTo === 'classes') {
            updateRegularAssignment({
                name: regularAssignmentFormData.name,
                description: regularAssignmentFormData.description,
                forClasses: regularAssignmentFormData.classes,
                forAll: null,
                forStudents: null,
                users: [userState?.user?.id ?? 0],
                file: regularAssignmentFormData.fileId,
                acceptSubmission: regularAssignmentFormData.allowLateSubmission,
                submissionDeadline: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionDeadline : null,
                submissionFileType: regularAssignmentFormData.allowLateSubmission ? regularAssignmentFormData.submissionType : null,
            });
        }
    };

    const handleRegularAssignmentFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadNewTechniqueVideo(data);
        }
    };

    const handleRegularAssignmentEditClick = () => {
        setIsRegularAssignmentEditing(true);
    };

    const handleCancelRegularAssignmentUpdate = () => {
        setIsRegularAssignmentEditing(false);
        setIsRegularAssignmentViewing(false);
    };

    const handleDeleteRegularAssignmentsClick = () => {
        console.log('delete');
    };

    const [studentQuery, setstudentQuery] = useState('');

    const {
        isSuccess: isStudentListFetchSuccess,
        isLoading: isStudentListLoading,
        data: studentData,
    } = useQuery({
        queryKey: [STUDENT_DATA_QUERY],
        queryFn: async () => {
            try {
                const response = await AnnouncementService.instance.getStudentList(
                    userState.user?.id ?? 0
                );
                return response;
            } catch (error) {
                console.error('Error fetching student suggestion data: ', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const [studentsData, setstudentsData] = useState<StudentData[] | null>(null);

    const fetchStudents = async (query: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/students`, {
                params: {
                    populate: 'photo',
                    filters: {
                        users: { id: userState?.user?.id ?? 0 },
                        $or: [
                            { firstName: { $contains: query } },
                            { lastName: { $contains: query } },
                            { middleName: { $contains: query } },
                        ],
                    },
                },
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            });
            if (response.data) {
                setstudentsData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    useEffect(() => {
        fetchStudents(studentQuery);
    }, [studentQuery]);

    const mapStudentsToOptions = (): { value: number; label: string }[] | [] => {
        if (studentData?.data?.data) {
            return studentData?.data?.data?.map((item: StudentData) => ({
                value: item.id,
                label:
                    item?.attributes?.firstName +
                    ' ' +
                    (item?.attributes?.middleName || '') +
                    ' ' +
                    (item?.attributes?.lastName || ''),
            }));
        }
        return [];
    };

    const [isAddFitnessTestModalOpen, setIsAddFitnessTestModalOpen] = useState(false);

    const handleFitnessTestModalOpen = () => {
        setIsAddFitnessTestModalOpen(true);
    }

    const handleAddFitnessTestFormClose = () => {
        setIsAddFitnessTestModalOpen(false);
    }

    const [isAllFitnessTestsListOpen, setIsAllFitnessTestsListOpen] = useState(false);

    const handleToggleFitnessTestTemplatesListView = (value: boolean) => {
        setIsAllFitnessTestsListOpen(value);
    }

    const { isFetching: isFitnessTestTemplatesFetching, data: fitnessTestTemplates } = useQuery({
        queryKey: [FITNESS_TEST_DETAILS_LIST_QUERY],
        queryFn: () => ProgressTrackerService.instance.getFitnessTestDetailsList(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isFetching: isFitnessTestsConductedFetching, data: fitnessTestsConducted } = useQuery({
        queryKey: [FITNESS_TEST_CONDUCTED_LIST_QUERY],
        queryFn: () => ProgressTrackerService.instance.getFitnessTestConductedList(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const [isTakeFitnessTestModalOpen, setIsTakeFitnessTestModalOpen] = useState(false);
    const [selectedFitnessTest, setSelectedFitnessTest] = useState<FitnessTestDetailsData | null>(null);

    const handleTakeFitnessTestModalOpen = (data: FitnessTestDetailsData) => {
        setIsAllFitnessTestsListOpen(false);
        setIsTakeFitnessTestModalOpen(true);
        setSelectedFitnessTest(data);
    }

    const handleTakeFitnessTestModalClose = () => {
        setIsTakeFitnessTestModalOpen(false);
        setSelectedFitnessTest(null);
    }

    const [isRecordFitnessTestModalOpen, setIsRecordFitnessTestModalOpen] = useState(false);
    const [selectedRecordedFitnessTest, setSelectedRecordedFitnessTest] = useState<FitnessTestConductedData | null>(null);

    const handleRecordFitnessTestModalOpen = (data: FitnessTestConductedData) => {
        setIsRecordFitnessTestModalOpen(true);
        setSelectedRecordedFitnessTest(data);
    }

    const handleRecordFitnessTestModalClose = () => {
        setIsRecordFitnessTestModalOpen(false);
        setSelectedRecordedFitnessTest(null);
    }

    const [activeFitnessTestListFilter, setActiveFitnessTestListFilter] = useState<'ALL' | 'COMPLETED' | 'INCOMPLETE'>('ALL');

    const handleActiveFitnessTestListFilterChange = (value: 'ALL' | 'COMPLETED' | 'INCOMPLETE') => {
        setActiveFitnessTestListFilter(value);
    }

    return {
        isLoading:
            isRegularAssignmentAdding ||
            isTechniqueAUpdating ||
            isNewTechniqueAdding ||
            isFitnessTestsConductedFetching ||
            isFileUploading || 
            isFitnessTestTemplatesFetching,
        state,
        handleRegularAssignmentEditClick,
        handleDeleteRegularAssignmentsClick,
        setOpenTab,
        handleRegularAssignmentFileUpload,
        dispatch,
        currentPage: state.currentPage,
        AIModules,
        isView,
        isEdit,
        regularAssignment,
        addNewTechnique,
        techniqueName,
        timestamp,
        file,
        join1,
        join2,
        weightage,
        rows,
        data,
        videoRef,
        url,
        isModalOpen,
        rowToEdit,
        setRows,
        setJoin1,
        setJoin2,
        setWeightage,
        setIsModalOpen,
        setRowtoEdit,
        handleAIModuleClick,
        handleHomeWorkAssignmentClick,
        handleAddNewTechniqueClick,
        handleTechniqueViewClick,
        handleEditClick,
        handleAddTimeStamp,
        handleDeleteRow,
        handleEditRow,
        handleTechniqueNameChange,
        handleNewTechniqueVideoUpload,
        selectedRegularAssignmentToView,
        handleInputChange,
        joinOneValue,
        joinTwoValue,
        weightageValue,
        handleAddKeyPointData,
        filteredKeyPointsData,
        currentTimestamp,
        handleAddNewTechniqueSubmit,
        techniquesData: techniquesData?.data?.data,
        mapClassesToOptions,
        selectedClassesId,
        handleClassInputChange,
        selectedTechniqueToView,
        activeTechniqueData:
            techniquesData?.data?.data?.filter(item => item?.id === selectedTechniqueToView)[0] ??
            null,
        isTechniqueEditing,
        handleEditTechniqueBtnClick,
        handleEditTechniqueSubmit,
        handleDeleteTechniqueBtnClick,
        handleEditKeyPointModalClose,
        isEditKeyPointModalOpen,
        handleEditAddedKeyPointData,
        editingKeyPointDataIndex,
        editingKeyPointData,
        handleEditKeyPointModalInputChange,
        handleSaveKeyPointUpdatedData,
        handleDeleteKeyPointUpdatedData,
        userState,
        isJoinOneSearchInputOnFocus,
        setIsJoinOneSearchInputOnFocus,
        isJoinTwoSearchInputOnFocus,
        setIsJoinTwoSearchInputOnFocus,
        joinOneSearchTerm,
        setJoinOneSearchTerm,
        joinTwoSearchTerm,
        setJoinTwoSearchTerm,
        handleRemoveUploadedVideo,
        handleCancelTechniqueCreation,
        handleHomeassingmentclick,
        handleCancelRegularAssignmentUpdate,
        addRegularAssignment,
        regularAssignmentFormData,
        handleRegularAssignmentInputChange,
        handleAddRegularAssignment,
        handleCancelRegularAssignmentCreation,
        regularAssignments: regularAssignments?.data?.data,
        handleRegularAssignmentViewClick,
        isRegularAssignmentViewing,
        isRegularAssignmentEditing,
        handleUpdateRegularAssignment,
        academies: academiesData?.data?.data,
        academySelected,
        setAcademySelected,
        certPgSec,
        setCertPgSec,
        mapAcademyToOptions,
        selectedAcademyId,
        handleAcademyInputChange,
        mapTechniqueToOptions,
        handleTechniqueInputChange,
        selectDiscipline,
        setSelectDiscipline,
        addAIAssignment,
        handleAiHomeassingmentclick,
        studentsData,
        studentQuery,
        setstudentQuery,
        setAddAIAssignment,
        mapStudentToOptions,
        mapStudentsToOptions,
        isAddFitnessTestModalOpen,
        handleFitnessTestModalOpen,
        handleAddFitnessTestFormClose,
        isAllFitnessTestsListOpen,
        handleToggleFitnessTestTemplatesListView,
        fitnessTestTemplates: fitnessTestTemplates?.data?.data || [],
        fitnessTestsConducted: fitnessTestsConducted?.data?.data || [],
        isTakeFitnessTestModalOpen,
        selectedFitnessTest,
        handleTakeFitnessTestModalOpen,
        handleTakeFitnessTestModalClose,
        isRecordFitnessTestModalOpen,
        handleRecordFitnessTestModalClose,
        selectedRecordedFitnessTest,
        handleRecordFitnessTestModalOpen,
        activeFitnessTestListFilter,
        handleActiveFitnessTestListFilterChange,
    };
};
export default ProgressTrackerViewModel;
