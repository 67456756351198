import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    COACH_QUERY,
    STUDENT_SUGGESTION_QUERY,
    SUPPLIER_QUERY,
    USER_DETAILS_QUERY,
    USER_QUERY,
} from '../../utils/constants/constants';
import { HeaderMenuService } from '../../services/header-menu-service';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    setUser,
    setUserDetails,
    setSupplierDetails,
    setWalkthroughFlags,
} from '../../utils/redux/user-slice';
import { User } from '../../models/user/user';
import { formatDateToInputDate, getJWTToken, setJWTToken } from '../../utils/helpers/helpers';
import { Routes } from '../navigation/routes';
import { useDebounce } from 'use-debounce';
import { RootState } from '../../utils/redux/store';
import { Roles } from '../../utils/auth/roles';
import { UserDetailsService } from '../../services/user-details-service';
import { UserDetailsData } from '../../models/user-details/user-details-data';
import { SupplierData } from '../../models/supplier/supplier-data';
import { UserPostData } from '../../models/user/user-post-data';
import ApiResponse from '../../utils/types/api-response';
import { UserService } from '../../services/user-services';

const HeaderMenuViewModel = () => {
    const navigate = useNavigate();
    const usedispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);

    const userState = useSelector((state: RootState) => state.user);
    const userRole = userState?.user?.role?.type;
    const queryClient = useQueryClient();

    const {
        isSuccess: isSupplierFetchSuccess,
        isLoading: isSupplierDataLoading,
        data: supplierData,
    } = useQuery({
        queryKey: [SUPPLIER_QUERY],
        queryFn: HeaderMenuService.instance.getSupplierDetails.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: userRole === Roles.AUTHENTICATED_USER,
    });

    const {
        isSuccess: isUserDetailsDataFetchSuccess,
        isLoading: isUserDetailsDataLoading,
        data: userDetailsData,
    } = useQuery({
        queryKey: [USER_DETAILS_QUERY],
        queryFn: UserDetailsService.instance.getUserDetails.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: userRole === Roles.RESTRICTED_USER,
    });

    useEffect(() => {
        if (isUserDetailsDataFetchSuccess && userDetailsData?.data?.data) {
            usedispatch(setUserDetails(userDetailsData?.data?.data[0] ?? ({} as UserDetailsData)));
        }
    }, [userDetailsData?.data?.data]);

    useEffect(() => {
        if (isSupplierFetchSuccess && supplierData?.data?.data) {
            usedispatch(setSupplierDetails(supplierData?.data?.data[0] ?? ({} as SupplierData)));
        }
    }, [supplierData?.data?.data]);

    const {
        isSuccess: isStudentSuggesionDataFetchSuccess,
        isLoading: isStudentDataFetchLoading,
        data: studentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SUGGESTION_QUERY, value],
        queryFn: HeaderMenuService.instance.getStudentList.bind(
            this,
            userState?.user?.id ?? 0,
            value
        ),
        refetchOnWindowFocus: false,
        enabled: searchTerm.length > 0,
    });

    const handleOnMenuItemClick = useCallback((url: string) => {
        navigate(url);
        setIsSidebarOpen(false);
    }, []);

    const goToStudentDetails = useCallback(
        (studentId: number) => {
            navigate(Routes.Students + '/' + studentId);
        },
        [navigate]
    );

    const handlesearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event?.target?.value ?? '');
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

    const handleToggleMenuClick = () => {
        setIsSidebarOpen(prev => !prev);
    };

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isWebDropdownOpen, setWebDropdownOpen] = useState(false);

    const handleAvatarClick = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleSettingsIconClick = () => {
        setWebDropdownOpen(prev => !prev);
    };

    const {
        isSuccess: isUserInfoFetchSuccess,
        data: userData,
        isLoading: isUserInfoFetching,
    } = useQuery({
        queryKey: [USER_QUERY],
        queryFn: HeaderMenuService.instance.getUserInfo,
        refetchOnWindowFocus: false,
        enabled: getJWTToken() != null && getJWTToken().length > 0,
    });

    useEffect(() => {
        if (isUserInfoFetchSuccess && getJWTToken() !== '') {
            if (userData.success) {
                usedispatch(setUser(userData?.data ?? ({} as User)));
                usedispatch(setWalkthroughFlags(userData.data?.tutorialFlag ?? ''));
            } else {
                if (userData.error?.status === 403) {
                    setJWTToken('');
                }
            }
        }
    }, [isUserInfoFetchSuccess, userData]);

    const setUserTutorialFlag = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(data, userState?.user?.id ?? 0);
        if (response.success) {
            return response;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const { mutate: updateUserTutorialFlag } = useMutation(setUserTutorialFlag, {
        onSuccess: data => {
            queryClient.invalidateQueries(USER_QUERY);
        },
        onError: error => {},
    });

    useEffect(() => {
        if (!(userState.walkthroughFlag === 'FETCHING')) {
            updateUserTutorialFlag({ tutorialFlag: userState.walkthroughFlag });
        }
    }, [userState.walkthroughFlag]);

    return {
        isSupplierFetchSuccess,
        supplierData,
        userData: userState?.user,
        handleOnMenuItemClick,
        handlesearchInputChange,
        isStudentSuggesionDataFetchSuccess,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        searchTerm,
        goToStudentDetails,
        isSidebarOpen,
        handleToggleMenuClick,
        isDropdownOpen,
        handleAvatarClick,
        userRole,
        isUserDetailsDataFetchSuccess,
        userDetailsData,
        isWebDropdownOpen,
        handleSettingsIconClick,
        isSupplierDataLoading,
        isUserDetailsDataLoading,
        userState,
    };
};

export default HeaderMenuViewModel;
