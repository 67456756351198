import { useEffect, useState } from 'react';
import { studentAcademyHistory } from '../../../models/student/student-activity';
import { formatDateToInputDate, isValidEmail } from '../../../utils/helpers/helpers';
import { StudentPostDataWrapper } from '../../../models/student/student-post-data-wrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { StudentDetailsWrapper } from '../../../models/student/student-details-wrapper';
import { StudentService } from '../../../services/student-service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    ACADEMY_QUERY,
    ENQUIRY_QUERY,
    SCHEDULE_QUERY,
    STUDENT_DETAILS_QUERY,
    STUDENT_QUERY,
} from '../../../utils/constants/constants';
import { AcademiesService } from '../../../services/academies-service';
import ClassService from '../../../services/class-service';
import { ClassData } from '../../../models/class/class-data';
import { UploadService } from '../../../services/upload-service';
import { Roles } from '../../../utils/auth/roles';
import { StudentData } from '../../../models/student/student-data';
import { EnquiryData } from '../../../models/enquiry/enquiry-data';
import { studentAcademyHistoryPostData } from '../../../models/student/student-activity-post-data';
import { EnquiryPostDataWrapper } from '../../../models/enquiry/enquiry-post-data-wrapper';
import ApiResponse from '../../../utils/types/api-response';
import { EnquiryService } from '../../../services/enquiry-services';

export interface StudentFormDataType {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    DateOfBirth: string;
    BloodGroup: string;
    isPhysicallyHandicapped: string | null;
    academies: number[];
    skillLevel: string;
    class: number[];
    studentAcademyHistory?: studentAcademyHistory[];
    parentNumber: string;
    parentEmail: string;
    photo: string;
    city: string;
    state: string;
    currentAddress: string;
    apartmentName: string;
    relationOneRelationship: string;
    relationOneFullName: string;
    relationOneContact: string;
    relationOneEmail: string;
    relationOneGender: string;
    relationOneProfession: string;
    relationTwoRelationship: string;
    relationTwoFullName: string;
    relationTwoContact: string;
    relationTwoEmail: string;
    relationTwoGender: string;
    relationTwoProfession: string;
    school: string;
    board: string;
    grade: string;
}

export interface ClassDetailsCardType {
    id: number;
    timings: {
        day: string;
        from: string;
        to: string;
    }[];
}

export interface AcademyCard {
    academyId: number;
    classesSelected: ClassDetailsCardType[];
    enrolmentDate: string;
    remarks: string;
}

const AddStudentViewModel = ({
    handleClose,
    isEdit,
}: {
    handleClose: () => void;
    isEdit: boolean;
}) => {
    const formDataInitialState = {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        DateOfBirth: '',
        BloodGroup: '',
        isPhysicallyHandicapped: null,
        academies: [],
        skillLevel: '',
        class: [],
        studentAcademyHistory: [],
        parentNumber: '',
        photo: '',
        parentEmail: '',
        city: '',
        state: '',
        currentAddress: '',
        apartmentName: '',
        relationOneRelationship: '',
        relationOneFullName: '',
        relationOneContact: '',
        relationOneEmail: '',
        relationOneGender: '',
        relationOneProfession: '',
        relationTwoRelationship: '',
        relationTwoFullName: '',
        relationTwoContact: '',
        relationTwoEmail: '',
        relationTwoGender: '',
        relationTwoProfession: '',
        school: '',
        board: '',
        grade: '',
    };

    const [activeTab, setActiveTab] = useState(1);
    const [formData, setFormData] = useState<StudentFormDataType>(formDataInitialState);
    const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
    const [imageid, setImageId] = useState<number>();
    const [profileImageInputKey, setProfileImageInputKey] = useState<number>(0);
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const handleAddNewStudentBtnClick = () => {
        setIsNewStudentModalOpen(true);
    };

    const switchTab = (tabNumber: number, isGoingBack: boolean) => {
        if (isGoingBack) {
            setActiveTab(tabNumber);
        } else {
            if (validateCurrentTab()) {
                setActiveTab(tabNumber);
            }
        }
    };

    const validateCurrentTab = () => {
        switch (activeTab) {
            case 1:
                if (!formData.firstName || !formData.gender || !formData.DateOfBirth) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (formData.academies.length <= 0) {
                    alert('Please Choose an academy.');
                    return false;
                } else if (formData.class.length <= 0) {
                    alert('Please Choose a class.');
                    return false;
                }
                break;
            case 2:
                if (!formData.parentNumber || !formData.parentEmail || !formData.currentAddress) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (!isValidEmail(formData.parentEmail)) {
                    alert('Invalid email format. Please enter a valid parent email address.');
                    return false;
                }
                break;
            case 3:
                break;
            default:
                break;
        }
        return true;
    };

    const handleStudentFormModalClose = () => {
        const result = window.confirm(
            `Are you sure that you want to exit? \nall data entered in the fields will not be saved!`
        );
        if (result) {
            setIsNewStudentModalOpen(false);
            handleClose();
            setImageId(undefined);
            setAcademyCards(initialAcademyCardValue);
            setEditingStudentData(undefined);
            setIsEditStudentModalOpen(false);
            setFormData(formDataInitialState);
            setActiveTab(1);
        } else {
            return;
        }
    };

    const handleInputChange = (field: string, value: string | number[]) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const postNewStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentDetailsWrapper | undefined> => {
        const response = await StudentService.instance.postNewStudent(data);
        if (response.success) {
            if (activeEnquiryData) {
                const remarksInfo = userState?.user?.username
                    ? 'Student added by ' + userState.user.username
                    : 'Student added';

                const remarksData = {
                    data: {
                        remarks: [
                            ...(activeEnquiryData?.attributes?.remarks?.map(item => ({
                                date: item.date,
                                enquiredBy: item.enquiredBy?.data?.id,
                                remarksInfo: item.remarksInfo,
                                status: item.status,
                            })) ?? []),
                            {
                                date: new Date(),
                                enquiredBy: userState?.user?.id ?? 0,
                                remarksInfo,
                                status: 'Added Student',
                            },
                        ],
                    },
                };

                await EnquiryService.instance
                    .updateEnquiry(remarksData, activeEnquiryData?.id)
                    .then(() => {
                        queryClient.invalidateQueries(ENQUIRY_QUERY);
                    });
            }
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewStudent,
        isLoading: isNewStudentAdding,
        // error: isLeaveRecordPostError,
        isSuccess: isNewStudentAdded,
    } = useMutation(postNewStudent, {
        onSuccess: data => {
            alert('Added a student Successfully!');
            setIsNewStudentModalOpen(false);
            handleClose();
            setImageId(undefined);
            setAcademyCards(initialAcademyCardValue);
            setActiveTab(1);
            setFormData(formDataInitialState);
            setActiveEnquiryData(undefined);
            queryClient.invalidateQueries(STUDENT_QUERY);
        },
        onError: error => {
            // console.log(`On student add Error:`, error);
            alert('Failed to add student! Please try again');
            // Error actions
        },
    });

    const handleAddNewStudent = (e: React.FormEvent) => {
        e.preventDefault();

        const newStudentData: StudentPostDataWrapper = {
            data: {
                firstName: formData.firstName,
                lastName: formData.lastName || null,
                middleName: formData.middleName || null,
                gender: formData.gender,
                dob: formData.DateOfBirth,
                handicapped: formData.isPhysicallyHandicapped === 'true' ? true : false,
                bloodGroup: formData.BloodGroup || null,
                parentContactNo: formData.parentNumber,
                parentEmailId: formData.parentEmail,
                city: formData.city || null,
                state: formData.state || null,
                currentAddress: formData.currentAddress || null,
                apartmentName: formData.apartmentName || null,
                photo: imageid || null,
                academies: formData.academies,
                classes: formData.class,
                school: formData.school || null,
                grade: formData.grade || null,
                board: formData.board || null,
                studentAcademyHistory: academyCards.map(academy => {
                    return {
                        supplier: userState.user?.id,
                        academy: academy.academyId,
                        status: 'Active',
                        statusDate: new Date(),
                        enrolmentDate: new Date(academy.enrolmentDate),
                        remarks: academy.remarks || null,
                    };
                }),
                sessions: academyCards
                    .flatMap(acad => acad.classesSelected)
                    .map(cls => {
                        return {
                            class: cls.id,
                            sessionTimings: cls.timings.map(t => ({
                                day: t.day,
                                start_time: t.from,
                                end_time: t.to,
                            })),
                        };
                    }),
                studentRelation:
                    [
                        {
                            relation: formData.relationOneRelationship || null,
                            name: formData.relationOneFullName || null,
                            contactNo: formData.relationOneContact || null,
                            emailId: formData.relationOneEmail || null,
                            gender: formData.relationOneGender || null,
                            profession: formData.relationOneProfession || null,
                        },
                        {
                            relation: formData.relationTwoRelationship || null,
                            name: formData.relationTwoFullName || null,
                            contactNo: formData.relationTwoContact || null,
                            emailId: formData.relationTwoEmail || null,
                            gender: formData.relationTwoGender || null,
                            profession: formData.relationTwoProfession || null,
                        },
                    ] || null,
                users: [userState?.user?.id || 0],
            },
        };

        const additionalstudentAcademyHistoryAfterStudentUpdate: studentAcademyHistoryPostData[] =
            [];
        const beforeUpdate = editingStudentData?.attributes?.academies?.data?.map(item => item.id);
        // const afterUpdate = formData.academies;
        const afterUpdate = academyCards;

        afterUpdate?.forEach(academy => {
            if (!beforeUpdate?.includes(academy?.academyId)) {
                additionalstudentAcademyHistoryAfterStudentUpdate.push({
                    supplier: userState.user?.id || 0,
                    academy: academy?.academyId,
                    status: 'Active',
                    statusDate: new Date(),
                    enrolmentDate: new Date(academy?.enrolmentDate),
                });
            }
        });

        // Check for academies in beforeUpdate but not in afterUpdate
        beforeUpdate?.forEach(academyId => {
            if (!afterUpdate.map(item => item.academyId)?.includes(academyId)) {
                additionalstudentAcademyHistoryAfterStudentUpdate.push({
                    supplier: userState.user?.id || 0,
                    academy: academyId,
                    status: 'Inactive',
                    statusDate: new Date(),
                    // enrolmentDate: new Date(),
                });
            }
        });

        const editStudentData: StudentPostDataWrapper = {
            data: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                middleName: formData.middleName || null,
                gender: formData.gender,
                dob: formData.DateOfBirth,
                handicapped: formData.isPhysicallyHandicapped === 'true' ? true : false,
                bloodGroup: formData.BloodGroup || null,
                parentContactNo: formData.parentNumber,
                parentEmailId: formData.parentEmail,
                city: formData.city || null,
                state: formData.state || null,
                currentAddress: formData.currentAddress || null,
                apartmentName: formData.apartmentName || null,
                photo: imageid || null,
                academies: formData.academies,
                classes: formData.class,
                school: formData.school || null,
                grade: formData.grade || null,
                board: formData.board || null,
                studentAcademyHistory: [
                    ...(editingStudentData?.attributes?.studentAcademyHistory?.map(data => {
                        const foundAcademyCard = academyCards?.find(
                            item => item.academyId === data?.academy?.data?.id
                        );
                        const enrolmentDate = foundAcademyCard
                            ? new Date(foundAcademyCard.enrolmentDate)
                            : null;
                        return { ...data, enrolmentDate };
                    }) || []),
                    ...additionalstudentAcademyHistoryAfterStudentUpdate,
                ],
                studentRelation:
                    [
                        {
                            relation: formData.relationOneRelationship || null,
                            name: formData.relationOneFullName || null,
                            contactNo: formData.relationOneContact || null,
                            emailId: formData.relationOneEmail || null,
                            gender: formData.relationOneGender || null,
                            profession: formData.relationOneProfession || null,
                        },
                        {
                            relation: formData.relationTwoRelationship || null,
                            name: formData.relationTwoFullName || null,
                            contactNo: formData.relationTwoContact || null,
                            emailId: formData.relationTwoEmail || null,
                            gender: formData.relationTwoGender || null,
                            profession: formData.relationTwoProfession || null,
                        },
                    ] || null,
                users: [
                    ...(editingStudentData?.attributes?.users?.data?.map(item => item.id) || []),
                    userState?.user?.id || 0,
                ],
                sessions: academyCards
                    .flatMap(acad => acad.classesSelected)
                    .map(cls => {
                        return {
                            class: cls.id,
                            sessionTimings: cls.timings.map(t => ({
                                day: t.day,
                                start_time: t.from,
                                end_time: t.to,
                            })),
                        };
                    }),
            },
        };

        if (isEdit) {
            updateStudentDetails(editStudentData);
        } else {
            addNewStudent(newStudentData);
        }
    };

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading: isClassFetching,
        data: classesData,
    } = useQuery({
        queryKey: [SCHEDULE_QUERY, userState?.user?.id],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const mapAcademiesToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map(academy => {
                const { id, attributes } = academy;
                return {
                    value: id,
                    label: attributes.name,
                };
            });
        }
        return [];
    };

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && formData.academies) {
            return classesData?.data?.data
                ?.filter((item: ClassData) =>
                    formData?.academies.includes(item?.attributes?.academies?.data?.id)
                )
                ?.map((item: ClassData) => ({
                    value: item.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const PostStudentImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                photo: item.url,
                            }));
                        }
                        if (item.id) {
                            setImageId(item.id);
                        }
                    });
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postStudentProfileImageUpload: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadStudentImage,
        isLoading: isProfileImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(PostStudentImage, {
        onSuccess: data => {
            console.log('image upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const updateExistingStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentData | undefined> => {
        const response = await StudentService.instance.updateStudent(
            editingStudentData?.id || 0,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateStudentDetails,
        isLoading: isStudentDetailsUpdating,
        isSuccess: isStudentDetailsUpdated,
    } = useMutation(updateExistingStudent, {
        onSuccess: data => {
            alert('Updated student Successfully!');
            setIsEditStudentModalOpen(false);
            setFormData(formDataInitialState);
            setEditingStudentData(undefined);
            handleClose();
            setImageId(undefined);
            setActiveTab(1);
            queryClient.invalidateQueries(STUDENT_QUERY);
            queryClient.invalidateQueries(STUDENT_DETAILS_QUERY);
        },
        onError: error => {
            alert('Failed to update the student! Please try again');
        },
    });

    const getUserId = () => {
        if (userState?.user?.role?.type === Roles.AUTHENTICATED_USER) {
            return userState?.user?.id ?? 0;
        } else if (userState?.user?.role?.type === Roles.RESTRICTED_USER) {
            return userState?.userDetails?.attributes?.users?.data[0]?.id ?? 0;
        }
        return 0;
    };

    const { data: allClassesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: () => ClassService.instance.getClasses(getUserId()),
        refetchOnWindowFocus: false,
        enabled: getUserId() > 0,
    });

    const handleProfileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 10240) {
                    alert('Image size exceeds 10MB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadStudentImage(data);

            setProfileImageInputKey(prev => prev + 1);
        }
    };

    const initialAcademyCardValue = [
        {
            academyId: 0,
            classesSelected: [],
            enrolmentDate: formatDateToInputDate(new Date()),
            remarks: '',
        },
    ];

    const [isExpandedForAddAcademy, setIsExpandedForAddAcademy] = useState<number>(0);
    const [academyCards, setAcademyCards] = useState<AcademyCard[]>(initialAcademyCardValue);
    const listOfAllAcademy = academiesData?.data?.data.map(item => item);
    const academyNotSelected = listOfAllAcademy?.filter(
        academy => !academyCards?.map(academy => academy?.academyId).includes(academy.id)
    );

    const handleExpandClickForAddAcademy = (index: number) => {
        if (isExpandedForAddAcademy === index) {
            if (academyCards[index].academyId && academyCards[index].classesSelected.length > 0) {
                setIsExpandedForAddAcademy(-1);
            } else {
                alert('Invalid request. Kindly fill the required fields.');
                return;
            }
        } else {
            setIsExpandedForAddAcademy(index);
        }
    };

    const handleDeleteClickForAcademy = (index: number) => {
        if (academyCards.length === 1) {
            alert('Student should belong to atleast one acadmey.');
            return;
        }

        setAcademyCards(prev => prev.filter((item, cardIndex) => cardIndex !== index));
        if (isExpandedForAddAcademy === index) {
            setIsExpandedForAddAcademy(-1);
        } else if (index < isExpandedForAddAcademy) {
            setIsExpandedForAddAcademy(isExpandedForAddAcademy - 1);
        }
    };

    const handleAddNewAcademyCardButtonClick = () => {
        if (academyCards.length === listOfAllAcademy?.length) {
            alert('No more Academies left');
            return;
        }
        if (
            isExpandedForAddAcademy !== -1 &&
            (!academyCards[isExpandedForAddAcademy]?.academyId ||
                !(academyCards[isExpandedForAddAcademy].classesSelected.length > 0))
        ) {
            alert('Invalid request. Kindly fill the required files in the previous card.');
            return;
        }
        const newAcademyCard: AcademyCard = {
            academyId: 0,
            classesSelected: [],
            enrolmentDate: formatDateToInputDate(new Date()),
            remarks: '',
        };
        setAcademyCards(prev => [...prev, newAcademyCard]);
        handleExpandClickForAddAcademy(academyCards.length);
    };

    const handleNewAcademyCardInputChange = (
        field: string,
        value: string | number[] | number | undefined,
        index: number
    ) => {
        //  console.log(field, value);
        setAcademyCards(prev => {
            const newData = [...prev]; // Create a shallow copy of 'prev'
            newData[index] = {
                ...newData[index],
                [field]: value,
            };
            return newData;
        });
    };

    const mapRemainingAcademiesToOption = (): { value: number; label: string }[] | [] => {
        if (academyNotSelected) {
            return academyNotSelected.map(academy => {
                const { id, attributes } = academy;
                return {
                    value: id,
                    label: attributes.name,
                };
            });
        }
        return [];
    };

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            academies: academyCards.map(card => card.academyId).filter(Boolean),
            class: academyCards
                .map(card => card.classesSelected.map(classItem => classItem.id))
                .flat(),
        }));
    }, [academyCards]);

    const listOfAllClasses = classesData?.data?.data;

    const mapRemainingClassesToOption = (
        index: number
    ): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && academyCards[index].academyId) {
            return (
                listOfAllClasses
                    ?.filter(
                        classItem =>
                            classItem.attributes.academies.data.id === academyCards[index].academyId
                    )
                    ?.map(item => ({
                        value: item.id,
                        label: item.attributes.class_name,
                    })) ?? []
            );
        }
        return [];
    };

    const handleClassAdding = (id: number, academyCardIndex: number) => {
        setAcademyCards(prev => {
            const newAcademyCards = [...prev];
            const selectedAcademyCard = newAcademyCards[academyCardIndex];
            const updatedClassesSelected = [
                ...selectedAcademyCard.classesSelected,
                {
                    id: id,
                    timings: [],
                },
            ];
            newAcademyCards[academyCardIndex] = {
                ...selectedAcademyCard,
                classesSelected: updatedClassesSelected,
            };
            return newAcademyCards;
        });
    };

    const handleClassDeleting = (classId: number, academyCardIndex: number) => {
        setAcademyCards(prev => {
            const newAcademyCards = [...prev];
            const selectedAcademyCard = newAcademyCards[academyCardIndex];
            const updatedClassesSelected = selectedAcademyCard.classesSelected.filter(
                classDetails => classDetails.id !== classId
            );
            newAcademyCards[academyCardIndex] = {
                ...selectedAcademyCard,
                classesSelected: updatedClassesSelected,
            };
            return newAcademyCards;
        });
    };

    const handleCheckboxChange = (
        key: string,
        timing: { day: string; from: string; to: string },
        academyCardIndex: number,
        classId: number
    ) => {
        setAcademyCards(prev => {
            const newAcademyCards: AcademyCard[] = JSON.parse(JSON.stringify(prev));
            const card = newAcademyCards[academyCardIndex];
            // Check if card and classesSelected are defined
            if (!card || !card.classesSelected) {
                return prev;
            }

            const classIndex = card.classesSelected.findIndex(cls => cls.id === classId);
            const currentClassData: ClassData =
                allClassesData?.data?.data?.find(classItem => classItem?.id === classId) ??
                ({} as ClassData);

            if (classIndex >= 0) {
                const classTimings = card.classesSelected[classIndex].timings;
                const timingIndex = classTimings.findIndex(
                    t => t.day === timing.day && t.from === timing.from && t.to === timing.to
                );

                if (timingIndex >= 0) {
                    // Remove timing if it exists
                    card.classesSelected[classIndex].timings = classTimings.filter(
                        t => !(t.day === timing.day && t.from === timing.from && t.to === timing.to)
                    );
                } else {
                    // Add timing if it does not exist
                    card.classesSelected[classIndex].timings.push(timing);
                }
            } else {
                // Add new class with timing
                card.classesSelected.push({
                    id: classId,
                    timings: [timing],
                });
            }

            newAcademyCards[academyCardIndex] = card;
            return newAcademyCards;
        });
    };

    const handleCheckboxChangeForDay = (day: string, academyCardIndex: number, classId: number) => {
        setAcademyCards(prev => {
            if (!prev || prev.length === 0 || academyCardIndex < 0 || academyCardIndex >= prev.length) {
                return prev;
            }
    
            // Deep clone the state to avoid direct mutations
            const newAcademyCards: AcademyCard[] = JSON.parse(JSON.stringify(prev));
            const card = newAcademyCards[academyCardIndex];
    
            if (!card || !card.classesSelected) {
                return prev;
            }
    
            const classIndex = card.classesSelected.findIndex(cls => cls.id === classId);
            const currentClassData = allClassesData?.data?.data?.find(classItem => classItem?.id === classId);
    
            if (classIndex >= 0 && currentClassData) {
                const allTimings = currentClassData?.attributes?.class_timings?.map(data => ({
                    day: data.day,
                    from: data.start_time,
                    to: data.end_time
                })) || [];
    
                const dayTimingsOfClass = allTimings.filter(t => t.day === day);
    
                if (dayTimingsOfClass.length > 0) {
                    const existingDayTimings = card.classesSelected[classIndex].timings.filter(t => t.day === day);
    
                    if (existingDayTimings.length === dayTimingsOfClass.length) {
                        // All slots are selected, remove them
                        card.classesSelected[classIndex].timings = card.classesSelected[classIndex].timings.filter(t => t.day !== day);
                        // console.log("All slots are selected, remove them");
                    } else {
                        // Some or none of the slots are selected, find and add those which are not selected
                        const timingsToAdd = dayTimingsOfClass.filter(timing =>
                            !existingDayTimings.some(selected =>
                                selected.from === timing.from && selected.to === timing.to
                            )
                        );
                        card.classesSelected[classIndex].timings.push(...timingsToAdd);
                        // console.log("Some or none of the slots are selected, find and add those which are not selected");
                    }
                }
            }
    
            return newAcademyCards;
        });
    };
    

    // console.log(academyCards);
    

    // const handleCheckboxChangeForDay = (day: string, academyCardIndex: number, classId: number) => {
    //     setAcademyCards(prev => {
    //         if (
    //             !prev ||
    //             prev.length === 0 ||
    //             academyCardIndex < 0 ||
    //             academyCardIndex >= prev.length
    //         ) {
    //             return prev;
    //         }

    //         const newAcademyCards = [...prev];
    //         const card = { ...newAcademyCards[academyCardIndex] };

    //         if (!card || !card.classesSelected) {
    //             return prev;
    //         }

    //         const classIndex = card.classesSelected.findIndex(cls => cls.id === classId);

    //         if (classIndex >= 0) {
    //             const existingDayIndex = card.classesSelected[classIndex].timings.findIndex(
    //                 t => t.day === day
    //             );

    //             if (existingDayIndex >= 0) {
    //                 // Day already exists, remove it
    //                 card.classesSelected[classIndex].timings = card.classesSelected[
    //                     classIndex
    //                 ].timings.filter(t => t.day !== day);
    //                 // console.log("Day exists, removing it");
    //             } else {
    //                 // Day does not exist, add it
    //                 card.classesSelected[classIndex].timings.push({ day, from: '', to: '' });
    //                 // console.log("Day does not exist, adding it");
    //             }
    //         }

    //         newAcademyCards[academyCardIndex] = card;
    //         return newAcademyCards;
    //     });
    // };

    const [editingStudentData, setEditingStudentData] = useState<StudentData>();
    const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState<boolean>(false);

    const { isSuccess: isAllAcademiesFetchSuccess, data: allAcademiesOfSupplier } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const setStudentEditData = (student: StudentData | undefined) => {
        if (student) {
            setEditingStudentData(student);
            if (student?.attributes?.photo?.data?.id) {
                setImageId(student?.attributes?.photo?.data?.id);
            }
            setFormData({
                firstName: student?.attributes?.firstName,
                lastName: student?.attributes?.lastName,
                middleName: student?.attributes?.middleName || '',
                gender: student?.attributes?.gender || '',
                DateOfBirth: student?.attributes?.dob,
                BloodGroup: student?.attributes?.bloodGroup,
                photo: student?.attributes?.photo?.data?.attributes?.url,
                isPhysicallyHandicapped: student?.attributes?.handicapped ? 'true' : 'false',
                academies: student?.attributes?.academies?.data?.map(academy => academy?.id),
                skillLevel: '',
                class: student?.attributes?.classes?.data?.map(classItem => classItem?.id),
                parentNumber: student?.attributes?.parentContactNo,
                parentEmail: student?.attributes?.parentEmailId,
                city: student?.attributes?.city,
                state: student?.attributes?.state,
                currentAddress: student?.attributes?.currentAddress,
                apartmentName: student?.attributes?.apartmentName,
                school: student?.attributes?.school ?? '',
                board: student?.attributes?.board ?? '',
                grade: student?.attributes?.grade ?? '',
                relationOneRelationship:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].relation || ''
                        : '',
                relationOneFullName:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].name || ''
                        : '',
                relationOneContact:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].contactNo || ''
                        : '',
                relationOneEmail:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].emailId || ''
                        : '',
                relationOneGender:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].gender || ''
                        : '',
                relationOneProfession:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[0]
                        ? student?.attributes?.studentRelation[0].profession || ''
                        : '',
                relationTwoRelationship:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].relation || ''
                        : '',
                relationTwoFullName:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].name || ''
                        : '',
                relationTwoContact:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].contactNo || ''
                        : '',
                relationTwoEmail:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].emailId || ''
                        : '',
                relationTwoGender:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].gender || ''
                        : '',
                relationTwoProfession:
                    student?.attributes?.studentRelation && student?.attributes?.studentRelation[1]
                        ? student?.attributes?.studentRelation[1].profession || ''
                        : '',
            });
            const allActiveAcademyHistoryOfStudent =
                student?.attributes?.studentAcademyHistory.filter(
                    item =>
                        item.status === 'Active' &&
                        allAcademiesOfSupplier?.data?.data
                            .map(academy => academy.id)
                            .includes(item?.academy?.data?.id)
                );

            const data: AcademyCard[] = student?.attributes?.academies?.data
                ?.map(academy => academy.id)
                .map(id => {
                    const reqEntry = allActiveAcademyHistoryOfStudent
                        .filter(item => item?.academy?.data?.id === id)
                        .sort(
                            (a, b) =>
                                new Date(b?.enrolmentDate).getTime() -
                                new Date(a?.enrolmentDate).getTime()
                        )[0];

                    const reqClasses =
                        allClassesData?.data?.data
                            .filter(
                                classData =>
                                    classData.attributes?.academies?.data?.id === id &&
                                    student.attributes?.classes?.data
                                        .map(data => data.id)
                                        .includes(classData?.id)
                            )
                            .map(data => data?.id) ?? [];
                    return {
                        academyId: id,
                        classesSelected: reqClasses.map(classId => {
                            const allSessionsForThisClass = student?.attributes?.sessions?.find(
                                session => session?.class?.data?.id === classId
                            );
                            return {
                                id: classId,
                                timings:
                                    allSessionsForThisClass?.sessionTimings.filter(t => t.end_time !== null && t.end_time !== '').map(t => ({
                                        day: t.day,
                                        from: t.start_time,
                                        to: t.end_time,
                                    })) ?? [],
                            };
                        }),
                        enrolmentDate: formatDateToInputDate(new Date(reqEntry?.enrolmentDate)),
                        remarks: reqEntry?.remarks,
                    };
                });

            setAcademyCards(data.length === 0 ? initialAcademyCardValue : data);

            setIsEditStudentModalOpen(true);
        }
    };

    const presetEnquiryDataInForm = (enquiry: EnquiryData | undefined) => {
        if (enquiry) {
            setFormData(prev => ({
                ...prev,
                firstName: enquiry.attributes.firstName,
                middleName: enquiry.attributes.middleName,
                lastName: enquiry.attributes.lastName,
                parentEmail: enquiry.attributes.email,
                parentNumber: enquiry.attributes.contactNo,
                academies: enquiry.attributes.academies.data.map(item => item.id),
                class: enquiry.attributes.classesInterested.data.map(item => item.id),
            }));

            const data: AcademyCard[] = enquiry.attributes.academies.data
                .map(academy => academy.id)
                .map(id => {
                    const reqClasses =
                        classesData?.data?.data
                            ?.filter(
                                classData =>
                                    classData?.attributes?.academies?.data?.id === id &&
                                    enquiry.attributes?.classesInterested?.data
                                        .map(data => data.id)
                                        .includes(classData?.id)
                            )
                            .map(data => data?.id) ?? [];
                    return {
                        academyId: id,
                        enrolmentDate: formatDateToInputDate(new Date()),
                        remarks: '',
                        classesSelected: reqClasses.map(classId => {
                            return {
                                id: classId,
                                timings: [],
                            };
                        }),
                    };
                });

            setAcademyCards(data);
        }
    };

    const [activeEnquiryData, setActiveEnquiryData] = useState<EnquiryData | undefined>(undefined);

    return {
        isLoading: isNewStudentAdding || isStudentDetailsUpdating,
        handleAddNewStudentBtnClick,
        handleStudentFormModalClose,
        isNewStudentModalOpen,
        switchTab,
        handleInputChange,
        handleAddNewStudent,
        formData,
        activeTab,
        mapAcademiesToOptions,
        mapClassesToOptions,
        handleProfileImgChange,
        isProfileImagePosting,
        profileImageInputKey,
        isExpandedForAddAcademy,
        handleExpandClickForAddAcademy,
        academyCards,
        handleAddNewAcademyCardButtonClick,
        handleDeleteClickForAcademy,
        mapRemainingAcademiesToOption,
        handleNewAcademyCardInputChange,
        mapRemainingClassesToOption,
        handleClassAdding,
        handleClassDeleting,
        allClasses: allClassesData?.data?.data,
        handleCheckboxChange,
        handleCheckboxChangeForDay,
        setStudentEditData,
        isEditStudentModalOpen,
        presetEnquiryDataInForm,
        setActiveEnquiryData,
    };
};

export default AddStudentViewModel;
