import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import style from './style.module.css';
import clickIcon from '../../../../assets/icons/clock.png';
import { convertTimeFormat } from '../../../../utils/helpers/helpers';
import { ClassDetailsCardType } from '../../../view-models/dashboard-view-model';
import { ClassData } from '../../../../models/class/class-data';
import CustomSelectModal from '../custom-select-modal';

interface AddClassCardProps {
    listOfAvailableClasses: { value: number; label: string }[];
    classCardData: ClassDetailsCardType[];
    listOfAllClasses: () => { value: number; label: string }[];
    handleClassAdding: (id: number, academyCardIndex: number, classCardIndex: number) => void;
    academyCardIndex: number;
    handleClassDeleting: (classId: number, academyCardIndex: number) => void;
    allClasses: ClassData[];
    handleCheckboxChange: (
        key: string,
        timing: { day: string; from: string; to: string },
        academyCardIndex: number,
        classId: number
    ) => void;
    handleCheckboxChangeForDay: (day: string, academyCardIndex: number, classId: number) => void;
}

const AddClassCard = ({
    listOfAvailableClasses,
    classCardData,
    listOfAllClasses,
    handleClassAdding,
    academyCardIndex,
    handleClassDeleting,
    allClasses,
    handleCheckboxChange,
    handleCheckboxChangeForDay,
}: AddClassCardProps) => {
    const [currentClassSelectedForAdding, setCurrentClassSelectedForAdding] = useState(0);
    const [expandedClassCard, setExpandedClassCard] = useState<number>(0);

    const handleCardExpandCollapse = (id: number) => {
        setExpandedClassCard(prevId => (prevId === id ? 0 : id));
    };

    return (
        <div>
            <div className="flex items-center relative">
                {/* <Select
                    options={listOfAvailableClasses.filter(option => !classCardData.map(item => item.id).includes(option.value))}
                    className={style.formInput}
                    placeholder={
                        listOfAvailableClasses.filter(option => !classCardData.map(item => item.id).includes(option.value)).length === 0 ?
                            'No more classes found for this academy' :
                            'Choose a class'
                    }
                    onChange={e => setCurrentClassSelectedForAdding(e?.value ?? 0)}
                    value={listOfAllClasses().filter(option => option.value === currentClassSelectedForAdding)}
                    isDisabled={listOfAvailableClasses.filter(option => !classCardData.map(item => item.id).includes(option.value)).length === 0}
                /> */}
                <select
                    className={style.formInput}
                    disabled={
                        listOfAvailableClasses.filter(
                            option => !classCardData.map(item => item.id).includes(option.value)
                        ).length === 0
                    }
                    onChange={e => setCurrentClassSelectedForAdding(Number(e?.target?.value) ?? 0)}
                    value={currentClassSelectedForAdding}
                >
                    {listOfAvailableClasses.filter(
                        option => !classCardData.map(item => item.id).includes(option.value)
                    ).length === 0 ? (
                        <option
                            className="text-gray-400 bg-slate-300"
                            value={0}
                        >
                            No more classes found
                        </option>
                    ) : (
                        <>
                            <option
                                className="text-gray-400 bg-slate-300"
                                value={0}
                            >
                                Choose a class
                            </option>
                            {listOfAvailableClasses
                                .filter(
                                    option =>
                                        !classCardData.map(item => item.id).includes(option.value)
                                )
                                .map(option => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                        </>
                    )}
                </select>
                <button
                    className="absolute right-0 rounded bg-slate-400 h-full w-[38px] flex items-center justify-center cursor-pointer"
                    onClick={() => {
                        handleClassAdding(currentClassSelectedForAdding, academyCardIndex, 0);
                        setCurrentClassSelectedForAdding(0);
                    }}
                    disabled={!currentClassSelectedForAdding}
                    type="button"
                >
                    +
                </button>
            </div>
            {/* <CustomSelectModal
                background='slate-50'
                title='Select Academy'
                onChange={e => setCurrentClassSelectedForAdding(e ?? 0)}
                value={listOfAllClasses().filter(option => option.value === currentClassSelectedForAdding)}
                options={listOfAvailableClasses.filter(option => !classCardData.map(item => item.id).includes(option.value))}
                disabled={listOfAvailableClasses.filter(option => !classCardData.map(item => item.id).includes(option.value)).length === 0}
            /> */}
            <div className="border-2 flex flex-col gap-2 p-2 bg-white">
                {classCardData.length > 0 ? (
                    classCardData.map(item => {
                        const classData = allClasses?.find(classData => classData.id === item.id);
                        const days = [
                            ...new Set(
                                classData?.attributes?.class_timings?.map(timing => timing.day)
                            ),
                        ];

                        return (
                            <div
                                key={item.id}
                                className="border rounded p-1 px-2"
                            >
                                <div className="flex sm:flex-row flex-col sm:items-center justify-between p-[2px] sm:p-[4px]">
                                    <div className="font-semibold text-gray-700 text-sm sm:text-lg">
                                        {classData?.attributes?.class_name}
                                    </div>
                                    <div className="flex gap-2">
                                        <div
                                            className="flex items-center text-xs rounded bg-red-100 sm:px-2 sm:py-1 px-[4px] py-[2px] cursor-pointer"
                                            onClick={() =>
                                                handleClassDeleting(item.id, academyCardIndex)
                                            }
                                        >
                                            Remove
                                        </div>
                                        <div
                                            onClick={() => handleCardExpandCollapse(item.id)}
                                            className="flex items-center gap-2 text-xs rounded bg-gray-50 sm:px-2 sm:py-1 px-[4px] py-[2px] border border-gray-100 cursor-pointer"
                                        >
                                            <img
                                                src={clickIcon}
                                                className="h-5 w-5"
                                            />
                                            Select Day and Timings
                                        </div>
                                    </div>
                                </div>
                                {expandedClassCard === item.id && <hr className="my-2" />}
                                {expandedClassCard === item.id && (
                                    <div>
                                        <div className="flex flex-col gap-1">
                                            {days.map(day => (
                                                <div
                                                    key={day}
                                                    className="flex sm:flex-row flex-col gap-2 sm:gap-6 p-2 rounded border items-start bg-slate-50"
                                                >
                                                    <div className="flex items-center gap-2 min-w-[240px] max-w-[190px] font-semibold text-sm">
                                                        <input
                                                            type="checkbox"
                                                            className="rounded cursor-pointer bg-slate-100 focus:ring-slate-600 checked:bg-slate-500"
                                                            onChange={() =>
                                                                handleCheckboxChangeForDay(
                                                                    day,
                                                                    academyCardIndex,
                                                                    item.id
                                                                )
                                                            }
                                                            checked={classData?.attributes?.class_timings?.filter(t => t.day === day).length === item?.timings?.filter(t => t.day === day).length}
                                                            // checked={item.timings.some(
                                                            //     t => t.day === day
                                                            // )}
                                                        />
                                                        {day}
                                                    </div>
                                                    <div className="ml-4 sm:ml-0">
                                                        {classData?.attributes?.class_timings
                                                            ?.filter(timing => timing.day === day)
                                                            .map((data, index) => {
                                                                const key = `${data.day}-${data.start_time}-${data.end_time}`;
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="flex items-center gap-2 text-sm">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="rounded-lg cursor-pointer"
                                                                                checked={item.timings.some(
                                                                                    t => t.day === data.day && t.from === data.start_time && t.to === data.end_time
                                                                                )}
                                                                                onChange={() => handleCheckboxChange(key, { day: data.day, from: data.start_time, to: data.end_time }, academyCardIndex, item.id)}
                                                                            />
                                                                            {convertTimeFormat(
                                                                                data.start_time.split(
                                                                                    '.'
                                                                                )[0]
                                                                            )}{' '}
                                                                            -{' '}
                                                                            {convertTimeFormat(
                                                                                data.end_time.split(
                                                                                    '.'
                                                                                )[0]
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className="text-center text-gray-500">No Classes Added!</div>
                )}
            </div>
        </div>
    );
};

export default AddClassCard;
