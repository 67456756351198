import React, { memo } from 'react';
import style from './style.module.css';
import mailIcon from '../../../../assets/icons/email.png';
import callIcon from '../../../../assets/icons/call.png';
import calendarCheckIcon from '../../../../assets/icons/calendar-check.png';
import chevronDown from '../../../../assets/icons/chevrondown.png';
import plusIcon from '../../../../assets/icons/plus.png';
import { EnquiryData } from '../../../../models/enquiry/enquiry-data';
import { getFormattedDate } from '../../../../utils/helpers/helpers';
import { useCollapse } from 'react-collapsed';

interface EnquiryListItemProps {
    enquiryData: EnquiryData;
    handleNotInterestedClick: () => void;
    isExpanded: boolean;
    handleEnquiryItemExpandCollapse: () => void;
    enquiryAcademyListExpanded: boolean;
    enquiryDisciplineListExpanded: boolean;
    enquiryClassListExpanded: boolean;
    handleEnquiryInfoListExpandClick: (type: string, id: number) => void;
    handleAddNewStudentBtnClick: () => void;
    handleAddRemarks: () => void;
    remarksText: string;
    handleRetRemarksTextChange: (text: string) => void;
    handleEnquiryListItemCheck: () => void;
    isAllChecked: boolean;
    isRestricted: boolean;
    handleEditBtnClick: () => void;
}

const EnquiryListItem = ({
    enquiryData,
    handleNotInterestedClick,
    isExpanded,
    handleEnquiryItemExpandCollapse,
    handleEnquiryInfoListExpandClick,
    enquiryAcademyListExpanded,
    enquiryDisciplineListExpanded,
    enquiryClassListExpanded,
    handleAddNewStudentBtnClick,
    handleAddRemarks,
    remarksText,
    handleRetRemarksTextChange,
    handleEnquiryListItemCheck,
    isAllChecked,
    isRestricted,
    handleEditBtnClick,
}: EnquiryListItemProps) => {
    const { getCollapseProps } = useCollapse({
        isExpanded: isExpanded,
    });

    return (
        <div className={style.checkboxListItemWrapper}>
            <input
                type="checkbox"
                className={style.checkboxItem}
                onChange={handleEnquiryListItemCheck}
                checked={isAllChecked ? true : undefined}
            />
            <div className="flex-grow">
                <div className={style.nameCheckBoxWrapper}>
                    <div>
                        {enquiryData?.attributes?.firstName + ' '}
                        {enquiryData?.attributes?.middleName + ' '}
                        {enquiryData?.attributes?.lastName}
                    </div>
                </div>
                <div className={style.listInfoContainer}>
                    <div className={style.listItemContainer}>
                        <div>Parent name: {enquiryData?.attributes?.parentName}</div>
                        {enquiryData?.attributes?.academies?.data?.length === 1 ? (
                            <div>
                                {enquiryData?.attributes?.academies?.data[0]?.attributes?.name}
                            </div>
                        ) : (
                            <div className="inline-flex items-center gap-1 relative">
                                {enquiryData?.attributes?.academies?.data[0]?.attributes?.name}
                                <img
                                    className="p-1 h-4 w-4"
                                    src={plusIcon}
                                    alt="show all icon"
                                />
                                <div
                                    onClick={() =>
                                        handleEnquiryInfoListExpandClick('academy', enquiryData.id)
                                    }
                                    className="cursor-pointer bg-slate-100 flex items-center justify-center h-4 w-4 rounded-full text-xs"
                                >
                                    {enquiryData?.attributes?.academies?.data?.length - 1}
                                </div>
                                {enquiryAcademyListExpanded && (
                                    <div className="absolute bg-white p-2 rounded left-0 top-full shadow w-[200px]">
                                        {enquiryData?.attributes?.academies?.data?.map(item => (
                                            <div key={item?.id}>{item?.attributes?.name}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {enquiryData?.attributes?.discipline?.split(',').length === 1 ? (
                            <div>{enquiryData?.attributes?.discipline?.split(',')[0]}</div>
                        ) : (
                            <div className="inline-flex items-center gap-1 relative">
                                {enquiryData?.attributes?.discipline?.split(',')[0]}
                                <img
                                    className="p-1 h-4 w-4"
                                    src={plusIcon}
                                    alt="show all icon"
                                />
                                <div
                                    onClick={() =>
                                        handleEnquiryInfoListExpandClick(
                                            'discipline',
                                            enquiryData.id
                                        )
                                    }
                                    className="cursor-pointer bg-slate-100 flex items-center justify-center h-4 w-4 rounded-full text-xs"
                                >
                                    {enquiryData?.attributes?.discipline?.split(',')?.length - 1}
                                </div>
                                {enquiryDisciplineListExpanded && (
                                    <div className="absolute bg-white p-2 rounded left-0 top-full shadow w-[200px]">
                                        {enquiryData?.attributes?.discipline
                                            ?.split(',')
                                            ?.map((item, index) => <div key={index}>{item}</div>)}
                                    </div>
                                )}
                            </div>
                        )}
                        {enquiryData?.attributes?.classesInterested?.data?.length === 1 ? (
                            <div>
                                {
                                    enquiryData?.attributes?.classesInterested?.data[0]?.attributes
                                        ?.class_name
                                }
                            </div>
                        ) : (
                            <div className="inline-flex items-center gap-1 relative">
                                {
                                    enquiryData?.attributes?.classesInterested?.data[0]?.attributes
                                        ?.class_name
                                }
                                <img
                                    className="p-1 h-4 w-4"
                                    src={plusIcon}
                                    alt="show all icon"
                                />
                                <div
                                    onClick={() =>
                                        handleEnquiryInfoListExpandClick('class', enquiryData.id)
                                    }
                                    className="cursor-pointer bg-slate-100 flex items-center justify-center h-4 w-4 rounded-full text-xs"
                                >
                                    {enquiryData?.attributes?.classesInterested?.data?.length - 1}
                                </div>
                                {enquiryClassListExpanded && (
                                    <div className="absolute bg-white p-2 rounded left-0 top-full shadow w-[200px]">
                                        {enquiryData?.attributes?.classesInterested?.data?.map(
                                            item => (
                                                <div key={item?.id}>
                                                    {item?.attributes?.class_name}
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={style.iconTextWrapperCall}
                            {...getCollapseProps()}
                        >
                            <img
                                src={callIcon}
                                className={style.listIcons}
                            />
                            {enquiryData?.attributes?.contactNo}
                        </div>
                        <div
                            className={style.iconTextWrapperMail}
                            {...getCollapseProps()}
                        >
                            <img
                                src={mailIcon}
                                className={style.listIcons}
                            />
                            {enquiryData?.attributes?.email}
                        </div>
                        <div
                            className={style.iconTextWrapperDate}
                            {...getCollapseProps()}
                        >
                            <img
                                src={calendarCheckIcon}
                                className={style.listIcons}
                            />
                            {enquiryData?.attributes?.createdAt &&
                                getFormattedDate(enquiryData?.attributes?.createdAt)}
                        </div>
                    </div>
                    {enquiryData?.attributes?.remarks[enquiryData?.attributes?.remarks.length - 1]
                        ?.status === 'Added Student' ? (
                        <div className={style.addStudentNotInterestedBtnContainer}>
                            <div className="border border-dashed border-green-300 px-1 text-green-300">
                                Student Added
                            </div>
                        </div>
                    ) : enquiryData?.attributes?.remarks[
                          enquiryData?.attributes?.remarks.length - 1
                      ]?.status === 'Not Interested' ? (
                        <div className={style.addStudentNotInterestedBtnContainer}>
                            <div className="border border-dashed border-red-300 px-1 text-red-300">
                                Not Interested
                            </div>
                        </div>
                    ) : (
                        <div className={style.addStudentNotInterestedBtnContainer}>
                            {!isRestricted && (
                                <>
                                    <div className={style.addStudentBtnContainer}>
                                        <button
                                            className={style.addStudentBtn}
                                            onClick={handleAddNewStudentBtnClick}
                                        >
                                            Add Student
                                        </button>
                                    </div>
                                    <div className={style.notInterestedBtnContainer}>
                                        <button
                                            className={style.notInterestedBtn}
                                            onClick={handleNotInterestedClick}
                                        >
                                            Not interested
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="grid grid-cols-2 sm:grid-cols-3 sm:gap-1"
                    {...getCollapseProps()}
                >
                    <div
                        className={`
                            ${style.remarksTableContainer} 
                            ${
                                (enquiryData?.attributes?.remarks[
                                    enquiryData?.attributes?.remarks.length - 1
                                ]?.status === 'Enquiring' ||
                                    enquiryData?.attributes?.remarks.length === 0) &&
                                '!col-span-2'
                            }`}
                    >
                        <table className="border border-collapse border-solid border-gray-300 m-0 p-0 w-full">
                            {/* <caption className="text-lg">Remarks History</caption> */}
                            <thead>
                                <tr className="bg-gray-100 border border-solid border-gray-300 p-1">
                                    <th
                                        className="p-1 text-center text-sm tracking-wide uppercase w-[100px]"
                                        scope="col"
                                    >
                                        Date
                                    </th>
                                    <th
                                        className="p-1 text-center text-sm tracking-wide uppercase"
                                        scope="col"
                                    >
                                        Remarks
                                    </th>
                                    <th
                                        className="p-1 text-center text-sm tracking-wide uppercase min-w-[100px] hidden sm:table-cell"
                                        scope="col"
                                    >
                                        Status
                                    </th>
                                    <th
                                        className="p-1 text-center text-sm tracking-wide uppercase min-w-[100px] hidden sm:table-cell"
                                        scope="col"
                                    >
                                        Updated by
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {enquiryData?.attributes?.remarks?.length > 0 ? (
                                    enquiryData?.attributes?.remarks?.map(item => (
                                        <tr
                                            key={item?.id}
                                            className="border border-solid border-gray-300 p-1"
                                        >
                                            <td
                                                className="p-1 text-center"
                                                data-label="Date"
                                            >
                                                {getFormattedDate(item?.date)}
                                            </td>
                                            <td
                                                className="p-1 text-center"
                                                data-label="Remarks"
                                            >
                                                {item?.remarksInfo}
                                            </td>
                                            <td
                                                className="p-1 text-center hidden sm:table-cell"
                                                data-label="Status"
                                            >
                                                {item?.status}
                                            </td>
                                            <td
                                                className="p-1 text-center hidden sm:table-cell"
                                                data-label="UpdatedBy"
                                            >
                                                {item?.enquiredBy?.data?.attributes?.username}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            className="p-1 text-center text-gray-500"
                                            colSpan={4}
                                        >
                                            No remarks added yet!
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(enquiryData?.attributes?.remarks[enquiryData?.attributes?.remarks.length - 1]
                        ?.status === 'Enquiring' ||
                        enquiryData?.attributes?.remarks.length === 0) && (
                        <div className={style.remarksTextBoxContainer}>
                            {!isRestricted && (
                                <>
                                    <textarea
                                        value={remarksText}
                                        onChange={e => handleRetRemarksTextChange(e.target.value)}
                                        className={style.remarksInfoTextBox}
                                    />
                                    <div className={style.remarkUpdateBtnContainer}>
                                        <button
                                            className={style.remarkUpdateBtn}
                                            onClick={handleAddRemarks}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <hr className='col-span-3 bg-gray-500 my-2'/>
                    <div className='flex justify-end mt-1 col-span-3'>
                        <div 
                            className='bg-primary px-8 py-[2px] rounded text-white text-xl cursor-pointer active:scale-95 transition-all'
                            onClick={handleEditBtnClick}
                        >
                            Edit
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.enquiryItemExpandCollapseContainer}>
                <img
                    onClick={handleEnquiryItemExpandCollapse}
                    src={chevronDown}
                    className={isExpanded ? style.collapseEnquiryIcon : style.expandEnquiryIcon}
                />
            </div>
        </div>
    );
};

export default memo(EnquiryListItem);
