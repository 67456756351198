import React, { useEffect, useMemo, useState } from 'react';
import style from './style.module.css';
import EnrollStudentFormViewModel from '../../view-models/enroll-student-form-view-model';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import Footer from '../../components/landing-page-items/Footer';
import Navbar from '../../components/landing-page-items/Navbar';
import FooterBase from '../../components/landing-page-items/FooterBase';
import { EnrollmentDataListWrapper } from '../../../models/enrollment-data/enrollment-data-list-wrapper';
import PopupModal from '../../components/widgets/popup-modal';

const EnrollStudentFormPage = () => {
    const {
        isEnrollmentDataFetching,
        isSubmitted,
        isActive,
        isInvalidLink,
        data,
        formData,
        handleFormInputChange,
        handleFormSubmit,
        isNewEnrolmentJsonLoading,
        isNewEnrolmentJsonAdded,
        handleDeleteRelationClick,
        setSecondRelation,
        secondRelation,
        thankyouPage,
    } = EnrollStudentFormViewModel();

    const [showClassDetails, setShowClassDetails] = useState(false);
    const viewClassDetails = () => {
        setShowClassDetails(!showClassDetails);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="z-10000">
                <Navbar />
            </div>

            <div className="w-full mt-20 h-full flex-grow">
                {isEnrollmentDataFetching ? (
                    <FullScreenLoader />
                ) : isInvalidLink ? (
                    <div className="sm:w-[80%] w-[90%] sm:h-[600px] h-[650px] border-2 m-auto py-4 px-8 bg-[#5A639C] rounded text-red-500 flex">
                        <div className="text-center sm:text-5xl text-2xl font-bold m-auto">
                            Invalid link <br />
                            <div className="m-auto sm:text-lg text-base text-white font-normal mt-4">
                                Please try again
                            </div>
                        </div>
                    </div>
                ) : isActive ? (
                    isSubmitted ? (
                        <div className="sm:w-[80%] w-[90%] sm:h-[600px] h-[650px] border-2 m-auto py-4 px-8 bg-[#5A639C] rounded text-green-400 flex">
                            <div className="text-center sm:text-5xl text-2xl font-bold m-auto">
                                Form already submitted <br />
                                <div className="m-auto sm:text-lg text-base text-white font-normal mt-4">
                                    Thank you
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {thankyouPage && (
                                <div className="sm:w-[80%] w-[90%] sm:h-auto h-auto border-2 m-auto py-4 sm:px-8 px-2 bg-[#5A639C] rounded text-white">
                                    <div className="text-center m-auto font-semibold text-5xl">
                                        Form Submitted <br />
                                        <span className="text-xl font-semibold">Thank you</span>
                                    </div>
                                </div>
                            )}
                            {!thankyouPage && (
                                <div className="sm:w-[80%] w-[90%] sm:h-auto h-auto border-2 m-auto py-4 sm:px-8 px-2 bg-[#5A639C] rounded text-white">
                                    <div className="text-center">
                                        <div className="font-semibold text-2xl  ">
                                            Enrolment Form
                                        </div>
                                        <div className="mt-2 font-light text-base leading-5 ">
                                            This is a form for{' '}
                                            {data?.data?.data[0].attributes.isExistingStudent
                                                ? 'Existing Student'
                                                : 'New Student'}
                                            .
                                            <br />
                                            {data?.data?.data[0].attributes.academies.data
                                                .map(academy => academy.attributes.name)
                                                .join(', ')}{' '}
                                            Academy has invited you to enroll in thier{' '}
                                            {data?.data?.data[0].attributes.classes.data
                                                .map(classCard => classCard.attributes.class_name)
                                                .join(', ')}{' '}
                                            class. Click{' '}
                                            <span
                                                className="font-medium cursor-pointer underline"
                                                onClick={viewClassDetails}
                                            >
                                                here
                                            </span>{' '}
                                            to view class details. <br />
                                            You are{' '}
                                            {(data?.data?.data[0].attributes
                                                .amountCollectedAtEnrolment ?? 0 > 0)
                                                ? `Liable to pay ${data?.data?.data[0].attributes.amountCollectedAtEnrolment}`
                                                : 'not liable to pay any amount.'}{' '}
                                            for filling this form.
                                        </div>
                                    </div>
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="w-full flex sm:flex-row flex-col justify-between gap-4 mt-10">
                                            <input
                                                required
                                                type="text"
                                                className={style.formInput}
                                                placeholder="First Name&#42;"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'firstName',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <input
                                                type="text"
                                                className={style.formInput}
                                                placeholder="Middle Name"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'middleName',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <input
                                                type="text"
                                                className={style.formInput}
                                                placeholder="Last Name"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'lastName',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div className="w-full flex sm:flex-row flex-col justify-between gap-4 sm:mt-2 mt-4">
                                            <div className="sm:w-1/3 sm:pr-3">
                                                <select
                                                    required
                                                    className={`${style.formInput} w-1/3`}
                                                    value={formData.gender}
                                                    onChange={e =>
                                                        handleFormInputChange(
                                                            'gender',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Gender&#42;</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </div>
                                            <div className="sm:w-1/3 sm:pl-3">
                                                <input
                                                    required
                                                    type="date"
                                                    className={style.formInput}
                                                    value={formData.dob}
                                                    onChange={e =>
                                                        handleFormInputChange('dob', e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full flex sm:flex-row flex-col justify-between sm:gap-2 gap-4 sm:mt-2 mt-4">
                                            <div className="sm:w-1/3 sm:pr-2">
                                                <select
                                                    className={style.formInput}
                                                    value={formData.bloodGroup}
                                                    onChange={e =>
                                                        handleFormInputChange(
                                                            'bloodGroup',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Blood group</option>
                                                    <option value="A+">A+</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B+">B+</option>
                                                    <option value="B-">B-</option>
                                                    <option value="O+">O+</option>
                                                    <option value="O-">O-</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="AB-">AB-</option>
                                                </select>
                                            </div>
                                            <div className="sm:w-2/3">
                                                <textarea
                                                    placeholder="Medical Conditions if any"
                                                    className={style.formInput}
                                                    onChange={e => {
                                                        handleFormInputChange(
                                                            'medicalConditions',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-between gap-4 sm:mt-2 mt-4">
                                            <textarea
                                                required
                                                placeholder="Address&#42;"
                                                className={style.formInput}
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'address',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="mt-4 text-base font-bold">
                                            {' '}
                                            Emergency Contact Details
                                        </div>
                                        <div className="w-full flex sm:flex-row flex-col justify-between gap-4 mt-4">
                                            <input
                                                required
                                                type="text"
                                                className={style.formInput}
                                                placeholder="Relation 1 Name&#42;"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'relationName',
                                                        e.target.value
                                                    );
                                                }}
                                            />

                                            <input
                                                required
                                                type="number"
                                                className={style.formInput}
                                                placeholder="Relation 1 Contact Number&#42;"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'relationNumber',
                                                        e.target.value
                                                    );
                                                }}
                                            />

                                            <input
                                                required
                                                type="email"
                                                className={style.formInput}
                                                placeholder="Relation 1 Email Id&#42;"
                                                onChange={e => {
                                                    handleFormInputChange(
                                                        'relationEmail',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="w-full flex sm:flex-row flex-col justify-between sm:gap-4 sm:mt-2 mt-4">
                                            <div className="sm:w-1/3 sm:pr-3 w-full">
                                                <input
                                                    type="text"
                                                    className={style.formInput}
                                                    placeholder="Relation with student"
                                                    onChange={e => {
                                                        handleFormInputChange(
                                                            'relationWithStudent',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            {!secondRelation && (
                                                <div
                                                    className="font-semibold justify-center sm:mt-0 mt-1 cursor-pointer text-sm"
                                                    onClick={() => setSecondRelation(true)}
                                                >
                                                    +Add More
                                                </div>
                                            )}
                                        </div>
                                        {secondRelation && (
                                            <div>
                                                <span className="sm:hidden block gap-4 mt-4 text-sm font-bold">
                                                    2nd Relation
                                                </span>
                                                <div className="w-full flex sm:flex-row flex-col justify-between gap-4 sm:mt-4 mt-2">
                                                    <input
                                                        type="text"
                                                        className={style.formInput}
                                                        placeholder="Relation 2 Name"
                                                        onChange={e => {
                                                            handleFormInputChange(
                                                                'secondRelationName',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />

                                                    <input
                                                        type="number"
                                                        className={style.formInput}
                                                        placeholder="Relation 2 Contact Number"
                                                        onChange={e => {
                                                            handleFormInputChange(
                                                                'secondRelationNumber',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />

                                                    <input
                                                        type="email"
                                                        className={style.formInput}
                                                        placeholder="Relation 2 Email Id"
                                                        onChange={e => {
                                                            handleFormInputChange(
                                                                'secondRelationEmail',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-full flex sm:flex-row flex-col justify-between sm:gap-4 sm:mt-2 mt-4">
                                                    <div className="sm:w-1/3 sm:pr-3 w-full">
                                                        <input
                                                            type="text"
                                                            className={style.formInput}
                                                            placeholder="Relation with student"
                                                            onChange={e => {
                                                                handleFormInputChange(
                                                                    'secondRelationWithStudent',
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {secondRelation && (
                                                        <div
                                                            className="font-semibold justify-center sm:mt-0 mt-1 cursor-pointer text-sm text-white"
                                                            onClick={handleDeleteRelationClick}
                                                        >
                                                            -Delete Relation
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        <button
                                            type="submit"
                                            className="bg-orange-500 text-white font-medium py-2 w-full rounded mt-10"
                                        >
                                            {' '}
                                            Enroll{' '}
                                        </button>
                                    </form>
                                </div>
                            )}
                        </>
                    )
                ) : (
                    <div className="text-3xl">Link has expired</div>
                )}
            </div>
            <div className="w-full sm:p-10 sm:px-20 p-6 mx-auto mb-4">
                <FooterBase />
            </div>
            {!isInvalidLink && !isSubmitted && (
                <PopupModal
                    isOpen={showClassDetails}
                    handleModalClose={viewClassDetails}
                    background="white"
                    title="Class Details"
                >
                    <div className="m-auto ">
                        Class Name:{' '}
                        {data?.data?.data[0].attributes?.classes?.data[0].attributes?.class_name ??
                            ''}{' '}
                        <br />
                        Class Type:{' '}
                        {data?.data?.data[0].attributes?.classes?.data[0].attributes?.class_type ??
                            ''}{' '}
                        <br />
                        Discipline:{' '}
                        {data?.data?.data[0].attributes.classes?.data[0].attributes
                            ?.class_discipline?.data.attributes?.name ?? ''}{' '}
                        <br />
                        Skill level:{' '}
                        {data?.data?.data[0].attributes.classes?.data[0].attributes?.skill_level ??
                            ''}
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default EnrollStudentFormPage;
