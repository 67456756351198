import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HeaderMenuService } from '../../services/header-menu-service';
import {
    ACADEMY_QUERY,
    FEE_PAYMENT_QUERY,
    SCHEDULE_QUERY,
    STUDENTS_FEE_DETAILS_QUERY,
    STUDENT_HISTORY_QUERY,
    STUDENT_QUERY,
    STUDENT_SUGGESTION_QUERY,
    SUPPLIER_QUERY,
    USER_QUERY,
} from '../../utils/constants/constants';
import {
    differenceInDays,
    formatDateToInputDate,
    getJWTToken,
    getRawWeekDate,
    getWeekDay,
    isElementPresent,
    isValidEmail,
} from '../../utils/helpers/helpers';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import FeePaymentViewModel, {
    FeePaymentFormDataType,
    addFrequencyToDate,
} from './fee-payment-viewmodel';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { ScheduleState, scheduleReducer } from '../reducers/schedule-reducer';
import { useImmerReducer } from 'use-immer';
import { Routes } from '../navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import ClassService from '../../services/class-service';
import { ClassData } from '../../models/class/class-data';
import { studentAcademyHistory } from '../../models/student/student-activity';
import { AcademiesService } from '../../services/academies-service';
import { StudentData } from '../../models/student/student-data';
import { DashboardService } from '../../services/dashboard-service';
import { FeePaymentPostData } from '../../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../../models/fee/fee-payment-post-data-wrapper';
import { FeePaymentService } from '../../services/fee-payment-service';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import { StudentService } from '../../services/student-service';
import { UploadService } from '../../services/upload-service';
import { Roles } from '../../utils/auth/roles';
import { DriveStep, driver } from 'driver.js';
import { UserPostData } from '../../models/user/user-post-data';
import ApiResponse from '../../utils/types/api-response';
import { User } from '../../models/user/user';
import { UserService } from '../../services/user-services';
import { DASHBOARD_PAGE_WALKTHROUGH } from '../../utils/constants/walkthroughConstants';
import { setWalkthroughFlags } from '../../utils/redux/user-slice';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import StudentDetails from '../pages/student-details';
import { FeeDate } from '../../models/fee/fee-date';
import { StudentDetailsWrapper } from '../../models/student/student-details-wrapper';
import { FaIndent } from 'react-icons/fa';

export interface ClassDetailsCardType {
    id: number;
    timings: {
        day: string;
        from: string;
        to: string;
    }[];
}

export interface AcademyCard {
    academyId: number;
    classesSelected: ClassDetailsCardType[];
    enrolmentDate: string;
    remarks: string;
}

const DashboardViewModel = () => {
    const initialState: ScheduleState = {
        classData: [],
        selectedCalendarDate: new Date(),
    };

    const initialAcademyCardValue = [
        {
            academyId: 0,
            classesSelected: [],
            enrolmentDate: formatDateToInputDate(new Date()),
            remarks: '',
        },
    ];

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const userState = useSelector((state: RootState) => state.user);

    const { isSuccess: isUserInfoFetchSuccess, data: userData } = useQuery({
        queryKey: [USER_QUERY],
        queryFn: HeaderMenuService.instance.getUserInfo,
        refetchOnWindowFocus: false,
        enabled: getJWTToken() != null && getJWTToken().length > 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);
    const [selectedStudentData, setSelectedStudentData] = useState<StudentData>();
    const [selectedStudentClassId, setSelectedStudentClassId] = useState<number>();
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
    const [confirmedStudentClassId, setConfirmedStudentClassId] = useState<number>();
    const [inactiveFeeData, setInactiveFeeData] = useState<FeePaymentData[]>();
    const usedispatch = useDispatch();

    const setUserTutorialFlag = async (data: UserPostData): Promise<ApiResponse<User>> => {
        const response = await UserService.instance.updateUser(data, userState?.user?.id ?? 0);
        if (response.success) {
            return response;
        } else {
            // console.log(
            //     `On Update user user Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const { mutate: updateUserTutorialFlag } = useMutation(setUserTutorialFlag, {
        onSuccess: data => {
            queryClient.invalidateQueries(USER_QUERY);
            console.log('Updated');
        },
        onError: error => {
            // console.log(`On user update Error:`, error);
            // alert('Failed to update user! Please try again');
            // Error actions
        },
    });

    const filteredSteps: DriveStep[] | undefined = [];
    if (isElementPresent('quickAction')) {
        filteredSteps?.push({
            element: '#quickAction',
            popover: {
                title: 'Quick Actions',
                description: 'Readily add new enquires, students and collect fees',
                side: 'bottom',
                align: 'start',
            },
        });
    }
    if (isElementPresent('upcomingSchedule')) {
        filteredSteps?.push({
            element: '#upcomingSchedule',
            popover: {
                title: 'Upcoming Schedule',
                description: "Check today's classes and students attending.",
                side: 'top',
                align: 'start',
            },
        });
    }
    if (isElementPresent('monthlyGrowth')) {
        filteredSteps?.push({
            element: '#monthlyGrowth',
            popover: {
                title: 'Month-on-Month Growth',
                description: "Quick snapshot of your academy's health.",
                side: 'bottom',
                align: 'start',
            },
        });
    }
    if (isElementPresent('dashboard')) {
        filteredSteps?.push({
            element: '#dashboard',
            popover: {
                description:
                    "Welcome to your Dashboard. Here's a quick tour to help you get started",
                side: 'right',
            },
        });
    }
    if (isElementPresent('academy-management')) {
        filteredSteps?.push({
            element: '#academy-management',
            popover: {
                title: 'Academy Management',
                description:
                    'Enter your academy details and class information. The same will be displayed to students that visit your academy profile on SpArts',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('search')) {
        filteredSteps?.push({
            element: '#search',
            popover: {
                title: 'Search Bar',
                description: 'Quickly search students by student names to view profile details',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('enquiry-navMenu')) {
        filteredSteps?.push({
            element: '#enquiry-navMenu',
            popover: {
                title: 'Enquiries',
                description: 'List of leads, send follow up emails and messages',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('students-navmenu')) {
        filteredSteps?.push({
            element: '#students-navmenu',
            popover: {
                title: 'Students',
                description:
                    'View all students in your academies, view and edit their profiles, check attendance & churn probabilities, fees collection and progress',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('feepayment-navmenu')) {
        filteredSteps?.push({
            element: '#feepayment-navmenu',
            popover: {
                title: 'Fee Payment',
                description:
                    'List of students with pending and received fees. Send quick reminders for fees payment',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('schedule-navmenu')) {
        filteredSteps?.push({
            element: '#schedule-navmenu',
            popover: {
                title: 'Schedule',
                description: 'view upcoming classes and mark attendance',
                side: 'right',
                align: 'start',
            },
        });
    }
    if (isElementPresent('progress-tracker-navmenu')) {
        filteredSteps?.push({
            element: '#progress-tracker-navmenu',
            popover: {
                title: 'Progress Tracker',
                description:
                    'Track your progress and check for the homework assignments along with the AI module to help you learn better',
                side: 'right',
                align: 'start',
            },
        });
    }

    if (isElementPresent('settings-icon')) {
        filteredSteps?.push({
            element: '#settings-icon',
            popover: {
                title: 'Settings',
                description:
                    'Edit your profile, reset password, check your billing, notifications and report settings',
                side: 'left',
                align: 'start',
            },
        });
    }

    const driverObj = driver({
        popoverClass: 'driverjs-theme',
        doneBtnText: 'Finish Tutorial',
        showProgress: true,
        showButtons: ['next', 'previous'],
        prevBtnText: 'Skip Tour',

        steps: filteredSteps,
        onPrevClick: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(DASHBOARD_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
        onDestroyStarted: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(DASHBOARD_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
    });

    const numberOfDays = differenceInDays(new Date(userState.user?.createdAt ?? ''), new Date());

    if (
        numberOfDays < 30 &&
        !(userState.walkthroughFlag === 'FETCHING') &&
        !userState.walkthroughFlag.split(',').includes(DASHBOARD_PAGE_WALKTHROUGH)
    ) {
        setTimeout(() => {
            driverObj.drive();
        }, 2000);
    }

    const initialFeePaymentFormData = {
        payment_schedule: '',
        sessions: null,
        amount: null,
        balance: null,
        academy: null,
        student: null,
        class: null,
        feeStartDate: '',
        accReceivedIn: '',
        feeEndDate: '',
        feePaymentDate: formatDateToInputDate(new Date()),
        feePaymentAmount: '',
        feePaymentMode: '',
        feePaymentMessage: '',
        owner: null,
    };

    const [feePaymentFormData, setFeePaymentFormData] =
        useState<FeePaymentFormDataType>(initialFeePaymentFormData);
    const {
        isSuccess: isSupplierFetchSuccess,
        isLoading: isSupplierDataLoading,
        data: supplierData,
    } = useQuery({
        queryKey: [SUPPLIER_QUERY],
        queryFn: HeaderMenuService.instance.getSupplierDetails.bind(this, userData?.data?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: isUserInfoFetchSuccess && (userData?.success ?? false),
    });

    const { isSuccess: isAllAcademiesFetchSuccess, data: allAcademiesOfSupplier } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isStudentsFetchSuccess, data: studentsData } = useQuery({
        queryKey: [STUDENT_QUERY],
        queryFn: StudentService.instance.getActiveStudentList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const getActiveStudentsCount = () => {
        if (allAcademiesOfSupplier && studentsData) {
            const suppliersAcademiesId = allAcademiesOfSupplier?.data?.data?.map(item => item.id);

            const activeStudentsCount = studentsData?.data?.data?.reduce((count, student) => {
                const studentAcademyIds = student?.attributes?.academies?.data?.map(
                    academy => academy.id
                );

                if (
                    studentAcademyIds?.some(academyId => suppliersAcademiesId?.includes(academyId))
                ) {
                    return count + 1;
                }

                return count;
            }, 0);
            return activeStudentsCount;
        }
        return 0;
    };

    const [isFeeCollectionModalOpen, setIsFeeCollectionModalOpen] = useState(false);

    const onCollectFeeClicked = () => {
        setIsFeeCollectionModalOpen(true);
    };

    const [{ classData }, dispatch] = useImmerReducer(scheduleReducer, initialState);

    interface MonthlyRevenue {
        [month: string]: number;
    }

    const { isSuccess: isFeeDataFetchSuccess, data: feeData } = useQuery({
        queryKey: [FEE_PAYMENT_QUERY],
        queryFn: DashboardService.instance.getFeeListForDashboard.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isStudentsActivitesFetchSuccess, data: studentsActivities } = useQuery({
        queryKey: [STUDENT_HISTORY_QUERY],
        queryFn: AcademiesService.instance.getStudentActivitiesList.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const FeesSummary = (
        days: number
    ): {
        totalPaid: number;
        totalPending: number;
        studentsPaid: number;
        studentsNotPaid: number;
    } => {
        let totalPaid = 0;
        let totalPending = 0;
        let studentsPaid = 0;
        let studentsNotPaid = 0;

        const currentDate: Date = new Date();

        feeData?.data?.data?.forEach(paymentData => {
            const { attributes } = paymentData;

            if (attributes.fee_dates && attributes.fee_dates.length > 0) {
                attributes.fee_dates.forEach(feeDate => {
                    const paymentDate = new Date(feeDate.fee_date);

                    // Ensure paymentDate is explicitly cast to number
                    const timeDifferenceInDays = Math.ceil(
                        (currentDate.getTime() - paymentDate.getTime()) / (1000 * 60 * 60 * 24)
                    );

                    // Check if the payment is within the specified number of days
                    if (timeDifferenceInDays <= days) {
                        if (attributes.balance === null) {
                            totalPaid += feeDate.amount; // Use feeDate.amount instead of attributes.amount
                            studentsPaid++;
                        } else {
                            totalPaid += feeDate.amount;
                            studentsPaid++;
                            if (attributes.balance > 0) {
                                totalPending += attributes.balance;
                                studentsNotPaid++; // Increment studentsNotPaid when there is a balance
                            }
                        }
                    }
                });
            }
        });

        return { totalPaid, totalPending, studentsPaid, studentsNotPaid };
    };

    const MonthlyRevenueLastThreeMonths = (): { name: string; 'Cash inflow': number }[] => {
        const currentDate: Date = new Date();
        const monthlyRevenue: { [monthYearKey: string]: number } = {};

        feeData?.data?.data?.forEach(paymentData => {
            const { attributes } = paymentData;

            if (attributes.fee_dates && attributes.fee_dates.length > 0) {
                attributes.fee_dates.forEach(feeDate => {
                    const paymentDate = new Date(feeDate.fee_date);

                    // Check if the payment is within the last three months
                    if (
                        paymentDate >=
                            new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1) &&
                        paymentDate <= currentDate
                    ) {
                        const monthYearKey = `${paymentDate.toLocaleString('default', { month: 'short' })} ${paymentDate.getFullYear()}`;

                        if (monthlyRevenue[monthYearKey]) {
                            monthlyRevenue[monthYearKey] += feeDate.amount;
                        } else {
                            monthlyRevenue[monthYearKey] = feeDate.amount;
                        }
                    }
                });
            }
        });

        // Ensure we have data for the last three months, even if there are no payments
        for (let i = 2; i >= 0; i--) {
            const previousMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const monthYearKey = `${previousMonth.toLocaleString('default', { month: 'short' })} ${previousMonth.getFullYear()}`;

            if (!monthlyRevenue[monthYearKey]) {
                monthlyRevenue[monthYearKey] = 0;
            }
        }

        // Convert the result into the desired format
        const result = Object.entries(monthlyRevenue).map(([name, revenue]) => ({
            name,
            'Cash inflow': revenue,
        }));

        // Sort the result by year and month order
        result.sort((a, b) => {
            const aDate = new Date(`${a.name} 1`);
            const bDate = new Date(`${b.name} 1`);
            return aDate.getTime() - bDate.getTime();
        });

        return result;
    };

    // const MonthlyRevenueLastThreeMonthsSpread = (): { name: string; Revenue: number }[] => {
    //     const currentDate: Date = new Date();
    //     const monthlyRevenue: { [monthYearKey: string]: number } = {};

    //     feeData?.data?.data?.forEach((paymentData) => {
    //         const { attributes } = paymentData;

    //         if (attributes.fee_dates && attributes.fee_dates.length > 0) {
    //             attributes.fee_dates.forEach((feeDate) => {
    //                 const paymentDate = new Date(feeDate.fee_date);
    //                 const schedule = attributes.payment_schedule;

    //                 if (
    //                     paymentDate >= new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1) &&
    //                     paymentDate <= currentDate
    //                 ) {
    //                     const monthYearKey = `${paymentDate.toLocaleString('default', { month: 'short' })} ${paymentDate.getFullYear()}`;

    //                     let monthsToSpread = 1;
    //                     switch (schedule) {
    //                         case 'Quarterly':
    //                             monthsToSpread = 3;
    //                             break;
    //                         case 'Semi-Annually':
    //                             monthsToSpread = 6;
    //                             break;
    //                         case 'Annually':
    //                             monthsToSpread = 12;
    //                             break;
    //                         case 'Monthly':
    //                         default:
    //                             monthsToSpread = 1;
    //                             break;
    //                     }

    //                     const amountPerMonth = feeDate.amount / monthsToSpread;
    //                     for (let i = 0; i < monthsToSpread; i++) {
    //                         const spreadDate = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + i, 1);
    //                         if (
    //                             spreadDate >= new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1) &&
    //                             spreadDate <= currentDate
    //                         ) {
    //                             const spreadMonthYearKey = `${spreadDate.toLocaleString('default', { month: 'short' })} ${spreadDate.getFullYear()}`;
    //                             if (monthlyRevenue[spreadMonthYearKey]) {
    //                                 monthlyRevenue[spreadMonthYearKey] += amountPerMonth;
    //                             } else {
    //                                 monthlyRevenue[spreadMonthYearKey] = amountPerMonth;
    //                             }
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //     });

    //     // Ensure we have data for the last three months, even if there are no payments
    //     for (let i = 2; i >= 0; i--) {
    //         const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    //         const monthYearKey = `${previousMonth.toLocaleString('default', { month: 'short' })} ${previousMonth.getFullYear()}`;

    //         if (!monthlyRevenue[monthYearKey]) {
    //             monthlyRevenue[monthYearKey] = 0;
    //         }
    //     }

    //     // Convert the result into the desired format
    //     const result = Object.entries(monthlyRevenue).map(([name, revenue]) => ({
    //         name,
    //         Revenue: Math.floor(revenue),
    //     }));

    //     // Sort the result by year and month order
    //     result.sort((a, b) => {
    //         const aDate = new Date(`${a.name} 1`);
    //         const bDate = new Date(`${b.name} 1`);
    //         return aDate.getTime() - bDate.getTime();
    //     });

    //     return result;
    // };

    const MonthlyRevenueLastTwelveMonthsSpread = (): {
        name: string;
        'Fees collected': number;
    }[] => {
        const currentDate: Date = new Date();
        const monthlyRevenue: { [monthYearKey: string]: number } = {};

        feeData?.data?.data?.forEach(paymentData => {
            const { attributes } = paymentData;

            if (attributes.fee_dates && attributes.fee_dates.length > 0) {
                attributes.fee_dates.forEach(feeDate => {
                    const paymentDate = new Date(feeDate.fee_date);
                    const schedule = attributes.payment_schedule;

                    if (
                        paymentDate >=
                            new Date(currentDate.getFullYear(), currentDate.getMonth() - 11, 1) &&
                        paymentDate <= currentDate
                    ) {
                        let monthsToSpread = 1;
                        switch (schedule) {
                            case 'Quarterly':
                                monthsToSpread = 3;
                                break;
                            case 'Semi-Annually':
                                monthsToSpread = 6;
                                break;
                            case 'Annually':
                                monthsToSpread = 12;
                                break;
                            case 'Monthly':
                            default:
                                monthsToSpread = 1;
                                break;
                        }

                        const amountPerMonth = feeDate.amount / monthsToSpread;
                        for (let i = 0; i < monthsToSpread; i++) {
                            const spreadDate = new Date(
                                paymentDate.getFullYear(),
                                paymentDate.getMonth() + i,
                                1
                            );
                            if (
                                spreadDate >=
                                    new Date(
                                        currentDate.getFullYear(),
                                        currentDate.getMonth() - 11,
                                        1
                                    ) &&
                                spreadDate <= currentDate
                            ) {
                                const spreadMonthYearKey = `${spreadDate.toLocaleString('default', { month: 'short' })} ${spreadDate.getFullYear()}`;
                                if (monthlyRevenue[spreadMonthYearKey]) {
                                    monthlyRevenue[spreadMonthYearKey] += amountPerMonth;
                                } else {
                                    monthlyRevenue[spreadMonthYearKey] = amountPerMonth;
                                }
                            }
                        }
                    }
                });
            }
        });

        // Ensure we have data for the last twelve months, even if there are no payments
        for (let i = 11; i >= 0; i--) {
            const previousMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const monthYearKey = `${previousMonth.toLocaleString('default', { month: 'short' })} ${previousMonth.getFullYear()}`;

            if (!monthlyRevenue[monthYearKey]) {
                monthlyRevenue[monthYearKey] = 0;
            }
        }

        // Convert the result into the desired format
        const result = Object.entries(monthlyRevenue).map(([name, revenue]) => ({
            name,
            'Fees collected': Math.floor(revenue),
        }));

        // Sort the result by year and month order
        result.sort((a, b) => {
            const aDate = new Date(`${a.name} 1`);
            const bDate = new Date(`${b.name} 1`);
            return aDate.getTime() - bDate.getTime();
        });

        return result;
    };

    const MonthlyStudentRegistration = (): {
        name: string;
        'New students': number;
        'Dropout students': number;
    }[] => {
        const latestActivities: studentAcademyHistory[] = [];

        studentsActivities?.data?.data?.forEach(student => {
            const studentActivities = student?.attributes?.studentAcademyHistory;

            if (studentActivities?.length > 0) {
                const activitiesByAcademy: Map<number, studentAcademyHistory> = new Map();

                // Group activities by academyId and find the latest activity for each academy
                studentActivities.forEach(activity => {
                    const academyId = activity?.academy?.data?.id;
                    const existingActivity = activitiesByAcademy.get(academyId);

                    if (
                        !existingActivity ||
                        new Date(activity.statusDate) > new Date(existingActivity.statusDate)
                    ) {
                        activitiesByAcademy.set(academyId, activity);
                    }
                });

                // Add the latest activities for each academy to the result array
                latestActivities.push(...Array.from(activitiesByAcademy.values()));
            }
        });

        const today = new Date();
        const monthlyCounts: {
            name: string;
            'New students': number;
            'Dropout students': number;
        }[] = [];
        // Start from the current month and go back two more months
        for (let i = 0; i < 3; i++) {
            const monthStart = new Date(today.getFullYear(), today.getMonth() - i, 1);
            const monthEnd = new Date(today.getFullYear(), today.getMonth() - i + 1, 0);

            const activeStudents = latestActivities?.filter(
                activity =>
                    new Date(activity.enrolmentDate) >= monthStart &&
                    new Date(activity.enrolmentDate) <= monthEnd &&
                    activity.status === 'Active'
            )?.length;

            const inactiveStudents = latestActivities?.filter(
                activity =>
                    new Date(activity.statusDate) >= monthStart &&
                    new Date(activity.statusDate) <= monthEnd &&
                    activity.status === 'Inactive'
            )?.length;

            const monthName = monthStart.toLocaleString('default', { month: 'short' });
            const year = monthStart.getFullYear();

            monthlyCounts.push({
                name: `${monthName} ${year}`,
                'New students': activeStudents,
                'Dropout students': -inactiveStudents,
            });
        }

        return monthlyCounts.reverse();
    };

    const { isSuccess, isLoading, data } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isStudentSuggesionDataFetchSuccess,
        isLoading: isStudentDataFetchLoading,
        data: studentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SUGGESTION_QUERY, value],
        queryFn: async () => {
            try {
                // Perform asynchronous operations here
                const response = await HeaderMenuService.instance.getStudentList(
                    userData?.data?.id ?? 0,
                    value
                );
                // Return the result
                return response;
            } catch (error) {
                // Handle errors
                console.error('Error fetching student suggestion data:', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: searchTerm.length >= 0,
    });

    useEffect(() => {
        if (isSuccess)
            dispatch({
                type: 'setClassData',
                payload: transfromScheduleResponse(data?.data?.data ?? []),
            });
    }, [isSuccess]);

    const transfromScheduleResponse = (classList: ClassData[]) => {
        let transformedData: ClassData[] = [];
        classList.forEach(value => {
            const timingMappeddata = value?.attributes?.class_timings?.map(
                timing =>
                    ({
                        id: value?.id,
                        attributes: { ...value?.attributes, class_timing: timing },
                    }) as ClassData
            );
            transformedData = [...transformedData, ...(timingMappeddata ?? [])];
        });
        let filteredData: ClassData[] = [];
        for (let i = 0; i < 7; i++) {
            if (transformedData) {
                const dailyData = transformedData
                    .filter(value => value?.attributes?.class_timing?.day === getWeekDay(i))
                    .map(value => ({
                        id: value.id,
                        attributes: {
                            ...value.attributes,
                            class_day: getRawWeekDate(i),
                        },
                    })) as ClassData[];

                filteredData = [...filteredData, ...dailyData];
            }
        }

        return filteredData;
    };

    // const goScheduleDetails = useCallback((id: number, date: string) => {
    //     navigate(Routes.ScheduleDetails + '/' + id, { state: { date } });
    // }, []);

    const goScheduleDetails = useCallback((id: number, date: string, startTime: string, endTime: string) => {
        navigate(`${Routes.ScheduleDetails}/${id}`, { state: { date: date, filterStudentsForDay: true, time: { start: startTime, end: endTime} } });
    },[]);

    const today = new Date().toISOString().split('T')[0];
    const todayClasses = classData?.filter(item => item?.attributes?.class_day?.includes(today));

    const handleFeeCollectionModalClose = () => {
        setIsFeeCollectionModalOpen(false);
    };

    const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);

    const handleNewStudentModalClose = () => {
        setIsNewStudentModalOpen(false);
    };

    const handleAddNewStudentBtnClick = () => {
        setIsNewStudentModalOpen(true);
    };

    return {
        isLoading: false,
        isSupplierFetchSuccess,
        supplierData,
        userData,
        isSupplierDataLoading,
        onCollectFeeClicked,
        handleFeeCollectionModalClose,
        isFeeCollectionModalOpen,
        FeesSummary,
        MonthlyRevenueLastThreeMonths,
        MonthlyStudentRegistration,
        goScheduleDetails,
        todayClasses,
        isStudentSuggesionDataFetchSuccess,
        setSearchTerm,
        handleNewStudentModalClose,
        isNewStudentModalOpen,
        handleAddNewStudentBtnClick,
        activeStudentsCount: getActiveStudentsCount(),
        userState,
        MonthlyRevenueLastTwelveMonthsSpread,
    };
};

export default DashboardViewModel;
