import React from 'react';
import Logo from '../../../assets/logo.png';

const FooterBase = () => {
    return (
        // <div className="w-[90%] mx-auto mt-16">
        <>
            <div className="2xl:m-3 w-full sm:flex hidden items-center flex-row justify-between sm:mt-4">
                <div>
                    <img
                        src={Logo}
                        className="sm:h-[40px] h-[25px]"
                    />
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-8 md:gap-4 lg:gap-8 gap-2 sm:text-lg">
                    <a
                        className="cursor-pointer"
                        href="https://www.sparts.app/about-us"
                    >
                        About Us
                    </a>
                    <a
                        className="cursor-pointer"
                        href="https://www.sparts.app/contact-us"
                    >
                        Contact Us
                    </a>
                    <a
                        className="cursor-pointer"
                        href="https://www.sparts.app/terms-and-conditions"
                    >
                        Terms & Conditions
                    </a>
                    <a
                        className="cursor-pointer"
                        href="https://www.sparts.app/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    <a
                        className="cursor-pointer"
                        href="https://www.sparts.app/cookie-policy"
                    >
                        Cookie Policy
                    </a>
                </div>
            </div>

            {/* MOBILE DIV */}
            <div className="sm:hidden flex justify-between text-center w-full items-center text-xs">
                <div>
                    <img
                        src={Logo}
                        className="sm:h-[40px] h-[25px]"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-6 justify-between w-full">
                        <a
                            className="cursor-pointer"
                            href="https://www.sparts.app/about-us"
                        >
                            About Us
                        </a>
                        <a
                            className="cursor-pointer"
                            href="https://www.sparts.app/contact-us"
                        >
                            Contact Us
                        </a>
                        <a
                            className="cursor-pointer"
                            href="https://www.sparts.app/privacy-policy"
                        >
                            Privacy Policy
                        </a>
                    </div>
                    <div className="flex gap-4 justify-center w-full">
                        <a
                            className="cursor-pointer"
                            href="https://www.sparts.app/terms-and-conditions"
                        >
                            Terms & Conditions
                        </a>
                        <a
                            className="cursor-pointer"
                            href="https://www.sparts.app/cookie-policy"
                        >
                            Cookie Policy
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FooterBase;
