import React from 'react';
import style from './style.module.css';
import {
    BarChart,
    Bar,
    Legend,
    Label,
    XAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
// import  { FiArrowDownCircle } from 'react-icons/fi'

interface GrowthBarchartProps {
    MonthlyRevenueLastThreeMonths: () => { name: string; 'Cash inflow': number }[];
    MonthlyStudentRegistration: () => {
        name: string;
        'New students': number;
        'Dropout students': number;
    }[];
    MonthlyRevenueLastTwelveMonthsSpread: () => { name: string; 'Fees collected': number }[];
}

const GrowthBarchart: React.FC<GrowthBarchartProps> = ({
    MonthlyRevenueLastThreeMonths,
    MonthlyStudentRegistration,
    MonthlyRevenueLastTwelveMonthsSpread,
}) => {
    return (
        <div className={style.cardContainer}>
            <div className={style.cardHeader}>
                <div>Month-on-Month Growth</div>
                {/* <div>
						<FiArrowDownCircle fontSize={22}/>
					</div> */}
            </div>
            <div className={style.flexGrid}>
                <div
                    className={`${style.flexBasis} flex-col justify-center items-center divide-y-2 max-w-full`}
                >
                    {/* <span className={style.marginLeft2}>Revenue </span> */}
                    <BarChart
                        width={350}
                        height={300}
                        margin={{ bottom: 20 }}
                        data={MonthlyRevenueLastThreeMonths()}
                    >
                        <Legend />
                        <Tooltip />
                        <XAxis dataKey="name" />
                        <Bar
                            dataKey="Cash inflow"
                            fill="rgb(69, 90, 100)"
                            label={{ position: 'top' }}
                        />
                    </BarChart>
                    <div className="max-w-full overflow-auto md:overflow-hidden thin-scrollbar">
                        <BarChart
                            width={800}
                            height={300}
                            margin={{ top: 20 }}
                            data={MonthlyRevenueLastTwelveMonthsSpread()}
                        >
                            <Legend />
                            <Tooltip />
                            <XAxis dataKey="name" />
                            <Bar
                                barSize={40}
                                dataKey="Fees collected"
                                fill="rgb(69, 90, 100)"
                                label={{ position: 'top' }}
                            />
                        </BarChart>
                    </div>
                </div>
                <div className={`${style.flexBasis} max-w-full`}>
                    {/* <span className={style.marginLeft2}>Students </span> */}
                    <div className="max-w-full overflow-auto md:overflow-hidden thin-scrollbar">
                        <BarChart
                            width={550}
                            height={600}
                            data={MonthlyStudentRegistration()}
                        >
                            <Legend />
                            <Tooltip />
                            <ReferenceLine
                                y={0}
                                stroke="#000"
                            />
                            <XAxis dataKey="name" />
                            <Bar
                                dataKey="New students"
                                fill="rgb(69, 90, 100)"
                                label={{ position: 'center', fill: 'white' }}
                            />
                            <Bar
                                dataKey="Dropout students"
                                fill="rgb(119,136,153)"
                                label={{ position: 'center', fill: 'white' }}
                            />
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrowthBarchart;
