import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Routes } from '../routes';
import Dashboard from '../../pages/dashboard';
import Login from '../../pages/login';
import Academies from '../../pages/academies';
import Students from '../../pages/students';
import StudentDetails from '../../pages/student-details';
import FeePayment from '../../pages/fee-payment';
import ScheduleDetails from '../../pages/schedule-details';
import Schedule from '../../pages/schedule';
import ResetPassword from '../../pages/reset-password';
import LandingPage from '../../pages/landing-page';
import { AuthWrapper } from '../../../utils/auth/auth-wrapper';
import Enquires from '../../pages/enquiries';
import UserManagement from '../../pages/users';
import Settings from '../../pages/settings';
import ProgressTracker from '../../pages/progress-tracker';
import EnrollStudentFormPage from '../../pages/student-enroll-form';
import Announcement from '../../pages/announcement';
import StudentEvalResults from '../../pages/studentEvalResults';

export const router = createBrowserRouter([
    {
        path: Routes.LandingPage,
        element: <LandingPage />,
    },
    {
        path: Routes.Login,
        element: <Login />,
    },
    {
        path: Routes.ResetPassword,
        element: <ResetPassword />,
    },
    {
        path: Routes.Dashboard,
        element: <Dashboard />,
    },
    {
        path: Routes.ProgressTracker,
        element: <ProgressTracker />,
    },
    {
        path: Routes.Settings,
        element: <Settings />,
    },
    {
        path: Routes.Academies,
        element: <Academies />,
    },
    {
        path: Routes.Enquires,
        element: <Enquires />,
    },
    {
        path: Routes.Students,
        element: <Students />,
    },
    {
        path: Routes.UserManagement,
        element: <UserManagement />,
    },
    {
        path: Routes.Students + '/:id',
        element: <StudentDetails />,
    },
    {
        path: Routes.Students + '/:studentId' + '/:techniqueId',
        element: <StudentEvalResults />,
    },
    {
        path: Routes.FeePayment,
        element: <FeePayment />,
    },
    {
        path: Routes.ScheduleDetails + '/:id',
        element: <ScheduleDetails />,
    },
    {
        path: Routes.Schedule,
        element: <Schedule />,
    },
    {
        path: Routes.NotFound,
        element: <Login />,
    },
    {
        path: Routes.EnrollStudentFrom + '/:slug',
        element: <EnrollStudentFormPage />,
    },
    {
        path: Routes.Announcement,
        element: <Announcement />,
    },
]);
