import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/user/user';
import { loadUserState, saveUserState } from '../helpers/helpers';
import { UserDetailsData } from '../../models/user-details/user-details-data';
import { SupplierData } from '../../models/supplier/supplier-data';
import { actions } from 'react-table';
import { useEffect } from 'react';
import { UserPostData } from '../../models/user/user-post-data';
import ApiResponse from '../types/api-response';
import { UserService } from '../../services/user-services';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { USER_QUERY } from '../constants/constants';

const userState = {
    user: loadUserState(),
    userDetails: {} as UserDetailsData,
    supplierDetails: {} as SupplierData,
    walkthroughFlag: 'FETCHING' as string,
};

const userSlice = createSlice({
    name: 'user',
    initialState: userState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
            saveUserState(action.payload);
        },
        setUserDetails: (state, action: PayloadAction<UserDetailsData>) => {
            state.userDetails = action.payload;
        },
        setSupplierDetails: (state, action: PayloadAction<SupplierData>) => {
            state.supplierDetails = action.payload;
        },
        setWalkthroughFlags: (state, action: PayloadAction<string>) => {
            state.walkthroughFlag = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;
export const { setUserDetails, setSupplierDetails } = userSlice.actions;
export const { setWalkthroughFlags } = userSlice.actions;
export default userSlice.reducer;
