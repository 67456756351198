import React, { FormEvent } from 'react';
import style from './style.module.css';
// import {Link} from 'react-router-dom';
import InvisibleEye from '../../../assets/icons/invisible.png';
import VisibleEye from '../../../assets/icons/visible.png';
import logo from '../../../assets/logo.png';
import LoginViewModel from '../../view-models/login-view-model';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import FullScreenLoader from '../../components/bits/fullscreen-loader';

const Login = () => {
    const {
        email,
        password,
        showPassword,
        errorMsg,
        dispatch,
        onLoginClicked,
        forgotPasswordPage,
        setforgotPasswordPage,
        onForgotPasswordinputHandler,
        handlerSubmitFormForForgotPassWord,
        forgotPasswordEmailId,
        isLoading,
    } = LoginViewModel();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        onLoginClicked();
    };
    const navigate = useNavigate();

    return (
        <div className={style.mainContainer}>
            {isLoading && <FullScreenLoader />}
            {!forgotPasswordPage && (
                <form
                    className={style.loginContainer}
                    onSubmit={handleSubmit}
                >
                    <img
                        className={style.loginLogo}
                        src={logo}
                        onClick={() => navigate(Routes.LandingPage, { replace: true })}
                        alt="logo"
                    />
                    <div className={style.logoInputContainer}>
                        <div className={style.loginInputLabel}>Username</div>
                        <input
                            className={style.loginInput}
                            value={email}
                            placeholder="Enter Email !!"
                            type="email"
                            required
                            onChange={e =>
                                dispatch({
                                    type: 'setEmail',
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className={style.logoInputContainer}>
                        <div className={style.loginInputLabel}>Password</div>
                        <input
                            className={style.loginInput}
                            style={{ marginBottom: '5px' }}
                            value={password}
                            placeholder="Enter Password !!"
                            type={showPassword ? 'text' : 'password'}
                            required
                            onChange={e =>
                                dispatch({
                                    type: 'setPassword',
                                    payload: e.target.value,
                                })
                            }
                        />
                        <h1
                            className="ml-auto hover:underline hover:text-blue-500 cursor-pointer  w-fit "
                            onClick={() => setforgotPasswordPage(prev => !prev)}
                        >
                            Forgot Password
                        </h1>
                        <span
                            className={style.logoInputIconContainer}
                            onClick={() => dispatch({ type: 'setShowPassword' })}
                        >
                            <img
                                className={style.logoInputIcon}
                                src={showPassword ? VisibleEye : InvisibleEye}
                                alt="invisible_icon"
                            />
                        </span>
                    </div>

                    {/* temporarily commented out for demo */}

                    {/* <div className={style.loginForgotPasswordLabelContainer}>
                            <div className={style.loginForgotPasswordLabel}>
                                Forgot Password?
                            </div>
                        </div> */}

                    <div className={style.temporarilyPadding}></div>

                    {/* temporarily commented out for demo */}

                    <button
                        disabled={isLoading ? true : false}
                        type="submit"
                        className={style.loginButton}
                    >
                        {isLoading ? 'PLEASE WAIT...' : 'LOGIN'}
                    </button>
                    <div className={style.errorMsg}>{errorMsg}</div>

                    {/* temporarily commented out for demo */}

                    {/* <div className={style.loginSignupLabel}>
                            Don&apos;t have an account?{' '}
                            <Link className={style.loginSignupLink} to="">
                                Sign Up
                            </Link>
                        </div> */}

                    {/* temporarily commented out for demo */}
                </form>
            )}

            {forgotPasswordPage && (
                <form
                    className={style.loginContainer}
                    onSubmit={handlerSubmitFormForForgotPassWord}
                >
                    <img
                        className={style.loginLogo}
                        src={logo}
                        alt="logo"
                    />
                    <div className={style.logoInputContainer}>
                        <div className={style.loginInputLabel}>Email</div>
                        <input
                            value={forgotPasswordEmailId.emailid}
                            className={style.loginInput}
                            type="email"
                            placeholder="Please enter Your email-id"
                            onChange={e => {
                                onForgotPasswordinputHandler('emailid', e.target.value);
                            }}
                        />
                    </div>
                    <button
                        className={style.backTologinBtn}
                        type="button"
                        onClick={() => setforgotPasswordPage(prev => !prev)}
                    >
                        Back to Login
                    </button>
                    <button
                        className={style.loginButton}
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
};

export default Login;
