import React from 'react';
import style from './style.module.css';
import { FeePaymentData } from '../../../../models/fee/fee-payment-data';
import { ClassData } from '../../../../models/class/class-data';

interface ChangeClassFormProps {
    activeCollectFeeStudent: FeePaymentData | undefined;
    allClasses: ClassData[] | undefined;
    changeClassInputValue: number;
    handleClassChangeFormInputChange: (id: number) => void;
    handleClassChange: () => void;
}

const ChangeClassForm: React.FC<ChangeClassFormProps> = ({
    activeCollectFeeStudent,
    allClasses,
    changeClassInputValue,
    handleClassChangeFormInputChange,
    handleClassChange,
}) => {
    return (
        <div className={style.container}>
            <div className={style.studentsInfoWrapper}>
                <div className={style.containerLeftSidediv}>
                    <h1 className={style.containerLeftSidedivText}>Student Name</h1>
                    <h1>
                        {activeCollectFeeStudent?.attributes?.student?.data?.attributes
                            ?.firstName ?? ''}{' '}
                        {activeCollectFeeStudent?.attributes?.student?.data?.attributes
                            ?.middleName ?? ''}{' '}
                        {activeCollectFeeStudent?.attributes?.student?.data?.attributes?.lastName ??
                            ''}
                    </h1>
                </div>
                <div className={style.containerLeftSidediv}>
                    <h1 className={style.containerLeftSidedivText}>Discipline</h1>
                    <h1>
                        {
                            activeCollectFeeStudent?.attributes?.class?.data?.attributes
                                ?.class_discipline?.data?.attributes?.name
                        }
                    </h1>
                </div>
                <div className={style.containerLeftSidediv}>
                    <h1 className={style.containerLeftSidedivText}>Current Class</h1>
                    <h1>
                        {activeCollectFeeStudent?.attributes?.class?.data?.attributes?.class_name}
                    </h1>
                </div>
            </div>
            <div className={style.classSelectInputContainer}>
                <h1 className={style.containerLeftSidedivText}>New Class</h1>
                <select
                    value={changeClassInputValue}
                    className={style.classSelectInput}
                    onChange={e => handleClassChangeFormInputChange(parseInt(e.target.value))}
                >
                    {allClasses
                        ?.filter(
                            item =>
                                item?.attributes?.class_discipline?.data?.id ===
                                activeCollectFeeStudent?.attributes?.class?.data?.attributes
                                    ?.class_discipline?.data?.id
                        )
                        .map(item => (
                            <option
                                key={item.id}
                                value={item.id}
                            >
                                {item?.attributes?.class_name}
                            </option>
                        ))}
                </select>
            </div>
            <div className={style.formSubmitBtnContainer}>
                <button
                    className={style.formSubmitBtn}
                    onClick={handleClassChange}
                    disabled={
                        activeCollectFeeStudent?.attributes?.class?.data?.id ===
                        changeClassInputValue
                    }
                >
                    Change Class
                </button>
            </div>
        </div>
    );
};

export default ChangeClassForm;
