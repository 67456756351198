import { AiAttendanceListWrapper } from '../models/attendance/ai-attendance-list-wrapper';
import { AttendanceAttributesWraper } from '../models/attendance/attendance-attributes-wrapper';
import { AttendanceDataWraper } from '../models/attendance/attendance-data-wrapper';
import { AttendanceListWrapper } from '../models/attendance/attendance-list-wrapper';
import { ClassPostData } from '../models/class/class-post-data';
import { ClassPostDataWrapper } from '../models/class/class-post-data-wrapper';
import { StudentDetailsWrapper } from '../models/student/student-details-wrapper';
import { invokeApi } from '../utils/helpers/invoke-api';
import AiAttendanceRequestWrapper from '../utils/types/ai-attendance-request-wrapper';
import AiAttendanceRequest from '../utils/types/ai-attendance-resquest';
import AttendanceRequest from '../utils/types/attendance-request';
import AttendanceRequestWrapper from '../utils/types/attendance-request-wrapper';

export class ScheduleService {
    private static _instance: ScheduleService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new ScheduleService());
    }

    public getAttendanceHistory = async (
        classId: number,
        attendanceDate: string,
        searchTerm: string
    ) => {
        return invokeApi<null, AttendanceListWrapper>({
            route: `schedules?filters[class][id][$eq]=${classId}&populate[0]=student.photo&populate[1]=class&populate[2]=markedBy&filters[attendance_date][$eq]=${attendanceDate}&pagination[page]=1&pagination[pageSize]=1000&filters[$or][0][student][firstName][$contains]=${searchTerm}&filters[$or][1][student][middleName][$contains]=${searchTerm}&filters[$or][2][student][lastName][$contains]=${searchTerm}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public getAttendanceHistoryCount = async (ownerId: number, attendanceDate: string) => {
        let currentPage = 1;
        let allAttendance: AttendanceAttributesWraper[] = [];
        let totalAttendanceCount = 0;

        do {
            const response = await invokeApi<null, AttendanceListWrapper>({
                route: `schedules?populate[class][fields][0]=id&populate[student][fields][0]=id&filters[class][users][id][$eq]=${ownerId}&filters[attendance_date][$eq]=${attendanceDate}`,
                method: 'GET',
                privateRoute: true,
            });

            if (!response.success) {
                // Handle error
                return response; // Return error response
            }

            if (!response.data) {
                // Handle case where response.data is undefined
                return {
                    isLoading: false,
                    success: false,
                    error: {
                        status: 500,
                        message: 'Data is undefined',
                    },
                };
            }

            allAttendance = allAttendance.concat(response?.data?.data ?? []);

            const { pagination } = response.data.meta;
            const { page, pageCount, total } = pagination;

            totalAttendanceCount = total;

            if (page >= pageCount) {
                // Reached the last page, break the loop
                break;
            }

            currentPage++;
        } while (currentPage <= Math.ceil(totalAttendanceCount / 500));

        const paginationMeta = {
            pagination: {
                page: 1,
                pageSize: allAttendance.length,
                pageCount: allAttendance.length,
                total: allAttendance.length,
            },
        };

        return {
            isLoading: false,
            success: true,
            data: {
                data: allAttendance,
                meta: paginationMeta,
            },
        };
    };

    public postAttendance = async (data: AttendanceRequest) => {
        return invokeApi<AttendanceRequestWrapper, AttendanceDataWraper>({
            route: `schedules?populate=student`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public putAttendance = async (id: number, data: AttendanceRequest) => {
        return invokeApi<AttendanceRequestWrapper, AttendanceDataWraper>({
            route: `schedules/${id}?populate=student`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public postBulkAttendance = async (data: AttendanceRequest[], isAiAttendance: boolean) => {
        return invokeApi<
            { attendanceData: AttendanceRequest[]; isAiAttendance: boolean },
            AttendanceListWrapper
        >({
            route: `bulk-schedule`,
            method: 'POST',
            privateRoute: true,
            data: {
                attendanceData: data,
                isAiAttendance: isAiAttendance,
            },
        });
    };

    public getAiAttendanceResponse = async (classId: number, attendanceDate: string) => {
        return invokeApi<null, AiAttendanceListWrapper>({
            route: `ai-attendance-datas?populate=*&filters[class][id][$eq]=${classId}&filters[date][$eq]=${attendanceDate}`,
            method: 'GET',
            privateRoute: true,
        });
    };

    public postAiAttendanceRequestData = async (data: AiAttendanceRequest) => {
        return invokeApi<AiAttendanceRequestWrapper, AiAttendanceListWrapper>({
            route: `ai-attendance-datas`,
            method: 'POST',
            privateRoute: true,
            data: {
                data,
            },
        });
    };

    public putAiAttendanceRequestData = async (data: AiAttendanceRequest,id: number) => {
        return invokeApi<AiAttendanceRequestWrapper, AiAttendanceListWrapper>({
            route: `ai-attendance-datas/${id}`,
            method: 'PUT',
            privateRoute: true,
            data: {
                data,
            },
        });
    };
}

export default ScheduleService;
