import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FaRegEdit } from 'react-icons/fa';
import CertificationFormData from '../../../../utils/types/CertificationFormData';
import { getJWTToken } from '../../../../utils/helpers/helpers';
import axios from 'axios';

interface Certification {
    id: number;
    attributes: {
        Name: string;
        level: number;
        Remarks: string | null;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        assignment_technique_requests: {
            data: Array<{
                id: number;
                attributes: {
                    name: string;
                    createdAt: string;
                    updatedAt: string;
                    publishedAt: string;
                    sequence: number | null;
                };
            }>;
        };
    };
}

interface CertificationListProps {
    certifications: Certification[];
    onSave: (updatedCertifications: Certification[]) => void;
    handleOpenPopup: (mode: 'edit' | 'add', data?: CertificationFormData, id?: number) => void;
    setEditData: React.Dispatch<React.SetStateAction<CertificationFormData | undefined>>;
}

const CertificationList: React.FC<CertificationListProps> = ({
    certifications,
    onSave,
    handleOpenPopup,
    setEditData,
}) => {
    const [items, setItems] = useState(certifications);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setItems(certifications);
    }, [certifications]);

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        setItems(reorderedItems);
    };

    const handleSave = async () => {
        const headersList = {
            Accept: '*/*',
            Authorization: `Bearer ${getJWTToken()}`,
            'Content-Type': 'application/json',
        };

        try {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const updatedLevel = i; // New level based on position

                await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/api/certifications/${item.id}`,
                    {
                        data: {
                            ...item.attributes,
                            level: updatedLevel,
                        },
                    },
                    { headers: headersList }
                );
            }

            onSave(items);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleEdit = async (id: number) => {
        const headersList = {
            Accept: '*/*',
            Authorization: `Bearer ${getJWTToken()}`,
        };

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/certifications/${id}?populate=assignment_technique_requests`,
                { headers: headersList }
            );

            const data = response.data.data;

            const editData: CertificationFormData = {
                Name: data.attributes.Name,
                level: data.attributes.level,
                students: [],
                academy: '',
                discipline: '',
                users: '',
                Remarks: data.attributes.Remarks,
                assignment_technique_requests:
                    data.attributes.assignment_technique_requests.data.map((item: any) => item.id),
            };

            setEditData(editData);
            handleOpenPopup('edit', editData, data.id);
        } catch (error) {
            console.error('Error fetching certification data:', error);
        }
    };

    return (
        <div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="certifications"
                    isDropDisabled={!isEditing}
                >
                    {provided => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={String(item.id)}
                                    index={index}
                                    isDragDisabled={!isEditing}
                                >
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`p-4 mb-2 bg-white rounded-lg shadow-md flex flex-row items-center justify-between ${isEditing ? 'cursor-grab' : ''}`}
                                        >
                                            <div>{item.attributes.Name}</div>
                                            <div> (Level: {item.attributes.level})</div>
                                            <div>
                                                <button
                                                    onClick={() => handleEdit(item.id)}
                                                    className="flex items-center gap-2 border-black border-2 rounded p-2 pr-2"
                                                >
                                                    <FaRegEdit /> Edit
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {isEditing ? (
                <button
                    onClick={handleSave}
                    className="px-4 py-2 mt-4 bg-blue-600 text-white rounded-lg"
                >
                    Save
                </button>
            ) : (
                <button
                    onClick={() => setIsEditing(true)}
                    className="px-4 py-2 mt-4 bg-[#000] text-white rounded-lg"
                >
                    Re-order
                </button>
            )}
        </div>
    );
};

export { CertificationList };
