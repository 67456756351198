import React from 'react';
import LoginViewModel from '../../view-models/login-view-model';
import logo from '../../../assets/logo.png';
import style from './style.module.css';
import InvisibleEye from '../../../assets/icons/invisible.png';
import VisibleEye from '../../../assets/icons/visible.png';

function ResetPassword() {
    const {
        dispatch,
        handlerSubmitResetPassword,
        forgotPasswordEmailId,
        onForgotPasswordinputHandler,
        setforgotPasswordPage,
        handlerSubmitFormForForgotPassWord,
        onResetPasswordinputHandler,
        forgotePasswordReset,
        showPassword,
    } = LoginViewModel();
    return (
        <div className=" flex items-center flex-col py-10">
            <img
                className={style.loginLogo}
                src={logo}
                alt="logo"
            />

            <div className={style.logoInputContainer}>
                <div className={style.loginInputLabel}>Password</div>
                <input
                    className={style.loginInput}
                    style={{ marginBottom: '5px' }}
                    placeholder="Enter Password !!"
                    type={showPassword ? 'text' : 'password'}
                    required
                    value={forgotePasswordReset.password}
                    onChange={e => {
                        onResetPasswordinputHandler('password', e.target.value);
                    }}
                />
                <span
                    className={style.logoInputIconContainer}
                    onClick={() => dispatch({ type: 'setShowPassword' })}
                >
                    <img
                        className={style.logoInputIcon}
                        src={showPassword ? VisibleEye : InvisibleEye}
                        alt="invisible_icon"
                    />
                </span>
                <div className={style.loginInputLabel}>Password Confirmation</div>
                <input
                    className={style.loginInput}
                    type="password"
                    // type={showPassword ? 'text' : 'password'}
                    required
                    placeholder="confirmation your password..."
                    value={forgotePasswordReset.confirmationpassword}
                    onChange={e => {
                        onResetPasswordinputHandler('confirmationpassword', e.target.value);
                    }}
                />

                <button
                    className={style.loginButton}
                    onClick={handlerSubmitResetPassword}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default ResetPassword;
