import CryptoJS from 'crypto-js';
import { AUTH_SECRET, AUTH_TOKEN, USER_STATE } from '../constants/constants';
import { ClassTiming } from '../../models/class/class-timing';
import { User } from '../../models/user/user';
import BulkStudentAttendanceDataType from '../types/bulk-student-attendance-data-type';

export function getCurrentDateFormatted(): string {
    const date = new Date();
    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
    const day = date.toLocaleDateString('en-US', { day: '2-digit' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });

    return `${weekday}, ${day} ${month}`;
}

export function setJWTToken(jwt: string) {
    const encryptedToken = CryptoJS.AES.encrypt(jwt, AUTH_SECRET).toString();
    localStorage.setItem(AUTH_TOKEN, encryptedToken);
}

export function DeleteJWTToken() {
    localStorage.removeItem(AUTH_TOKEN);
}

export function getJWTToken(): string {
    const decryptedToken = CryptoJS.AES.decrypt(
        localStorage.getItem(AUTH_TOKEN) ?? '',
        AUTH_SECRET
    );
    const originalToken = decryptedToken.toString(CryptoJS.enc.Utf8);
    return originalToken;
}

export const loadUserState = (): User | null => {
    try {
        const encryptedUserState = localStorage.getItem(USER_STATE);
        if (encryptedUserState === null) {
            return null;
        }
        const bytes = CryptoJS.AES.decrypt(encryptedUserState, AUTH_SECRET);
        const decryptedUserState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as User;
        return decryptedUserState;
    } catch (error) {
        console.error('Error loading and decrypting user state from local storage:', error);
        return null;
    }
};

export function DeleteUserState() {
    localStorage.removeItem(USER_STATE);
}

// Function to encrypt and save user state to local storage
export const saveUserState = (userState: User | null) => {
    try {
        const serializedUserState = JSON.stringify(userState);
        const encryptedUserState = CryptoJS.AES.encrypt(
            serializedUserState,
            AUTH_SECRET
        ).toString();
        localStorage.setItem(USER_STATE, encryptedUserState);
    } catch (error) {
        console.error('Error encrypting and saving user state to local storage:', error);
    }
};

export function differenceInDays(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

    const timeDifference = Math.abs(date1.getTime() - date2.getTime());
    const daysDifference = Math.round(timeDifference / oneDay);

    return daysDifference;
}

export function differenceInMonths(date1: Date, date2: Date): number {
    const monthsDifference =
        (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());

    return monthsDifference;
}

export function addMonthsToDate(originalDate: Date, monthsToAdd: number) {
    const newDate = new Date(originalDate);
    newDate.setMonth(newDate.getMonth() + monthsToAdd);
    return newDate.toISOString().split('T')[0];
}

export function getFeePaymentQuery(
    schedules: string[],
    academies: number[],
    disciplines: string[]
): string {
    return (
        academies.reduce(
            (total, value, index) =>
                total + `filters[academy][id][$in][${index + 1}]=` + value + '&',
            ''
        ) +
        schedules.reduce(
            (total, value, index) =>
                total + `filters[payment_schedule][id][$in][${index + 1}]=` + value + '&',
            ''
        ) +
        disciplines.reduce(
            (total, value, index) =>
                total + `filters[class][discipline][$in][${index + 1}]=` + value + '&',
            ''
        )
    );
}

export function getEnrollmentQuery(selectedEnrollmentStatusForFilter: string[]): string {
    const statusQueryString: string[] = [];
    selectedEnrollmentStatusForFilter.map(status => {
        if (status === 'FORM_SUBMITTED') {
            statusQueryString.push(`filters[status][$eq]=FORM_SUBMITTED`);
        } else if (status === 'STUDENT_ADDED') {
            statusQueryString.push(`filters[status][$eq]=STUDENT_ADDED`);
        } else if (status === 'REJECTED') {
            statusQueryString.push(`filters[status][$eq]=REJECTED`);
        } else if (status === 'SUBMISSION_PENDING') {
            statusQueryString.push(`filters[status][$eq]=SUBMISSION_PENDING`);
        }
    });

    return `${statusQueryString.join('&')}`;
}

export function getStudentQuery(
    selectedAgesForFilter: string[],
    selectedAcademiesForFilter: number[],
    selectedClassesForFilter: number[],
    selectedDobFilter: { date: string; type: string },
    selectedApartmentForFilter: string[],
    statusForFilter: number,
    academyIds: number[] | undefined,
    selectedSchoolForFilter: string[],
    selectedGradeForFilter: string[],
    selectedBoardForFilter: string[],
    selectedSortOption:number
): string {
    
    let nameQueryString;
    if (selectedSortOption===1) {
        nameQueryString = `sort[0]=firstName:asc`;
    } else {
        nameQueryString = `sort[0]=firstName:desc`;
    }
    
    const academyQueryString = selectedAcademiesForFilter
        .map(id => `filters[academies][id][$eq]=${id}`)
        .join('&');
    const classesQueryString = selectedClassesForFilter
        .map(id => `filters[classes][id][$eq]=${id}`)
        .join('&');
    const ageQueryString = selectedAgesForFilter
        .map(age => {
            if (age === 'under6') {
                const currentDate = new Date();
                const sixYearsAgo = new Date(
                    currentDate.getFullYear() - 6,
                    currentDate.getMonth(),
                    currentDate.getDate()
                );
                const sevenYearsAgo = new Date(
                    currentDate.getFullYear() - 7,
                    currentDate.getMonth(),
                    currentDate.getDate()
                );
                return `filters[dob][$gt]=${sixYearsAgo.toISOString()}`;
            } else if (age === '15plus') {
                const fifteenYearsAgo = new Date(
                    new Date().getFullYear() - 15,
                    new Date().getMonth(),
                    new Date().getDate()
                );
                return `filters[dob][$gt]=${fifteenYearsAgo.toISOString()}`;
            } else {
                const currentDate = new Date();
                const lowerYearsAgo = new Date(
                    currentDate.getFullYear() - parseInt(age) - 1,
                    currentDate.getMonth(),
                    currentDate.getDate()
                );
                const upperYearsAgo = new Date(
                    currentDate.getFullYear() - parseInt(age),
                    currentDate.getMonth(),
                    currentDate.getDate()
                );
                return `filters[dob][$gt]=${lowerYearsAgo.toISOString()}&filters[dob][$lt]=${upperYearsAgo.toISOString()}`;
            }
        })
        .join('&');

    let dobFilterQueryString = '';
    if (selectedDobFilter && selectedDobFilter.date && selectedDobFilter.type) {
        const date = new Date(selectedDobFilter.date);
        if (selectedDobFilter.type === 'before') {
            dobFilterQueryString = `filters[dob][$lt]=${date.toISOString()}`;
        } else if (selectedDobFilter.type === 'after') {
            dobFilterQueryString = `filters[dob][$gt]=${date.toISOString()}`;
        }
    }

    const apartmentQueryString = selectedApartmentForFilter
        .map(apartment => `filters[apartmentName][$eq]=${apartment}`)
        .join('&');

    let statusQueryString = '';
    if (statusForFilter !== -1) {
        statusQueryString = `&academiesForFilter=${academyIds?.join(',')}&studentStatus=${statusForFilter}`;
    }

    const schoolQueryString = selectedSchoolForFilter
        .map(school => `filters[school][$eq]=${school}`)
        .join('&');
    const gradeQueryString = selectedGradeForFilter
        .map(grade => `filters[grade][$eq]=${grade}`)
        .join('&');
    const boardQueryString = selectedBoardForFilter
        .map(board => `filters[board][$eq]=${board}`)
        .join('&');
    return `${nameQueryString}&${academyQueryString}&${classesQueryString}&${ageQueryString}&${dobFilterQueryString}&${apartmentQueryString}&${statusQueryString}&${schoolQueryString}&${gradeQueryString}&${boardQueryString}`;
}

export function base64toFile(
    base64String: string,
    filename = 'file',
    type = 'application/octet-stream'
) {
    // Split the base64 string into two parts
    const base64Parts = base64String.split(';base64,');
    const contentType = base64Parts[0].split(':')[1];

    // Convert base64 to binary
    const binaryString = window.atob(base64Parts[1]);

    // Create an array buffer to hold the binary data
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Populate the array buffer with the binary data
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob object from the binary data
    const blob = new Blob([arrayBuffer], { type });

    // Create a File object from the Blob
    const file = new File([blob], filename, { type: contentType });

    return file;
}

export function getWeekDay(dayNo: number, date?: Date): string {
    const currentDate = date ? date : new Date();
    if (dayNo) {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + dayNo);
        return newDate.toLocaleDateString('en-US', {
            weekday: 'long',
        });
    } else {
        return currentDate.toLocaleDateString('en-US', {
            weekday: 'long',
        });
    }
}

export function getWeekDayForDateFromTimings(
    date: string,
    timings: ClassTiming[]
): ClassTiming | undefined {
    const currentDate = new Date(date);
    const day = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
    });
    return timings.find(value => value.day === day);
}

export function formatTime(timeString: string | undefined | null) {
    if (!timeString) {
        return 'Invalid time';
    }

    const [hours, minutes] = timeString.split(':');
    const time = new Date();
    time.setHours(parseInt(hours, 10));
    time.setMinutes(parseInt(minutes, 10));

    const formattedTime = time.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    return formattedTime;
}

export function getFormattedWeekDay(dateString: Date | string): string {
    const currentDate = new Date(dateString);
    return currentDate.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    });
}
export function getFormattedDayWithYear(dateString: Date | string): string {
    const currentDate = new Date(dateString);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthsOfYear = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const month = monthsOfYear[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();
    const year = currentDate.getFullYear();

    return `${dayOfWeek}-${month} ${dayOfMonth}, ${year}`;
}

export function getRawWeekDate(dayNo: number, date?: Date): string {
    const currentDate = date ? date : new Date();
    if (dayNo) {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + dayNo);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}

export function areDatesSame(date1: Date, date2: Date): boolean {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function arraysEqual(arr1: string[] | number[], arr2: string[] | number[]) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    arr1.sort();
    arr2.sort();

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function getISTTime(): string {
    const date = new Date(); // Get current date and time
    const ISTOptions: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Kolkata', // Time zone for IST
        hour12: false, // Use 24-hour format
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3, // Milliseconds
    };

    return date.toLocaleString('en-US', ISTOptions);
}

export function getFormattedDate(inputDate: Date | string): string {
    const dateObject = new Date(inputDate);

    // Check if the date object is valid
    if (isNaN(dateObject.getTime())) {
        return 'Invalid Date';
    }

    const day = dateObject.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject);
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
}

export function getCurrentDateString() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateToInputDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatTimeToAMPM(timeString: string): string {
    // Parse the time string to extract hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');

    // Convert hours to 12-hour format
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12

    // Return the formatted time string
    return `${formattedHours}:${minutes} ${ampm}`;
}

export function formatDate(inputDate: string): string {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Parse the input date string
    const parts = inputDate.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
    const day = parseInt(parts[2], 10);

    // Create a Date object
    const date = new Date(year, month, day);

    // Get the day of the week and month names
    const dayOfWeek = days[date.getDay()];
    const monthName = months[date.getMonth()];

    // Format the date string
    const formattedDate = `${dayOfWeek}, ${monthName} ${day}`;
    return formattedDate;
}

export function arraysHaveSameNumbers(arr1: number[], arr2: number[]) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}
export function isElementPresent(id: string): boolean {
    const element = document.getElementById(id);
    return !!element;
}

export const convertToCSV = (data: any[], classes: string[]): string => {
    if (data.length === 0) return '';

    const escapeCSVValue = (value: string): string => {
        if (value.includes(',')) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    };

    const classesRow = `Classes:\n${classes.join(',')}`;
    const headers = Object.keys(data[0]).join(',');
    const rows = data
        .map(row => {
            return Object.values(row)
                .map(value => {
                    if (Array.isArray(value)) {
                        return escapeCSVValue(value.join(';'));
                    } else if (typeof value === 'string') {
                        return escapeCSVValue(value);
                    } else {
                        return value; // handle other types like numbers, boolean, etc.
                    }
                })
                .join(',');
        })
        .join('\n');

    return `${classesRow}\n\n${headers}\n${rows}`;
};

export const csvToJson = (csvString: string): any[] => {
    const lines = csvString.split('\n');

    // Skip the first three rows (assuming they are headers or irrelevant)
    const dataLines = lines.slice(4); // Start parsing from the 5th row (index 4)

    // Parse headers from the fourth row (index 3)
    const headers = lines[3].split(',').map(header => header.replace(/\*/g, '').trim());

    // Parse data from subsequent lines
    const data = dataLines.map(line => {
        const values = parseCsvLine(line);
        const obj: any = {};
        headers.forEach((header, index) => {
            // Remove double quotes and backslashes from values
            obj[header.trim()] = values[index]?.replace(/["\\]/g, '')?.trim();
        });
        return obj;
    });

    return data;
};

// Function to parse CSV line considering fields that may contain commas
export const parseCsvLine = (line: string): string[] => {
    const values: string[] = [];
    let currentField = '';

    let insideQuotes = false;
    for (let i = 0; i < line.length; i++) {
        const char = line[i];
        if (char === '"') {
            insideQuotes = !insideQuotes;
        } else if (char === ',' && !insideQuotes) {
            values.push(currentField);
            currentField = '';
        } else {
            currentField += char;
        }
    }

    values.push(currentField); // Add the last field

    return values;
};

export function isValidDate(dateString: string) {
    // Regular expression to match the 'YYYY-MM-DD' format
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Check if the date string matches the regex
    if (!regex.test(dateString)) {
        return false;
    }

    // Parse the date components to check if they form a valid date
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    // Check if the parsed date components are valid
    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
        return false;
    }

    return true;
}

export function verifyBulkStudentCsvRow(
    data: BulkStudentAttendanceDataType,
    allClassesName: string[]
) {
    // const isValid = data?.classes?.split(',').filter(className => !allClassesName.includes(className)).length > 0 ||
    // data?.firstName === '' ||
    // (data?.gender !== 'male' && data?.gender !== 'female') ||
    // !isValidDate(data?.dob) ||
    // !isValidDate(data?.enrolementDate) ||
    // (data.relation1Gender !== 'male' && data?.relation1Gender !== 'female' && data?.relation1Gender !== '') ||
    // (data.relation2Gender !== 'male' && data?.relation2Gender !== 'female' && data?.relation2Gender !== '') ||
    // !isValidEmail(data.parentEmailId) ||
    // (!isValidEmail(data.relation1EmailId) || data.relation1EmailId === '') ||
    // (!isValidEmail(data.relation2EmailId) || data.relation2EmailId === '')

    const isValid =
        data?.classes?.split(',').filter(className => !allClassesName.includes(className)).length >
            0 ||
        data?.firstName === '' ||
        (data?.gender !== 'male' && data?.gender !== 'female');

    return isValid;
}

export function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidNumber(str: string) {
    // Check if the string can be converted to a number and is not NaN
    const num = parseInt(str);
    return !isNaN(num) && typeof num === 'number';
}

export function generateUniqueSlug(): string {
    let pass = '';
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 1; i <= 30; i++) {
        const char = Math.floor(Math.random() * str.length + 1);
        pass += str.charAt(char);
    }
    return pass;
}

export function convertTimeFormat(time: string): string {
    const [hoursStr, minutes, seconds] = time.split(':');
    let hours = parseInt(hoursStr, 10);
    let period = 'AM';

    if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
            hours -= 12;
        }
    } else if (hours === 0) {
        hours = 12;
    }

    // Ensure two digits for hours
    const formattedHours = hours.toString().padStart(2, '0');

    return `${formattedHours}:${minutes} ${period}`;
}

export function getCurrentDayString(d?: Date): string {
    const date = d ? d : new Date();
    const day = date.getDay();
    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    return daysOfWeek[day];
}

export function convertTo12HourFormat(timeString: string) {
    const [hours, minutes] = timeString.split(':');
    let hour = parseInt(hours);
    const isAM = hour < 12;

    if (hour === 0) {
        hour = 12;
    } else if (hour > 12) {
        hour -= 12;
    }

    const period = isAM ? 'AM' : 'PM';

    return `${hour.toString().padStart(2, '0')}:${minutes} ${period}`;
}

export function calculateAge(birth: Date): string {
    const today = new Date();
    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();

    // Adjust if the current month is before the birth month
    if (months < 0) {
        years--;
        months += 12;
    }

    return `${years} years, ${months} months`;
}

export function calculateAgeInNumber(birth: Date): number {
    const now = new Date();
    const differenceInMilliseconds = now.getTime() - birth.getTime();

    const millisecondsInYear = 365.25 * 24 * 60 * 60 * 1000; // Accounting for leap years
    const age = differenceInMilliseconds / millisecondsInYear;

    return age;
}
