import React from 'react';
import Navbar from '../../components/landing-page-items/Navbar';
import Hero from '../../components/landing-page-items/Hero';
import Companies from '../../components/landing-page-items/Companies';
import Process from '../../components/landing-page-items/Process';
import Services from '../../components/landing-page-items/Services';
import Testimonials2 from '../../components/landing-page-items/Testimonials';
import Footer from '../../components/landing-page-items/Footer';
import landingPageViewModel from '../../view-models/landing-page-view-model';

const LandingPage = () => {
    const {
        bookDemoFormData,
        handleInputChange,
        handleBookDemoFormSubmit,
        isDemoBooked,
        handleClick,
    } = landingPageViewModel();

    return (
        <div className="Landing">
            <Navbar />
            <Hero
                bookDemoFormData={bookDemoFormData}
                handleInputChange={handleInputChange}
                handleBookDemoFormSubmit={handleBookDemoFormSubmit}
                isDemoBooked={isDemoBooked}
            />
            <hr />
            <Companies />
            <hr />
            <Process />
            <Services />
            {/* <Testimonials2 /> */}
            <Footer />
            {/* <div className='w-full flex items-center justify-center'>
                <button className='px-5 py-2 bg-red-600 text-white rounded' onClick={handleClick}>Error</button>
            </div> */}
        </div>
    );
};

export default LandingPage;
