import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import FooterBase from './FooterBase';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className="w-[90%] mx-auto mt-16">
            <div className="2xl:m-3 w-full border-[.5px] border-[#0008] rounded-xl p-8 flex flex-col sm:flex-row justify-between">
                <div className="flex-1  lg:flex-[25%] text-2xl font-bold">
                    Built specifically for you & your academy
                </div>

                <div className="flex-1 lg:flex-[75%] flex flex-col gap-2 lg:gap-0 lg:flex-row justify-between">
                    <div className="flex flex-row">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="graphics/footer/star.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Digital transition support
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="graphics/footer/badge.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Trusted by over 25 academies
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className=" rounded w-[70px] h-[70px] flex items-center justify-center bg-[#E9ECF2] ">
                            <img
                                src="graphics/footer/bulb.webp"
                                alt="star"
                            />
                        </div>
                        <div className="text-gray p-3 font-extralight">
                            Enrolled 30+ new students
                        </div>
                    </div>
                </div>
            </div>
            <FooterBase />
        </div>
    );
};

export default Footer;
